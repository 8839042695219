import { isSlugEnvironment } from "src/common/host";
import { getCurrentEnvironment } from "src/config/getConfig";
import { RestaurantFragment } from "src/state/restaurant/types";
import { store } from "src/state/store";

export const constructPathWithParams = (path: string) => {
  if (getCurrentEnvironment() === "test") {
    return path;
  }

  let basePath = path;

  if (isSlugEnvironment()) {
    const slug = window.location.pathname.split("/")[1];
    basePath = `/${slug}${path}`;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const bundleId = urlParams.get("bundleId");
  const promoId = urlParams.get("promoId");
  const promoSource = urlParams.get("promoSource");

  const params: string[] = [];
  if (bundleId) {
    params.push(`bundleId=${bundleId}`);
  }
  if (promoId) {
    params.push(`promoId=${promoId}`);
  }
  if (promoSource) {
    params.push(`promoSource=${promoSource}`);
  }

  if (params.length > 0) {
    const paramString = params.join("&");
    if (basePath.includes("?")) {
      return `${basePath}&${paramString}`;
    }
    return `${basePath}?${paramString}`;
  }
  return basePath;
};

export const constructPathWithSlug = (path: string) => {
  let basePath = path;

  if (isSlugEnvironment()) {
    const slug = window.location.pathname.split("/")[1];
    basePath = `/${slug}${path}`;
  }

  return basePath;
};

export const getGenericHomePath = () => {
  const restaurant = store.getState().restaurants
    .currentRestaurant as RestaurantFragment;

  if (restaurant.isOwnerManaged) {
    return constructPathWithSlug("/");
  } else {
    return constructPathWithSlug("/about");
  }
};

export const getHomePath = () => {
  const restaurant = store.getState().restaurants
    .currentRestaurant as RestaurantFragment;

  if (restaurant.isOwnerManaged) {
    return constructPathWithParams("/");
  } else {
    return constructPathWithParams("/about");
  }
};

export const getGenericMenuPath = () => {
  const restaurant = store.getState().restaurants
    .currentRestaurant as RestaurantFragment;

  if (restaurant.isOwnerManaged) {
    return constructPathWithSlug("/menu");
  } else {
    return constructPathWithSlug("/");
  }
};

export const getMenuPath = () => {
  const restaurant = store.getState().restaurants
    .currentRestaurant as RestaurantFragment;

  if (restaurant.isOwnerManaged) {
    return constructPathWithParams("/menu");
  } else {
    return constructPathWithParams("/");
  }
};

export const getGenericCateringPath = () => {
  return constructPathWithSlug("/catering");
};

export const getCateringPath = () => {
  return constructPathWithParams("/catering");
};

export const getGenericDealPath = () => {
  return constructPathWithSlug("/deal/:dealId");
};

export const getDealPath = (dealId: string) => {
  return constructPathWithParams(`/deal/${dealId}`);
};

export const getGenericCategoryPath = () => {
  return constructPathWithSlug("/category/:categoryId");
};

export const getCategoryPath = (categoryId: string) => {
  return constructPathWithParams(`/category/${categoryId}`);
};

export const getGenericItemPath = () => {
  return constructPathWithSlug("/category/:categoryId/item/:itemId");
};

export const getItemPath = (categoryId: string, itemId: string) => {
  return constructPathWithParams(`/category/${categoryId}/item/${itemId}`);
};

export const getGenericReceiptPath = () => {
  return constructPathWithSlug("/receipt/:orderId");
};

export const getReceiptPath = (orderId: string) => {
  return constructPathWithParams(`/receipt/${orderId}`);
};

export const getGenericCateringInvoicePath = () => {
  return constructPathWithSlug("/catering-invoice/:cateringInvoiceId");
};

export const getCateringInvoicePath = (cateringInvoiceId: string) => {
  return constructPathWithParams(`/catering-invoice/${cateringInvoiceId}`);
};

export const getGenericSignInPath = () => {
  return constructPathWithSlug("/sign-in");
};

export const getSignInPath = (redirectPath?: string) => {
  const baseUrl = "/sign-in";

  if (redirectPath) {
    return constructPathWithParams(`${baseUrl}?redirect=${redirectPath}`);
  }

  return constructPathWithParams(baseUrl);
};

export const getGenericSignUpPath = () => {
  return constructPathWithSlug("/sign-up");
};

export const getSignUpPath = (redirectPath?: string) => {
  const baseUrl = "/sign-up";

  if (redirectPath) {
    return constructPathWithParams(`${baseUrl}?redirect=${redirectPath}`);
  }

  return constructPathWithParams(baseUrl);
};

export const getGenericForgotPasswordPath = () => {
  return constructPathWithSlug("/forgot-password");
};

export const getForgotPasswordPath = (redirectPath?: string) => {
  const baseUrl = "/forgot-password";

  if (redirectPath) {
    return constructPathWithParams(`${baseUrl}?redirect=${redirectPath}`);
  }

  return constructPathWithParams(baseUrl);
};

export const getGenericResetPasswordPath = () => {
  return constructPathWithSlug("/reset-password");
};

export const getResetPasswordPath = (email: string, redirectPath?: string) => {
  const baseUrl = `/reset-password?email=${email}`;

  if (redirectPath) {
    return constructPathWithParams(`${baseUrl}&redirect=${redirectPath}`);
  }

  return constructPathWithParams(baseUrl);
};

export const getGenericTermsPath = () => {
  return constructPathWithSlug("/terms");
};

export const getTermsPath = () => {
  return constructPathWithParams("/terms");
};

export const getGenericAccountPath = () => {
  return constructPathWithSlug("/account");
};

export const getAccountPath = () => {
  return constructPathWithParams("/account");
};

export const getGenericPersonalInfoPath = () => {
  return constructPathWithSlug("/personal-info");
};

export const getPersonalInfoPath = () => {
  return constructPathWithParams("/personal-info");
};

export const getGenericCartPath = () => {
  return constructPathWithSlug("/cart");
};

export const getGenericCateringCartPath = () => {
  return constructPathWithSlug("/catering-cart");
};

export const getCateringCartPath = () => {
  return constructPathWithParams("/catering-cart");
};

export const getCartPath = () => {
  return constructPathWithParams("/cart");
};

export const getGenericCateringCheckoutPath = () => {
  return constructPathWithSlug("/catering-checkout");
};

export const getCateringCheckoutPath = () => {
  return constructPathWithParams("/catering-checkout");
};

export const getGenericCheckoutPath = () => {
  return constructPathWithSlug("/checkout");
};

export const getCheckoutPath = () => {
  return constructPathWithParams("/checkout");
};

export const getGenericWildcardRoute = () => {
  return constructPathWithSlug("*");
};

export const getWildcardRoute = () => {
  return constructPathWithParams("*");
};
