// Class that allows for the connection to a websocket
import { getEnvVariable } from "src/config/getConfig";
import { WEBSOCKET_URL } from "src/api/types";

export class WebsocketClient {
  public websocketServer: WebSocket;
  public isConnecting = false;
  public entityId: string;
  public onMessageRecieved: (message: string) => void;

  public async initializeWebsocketConnection(
    websocketUrl: WEBSOCKET_URL,
    entityId: string,
    callback: (message: string) => void,
  ) {
    this.isConnecting = true;
    this.websocketServer = new WebSocket(
      `${getEnvVariable(
        "THANOS_WEBSOCKET_URL",
      )}${websocketUrl}?entityId=${entityId}`,
    );
    this.onMessageRecieved = callback;
    this.entityId = entityId;

    const waitForSocketState = (socket: WebSocket, state: number) => {
      return new Promise((resolve) => {
        setTimeout(function () {
          if (socket.readyState === state) {
            resolve(null);
          } else {
            waitForSocketState(socket, state).then(resolve);
          }
        }, 5);
      });
    };

    await waitForSocketState(this.websocketServer, 1);

    this.websocketServer.onmessage = (data) => {
      this.onMessageRecieved(data.data.toString());
    };

    this.isConnecting = false;
  }

  public isWebsocketConnected() {
    return this.websocketServer && this.websocketServer.readyState === 1;
  }

  public async gracefullyCloseWebsocketConnection() {
    if (this.websocketServer) {
      await this.websocketServer.close();
    }
  }
}
