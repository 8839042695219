import { useDesign } from "src/common/hooks";
import { SelectableCircle } from "src/components";
import ReactLoading from "react-loading";
import styles from "src/pages/CateringInvoice/PaymentDetails/styles.module.scss";
import classNames from "classnames";
import { PaymentElement } from "@stripe/react-stripe-js";
import { PayButton } from "src/pages/CateringInvoice/PayButton/PayButton";

interface ComponentProps {
  withStripeHooks: boolean;
  cateringInvoiceId: string;
  paymentTypeSelected: "card" | "bank_account";
  isLoadingACHPaymentIntent: boolean;
  isLoadingCardPaymentIntent: boolean;
  onSelectACH: () => void;
  onSelectCard: () => void;
  onPaymentSuccess: () => void;
}

export const PaymentDetails = ({
  withStripeHooks,
  cateringInvoiceId,
  paymentTypeSelected,
  isLoadingACHPaymentIntent,
  isLoadingCardPaymentIntent,
  onSelectACH,
  onSelectCard,
  onPaymentSuccess,
}: ComponentProps) => {
  const design = useDesign();

  return (
    <>
      <div className={styles.PaymentDetails}>
        <h4>Payment Details</h4>
        <div className={styles.paymentTypeRow} onClick={onSelectACH}>
          <SelectableCircle
            testID="ach-payment-option"
            isSelected={paymentTypeSelected === "bank_account"}
          />
          <h4 className={styles.paymentTypeText}>ACH Direct Debit</h4>
          {isLoadingACHPaymentIntent && (
            <ReactLoading
              type="spin"
              color={design.buttonColor}
              height={20}
              width={20}
            />
          )}
        </div>
        <h4
          className={classNames(styles.paymentTypeDescription, {
            [styles.paymentTypeSelected]:
              paymentTypeSelected === "bank_account",
          })}
        >
          ACH payments are free and don't incur any additional fees.
        </h4>
        {paymentTypeSelected === "bank_account" &&
          !isLoadingACHPaymentIntent && <PaymentElement />}
        <div
          className={classNames(styles.paymentTypeRow, {
            [styles.cardPaymentTypeSelected]: paymentTypeSelected === "card",
            [styles.cardPaymentTypeUnselected]: paymentTypeSelected !== "card",
          })}
          onClick={onSelectCard}
        >
          <SelectableCircle
            isSelected={paymentTypeSelected === "card"}
            testID="card-payment-option"
          />
          <h4 className={styles.paymentTypeText}>Credit or Debit Card</h4>
          {isLoadingCardPaymentIntent && (
            <ReactLoading
              type="spin"
              color={design.buttonColor}
              height={20}
              width={20}
            />
          )}
        </div>
        <h4
          className={classNames(styles.paymentTypeDescription, {
            [styles.paymentTypeSelected]: paymentTypeSelected === "card",
          })}
        >
          Credit or Debit cards incur an additional 3% payment processing fee.
        </h4>
        {paymentTypeSelected === "card" && !isLoadingCardPaymentIntent && (
          <PaymentElement />
        )}
      </div>
      <PayButton
        withStripeHooks={withStripeHooks}
        cateringInvoiceId={cateringInvoiceId}
        onPaymentSuccess={onPaymentSuccess}
      />
    </>
  );
};
