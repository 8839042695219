// Actions associated with restaurants
import { Dispatch } from "redux";

export enum GLOBAL_UI_VARIANT_ACTION {
  SET_UI_VARIANTS = "SET_UI_VARIANTS",
}

export interface SetUIVariants {
  type: GLOBAL_UI_VARIANT_ACTION.SET_UI_VARIANTS;
}

export const setUIVariants = () => {
  return async (dispatch: Dispatch<SetUIVariants>) => {
    const action = {
      type: GLOBAL_UI_VARIANT_ACTION.SET_UI_VARIANTS,
    } as SetUIVariants;

    dispatch(action);

    return action;
  };
};
