import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TextInput, Button, Image } from "src/components";
import styles from "src/pages/ForgotPassword/styles.module.scss";
import { State } from "src/state/state";
import { getResetPasswordPath } from "src/Router/routes";
import { isValidEmailAddress } from "src/common/validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import ReactLoading from "react-loading";
import { captureManualSentryException } from "src/common/sentry";
import { sendForgotPasswordEmailInAWSCognito } from "src/common/auth";
import { logForgotPasswordInitiated } from "src/common/analytics";
import { useScrollToTop, useDesign } from "src/common/hooks";

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const design = useDesign();
  useScrollToTop();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect");

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );

  const [emailAddress, setEmailAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorBox, setErrorBox] = useState<string | undefined>(undefined);

  const [error, setError] = useState<string>();

  const areFieldsValid = useCallback(() => {
    if (emailAddress.trim() === "") {
      setError("Please enter an email address.");
      return false;
    }

    if (!isValidEmailAddress(emailAddress.trim())) {
      setError("Please enter a valid email address.");
      return false;
    }

    return true;
  }, [emailAddress]);

  const handleForgotPassword = useCallback(async () => {
    if (restaurant) {
      try {
        setIsLoading(true);

        logForgotPasswordInitiated();
        await sendForgotPasswordEmailInAWSCognito(emailAddress);

        setIsLoading(false);

        if (redirectUrl) {
          navigate(getResetPasswordPath(emailAddress, redirectUrl));
        } else {
          navigate(getResetPasswordPath(emailAddress));
        }
      } catch (e) {
        setIsLoading(false);
        captureManualSentryException(e as Error);
        setErrorBox("Something went wrong. Please try again.");
      }
    }
  }, [emailAddress, restaurant, redirectUrl, dispatch, navigate]);

  if (!restaurant) {
    captureManualSentryException(
      new Error("restaurant is not defined in ForgotPassword"),
    );

    return <div />;
  }

  return (
    <div
      className={styles.ForgotPassword}
      data-testid="forgot-password-container"
    >
      {restaurant.isOwnerManaged ? (
        <Image src={restaurant.logoUrl} alt="logo" className={styles.logo} />
      ) : (
        <div className={styles.logo} />
      )}
      <h1 className={styles.headingText}>{`Forgot Password`}</h1>
      <TextInput
        className={styles.input}
        testId="email-address-input"
        label="Email Address"
        type="email"
        autoComplete="email"
        value={emailAddress}
        onChangeText={(newText) => {
          setEmailAddress(newText);
          setError(undefined);
        }}
        placeholder="Enter your email address..."
        errorMessage={error}
      />
      {errorBox && (
        <div className={styles.errorBox}>
          <FontAwesomeIcon
            className={styles.errorIcon}
            icon={faTriangleExclamation}
          />
          <p data-testid="error-box-text" className={styles.errorBoxText}>
            {errorBox}
          </p>
        </div>
      )}
      {isLoading ? (
        <ReactLoading
          type="spin"
          color={design.buttonColor}
          height={40}
          width={40}
        />
      ) : (
        <Button
          className={styles.resetPasswordButton}
          testId="reset-password-button"
          onClick={async () => {
            setErrorBox(undefined);

            if (areFieldsValid()) {
              await handleForgotPassword();
            }
          }}
        >
          <h3 className={styles.buttonText}>Reset Password</h3>
        </Button>
      )}
    </div>
  );
};
