// Global variant reducer
import {
  GLOBAL_UI_VARIANT_ACTION,
  SetUIVariants,
} from "src/state/globalUIVariant/actions";
import { GlobalUIVariantState } from "src/state/globalUIVariant/types";
import { selectRandomUIVariant } from "src/state/globalUIVariant/utils";

type GlobalUIVariantAction = SetUIVariants;

export const globalUIVariantReducer = (
  state: GlobalUIVariantState = {},
  action: GlobalUIVariantAction,
) => {
  switch (action.type) {
    case GLOBAL_UI_VARIANT_ACTION.SET_UI_VARIANTS: {
      const next: GlobalUIVariantState = { ...state };

      next.promotionalCheckboxVariant = selectRandomUIVariant(2);

      return next;
    }
    default: {
      return state;
    }
  }
};
