import styles from "src/pages/CateringCart/CateringCartItem/styles.module.scss";
import classNames from "classnames";
import { ScheduledCateringCartItemFragment } from "src/state/scheduledCateringCart/types";
import { useDispatch, useSelector } from "react-redux";
import { State } from "src/state/state";
import { useCallback, useMemo, useState } from "react";
import { ItemFragment } from "src/state/item/types";
import { BasicModal, Image, QuantityPicker } from "src/components";
import { roundToNearestCent } from "src/common/price";
import {
  removeEntityFromScheduledCateringCartAction,
  updateEntityInScheduledCateringCartAction,
} from "src/state/scheduledCateringCart/actions";
import { captureManualSentryException } from "src/common/sentry";

interface CartItemProps {
  cartItem: ScheduledCateringCartItemFragment;
  className?: string;
}

export const CateringCartItem = ({ cartItem, className }: CartItemProps) => {
  const dispatch = useDispatch();

  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);
  const [isRemovingCartItem, setIsRemovingCartItem] = useState(false);
  const items = useSelector((state: State) => state.items);

  const allItemsObject = useMemo(() => {
    let allItemsObject: { [itemId: string]: ItemFragment } = {};

    for (const categoryId in items) {
      const itemsInCategory = items[categoryId];
      allItemsObject = {
        ...allItemsObject,
        ...itemsInCategory,
      };
    }

    return allItemsObject;
  }, [items]);

  const item = useMemo(
    () => cartItem && allItemsObject[cartItem.itemId],
    [allItemsObject, cartItem],
  );

  const handleUpdateCartItemQuantity = useCallback(
    async (newQuantity: number) => {
      const newTotalPrice = item.price * newQuantity;

      await updateEntityInScheduledCateringCartAction(cartItem.id, {
        ...cartItem,
        quantity: newQuantity,
        totalPrice: roundToNearestCent(newTotalPrice),
      })(dispatch);
    },
    [cartItem, item, dispatch],
  );

  const handleRemoveCartItem = useCallback(async () => {
    setIsRemovingCartItem(true);
    await removeEntityFromScheduledCateringCartAction(cartItem.id)(dispatch);
    setIsRemoveModalVisible(false);
    setIsRemovingCartItem(false);
  }, [cartItem, dispatch]);

  if (!item) {
    captureManualSentryException(
      new Error("item is undefined in CateringCartItem"),
    );
    return <div />;
  }

  return (
    <div
      className={classNames(styles.CartItem, className)}
      data-testid={`cart-item-${cartItem.itemId}`}
    >
      {item.hasImage && (
        <Image src={item.imageURL} className={styles.itemImage} />
      )}
      <div
        className={classNames(styles.infoRows, {
          [styles.infoRowsWithoutImage]: !item.hasImage,
        })}
      >
        <div className={styles.row}>
          <h3 className={styles.itemName}>{item.name}</h3>
          <h3 className={styles.itemPrice} data-testid="price-before-options">
            ${cartItem.totalPrice.toFixed(2)}
          </h3>
        </div>
        <div className={styles.editRow}>
          <QuantityPicker
            withBorder={false}
            quantity={cartItem.quantity}
            onQuantityChange={(newQuantity) => {
              handleUpdateCartItemQuantity(newQuantity);
            }}
            className={styles.quantityPicker}
          />
          <button
            data-testid="remove-button"
            className={styles.removeButton}
            onClick={() => setIsRemoveModalVisible(true)}
          >
            Remove
          </button>
        </div>
      </div>
      <BasicModal
        testId="remove-cart-item-modal"
        isModalVisible={isRemoveModalVisible}
        onClickOutside={() => setIsRemoveModalVisible(false)}
        onCancel={() => setIsRemoveModalVisible(false)}
        onConfirm={() => {
          handleRemoveCartItem();
        }}
        title="Remove Item"
        message="Are you sure you want to remove this item from your cart?"
        isLoading={isRemovingCartItem}
      />
    </div>
  );
};
