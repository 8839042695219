import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { logTaxesAndFeesToolTipClickedToAnalyticsForCatering } from "src/common/analytics";
import { captureManualSentryException } from "src/common/sentry";
import { getServiceFeeForScheduledCateringOrder } from "src/common/serviceFee";
import { BasicModal } from "src/components";
import styles from "src/pages/Checkout/CheckoutOrderDetails/CheckoutPriceDetails/styles.module.scss";
import { ScheduledCateringCartItemFragment } from "src/state/scheduledCateringCart/types";
import { State } from "src/state/state";

interface ComponentProps {
  onTotalCalculated: (total: number) => void;
}

export const CheckoutPriceDetails = ({ onTotalCalculated }: ComponentProps) => {
  const [isFeesModalVisible, setIsFeesModalVisible] = useState(false);

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const cart = useSelector((state: State) => state.scheduledCateringCart);
  const checkoutSession = useSelector(
    (state: State) => state.scheduledCateringCheckoutSession,
  );
  const restaurantTipAmount = useSelector(
    (state: State) => state.scheduledCateringCheckoutSession.order.tipAmount,
  );
  const cartItems = useMemo(() => {
    return Object.values(cart) as ScheduledCateringCartItemFragment[];
  }, [cart]);
  const restaurantSettings = useSelector(
    (state: State) => state.restaurants.currentRestaurant?.restaurantSettings,
  );

  const subtotal = useMemo(
    () => cartItems && cartItems.reduce((a, v) => a + v.totalPrice, 0),
    [cartItems],
  );

  const salesTax = useMemo(() => {
    return checkoutSession.order.salesTax;
  }, [checkoutSession]);

  const serviceFee = useMemo(() => {
    return subtotal > 0 && restaurant
      ? getServiceFeeForScheduledCateringOrder(subtotal)
      : 0;
  }, [subtotal, restaurant]);

  const total = useMemo(() => {
    return subtotal + salesTax + restaurantTipAmount + serviceFee;
  }, [subtotal, salesTax, restaurantTipAmount, serviceFee]);

  useEffect(() => {
    onTotalCalculated(total);
  }, [onTotalCalculated, total]);

  if (!restaurant) {
    captureManualSentryException(
      new Error("restaurant is undefined in CateringCheckoutPriceDetails"),
    );
    return <div />;
  }

  return (
    <div className={styles.CheckoutPriceDetails}>
      <div className={styles.priceRow}>
        <h4 className={styles.priceRowText}>Subtotal</h4>
        <h4 className={styles.priceRowText} data-testid="subtotal">
          ${subtotal.toFixed(2)}
        </h4>
      </div>
      <div className={styles.priceRow}>
        <h4 className={styles.priceRowText}>Restaurant Delivery Fee</h4>
        <h4 className={styles.priceRowText} data-testid="delivery-fee">
          ${restaurantSettings?.cateringDeliveryFee.toFixed(2)}
        </h4>
      </div>
      <div className={styles.priceRow}>
        <div className={styles.taxesAndFeesRow}>
          <h4 className={styles.priceRowText}>Taxes & Fees</h4>
          <FontAwesomeIcon
            data-testid="taxes-and-fees-info-icon"
            className={styles.circleInfoIcon}
            icon={faCircleInfo}
            onClick={() => {
              logTaxesAndFeesToolTipClickedToAnalyticsForCatering(
                checkoutSession.order.customerId,
              );
              setIsFeesModalVisible(true);
            }}
          />
        </div>
        <h4 className={styles.priceRowText} data-testid="sales-tax">
          ${(salesTax + serviceFee).toFixed(2)}
        </h4>
      </div>
      {restaurant.restaurantSettings.isRestaurantTipsEnabled && (
        <div className={styles.priceRow}>
          <h4 className={styles.priceRowText}>Tips</h4>
          <h4 className={styles.priceRowText} data-testid="tip-amount">
            ${restaurantTipAmount.toFixed(2)}
          </h4>
        </div>
      )}
      <div className={styles.priceRow}>
        <h4 className={styles.orderTotalText}>Order Total</h4>
        <h4 className={styles.orderTotalText} data-testid="total">
          ${total.toFixed(2)}
        </h4>
      </div>
      <BasicModal
        height="320"
        testId="taxes-and-fees-modal"
        isModalVisible={isFeesModalVisible}
        title={"Taxes & Fees"}
        message={
          <>
            <p data-testid="modal-sales-tax">{`Sales Tax: $${salesTax.toFixed(
              2,
            )}`}</p>
            <p data-testid="modal-service-fee">{`Service Fee: $${serviceFee.toFixed(
              2,
            )}`}</p>
            <p>
              We use Platter to power our amazing website & mobile app. This
              tiny service fee helps keep the lights on.
            </p>
          </>
        }
        onConfirm={() => setIsFeesModalVisible(false)}
        onClickOutside={() => setIsFeesModalVisible(false)}
        confirmText="Ok"
      />
    </div>
  );
};
