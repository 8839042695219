import { useMemo, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logAddToCartFromUpsellToAnalytics } from "src/common/analytics";
import { formatNumber } from "src/common/number";
import { Button } from "src/components";
import styles from "src/pages/Cart/CartUpsell/styles.module.scss";
import { addItemToCartAction } from "src/state/cart/actions";
import { CartItemFragment } from "src/state/cart/types";
import { ItemFragment, UpsellItemsFragment } from "src/state/item/types";
import { selectUpsellItemsFromState } from "src/state/item/utils";
import { isCartItemADeal } from "src/state/order/utils";
import { State } from "src/state/state";

export const CartUpsell = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state: State) => state.cart);

  const upSellItems: UpsellItemsFragment = useSelector((state: State) =>
    selectUpsellItemsFromState(state),
  );

  const [addingToCart, setAddingToCart] = useState<string | null>(null);

  const selectedUpsellItems: ItemFragment[] = useMemo(() => {
    for (const cartItemId in cart) {
      if (isCartItemADeal(cart[cartItemId])) {
        continue;
      }

      const itemId = (cart[cartItemId] as CartItemFragment).itemId;

      if (upSellItems[itemId]) {
        delete upSellItems[itemId];
      }
    }

    const upSellItemIds = Object.keys(upSellItems);
    const upSellItemsCopy: ItemFragment[] = [];

    while (upSellItemsCopy.length < 3 && upSellItemIds.length > 0) {
      const randomIndex = Math.floor(Math.random() * upSellItemIds.length);
      const randomItemId = upSellItemIds.splice(randomIndex, 1)[0];
      upSellItemsCopy.push(upSellItems[randomItemId]);
    }

    return upSellItemsCopy;
  }, []);

  const handleAddItemToCart = useCallback(
    async (item: ItemFragment) => {
      setAddingToCart(item.id);

      await addItemToCartAction(
        item.id,
        1,
        {},
        item.price,
        item.price,
      )(dispatch);

      logAddToCartFromUpsellToAnalytics(item.id, item.price);

      setAddingToCart(null);
    },
    [dispatch],
  );

  if (selectedUpsellItems.length === 0) {
    return null;
  }
  return (
    <div className={styles.CartUpsell} data-testid="cart-upsell-container">
      <div className={styles.upsellTitle} data-testid="upsell-title">
        Want to add anything else? Here are customer fav's:
      </div>
      <div
        className={styles.upsellItemsContainer}
        data-testid="upsellItemsContainer"
      >
        {selectedUpsellItems.map((item) => {
          return (
            <div
              className={styles.upsellItem}
              key={item.id}
              data-testid={`upsell-item-${item.id}`}
            >
              <div
                className={styles.upsellNameAndPriceContainer}
                data-testid={`upsell-name-and-price-container-${item.id}`}
              >
                <div
                  className={styles.upsellItemName}
                  data-testid={`upsell-item-name-${item.id}`}
                >
                  {item.name}
                </div>
                <div
                  className={styles.upsellItemPrice}
                  data-testid={`upsell-item-price-${item.id}`}
                >
                  {formatNumber(item.price)}
                </div>
              </div>
              <div
                className={styles.upsellAddButtonContainer}
                data-testid={`upsell-add-button-container-${item.id}`}
              >
                <Button
                  className={styles.upsellAddButton}
                  testId={`upsell-add-button-${item.id}`}
                  onClick={async () => await handleAddItemToCart(item)}
                  disabled={addingToCart === item.id}
                >
                  <h4 className={styles.upsellAddButtonText}>Add</h4>
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
