import styles from "src/components/Button/styles.module.scss";
import classNames from "classnames";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  testId?: string;
  children?: React.ReactNode;
  secondary?: boolean;
}

export const Button = (props: ButtonProps) => {
  const { className, testId, children, secondary, ...rest } = props;

  return (
    <button
      data-testid={testId}
      className={classNames(
        styles.Button,
        {
          [styles.secondary]: secondary,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </button>
  );
};
