import classNames from "classnames";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { captureManualSentryException } from "src/common/sentry";
import { OptionValueFragment } from "src/common/types/OptionValue";
import styles from "src/pages/Cart/CartDeal/CartDealCard/styles.module.scss";
import { CartDealFragment } from "src/state/cart/types";
import { DEAL_TYPE, DealFragment } from "src/state/deal/types";
import { getAllItemsObjectFromState } from "src/state/item/utils";
import { OptionFragment } from "src/state/option/types";
import { State } from "src/state/state";

interface ComponentProps {
  cartDeal: CartDealFragment;
  deal: DealFragment;
}

export const CartDealCard = ({ cartDeal }: ComponentProps) => {
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const items = useSelector((state: State) => state.items);
  const options = useSelector(
    (state: State) => restaurant && state.options[restaurant.id],
  );
  const deal = useSelector(
    (state: State) => restaurant && state.deals[restaurant.id][cartDeal.dealId],
  );

  const dealItems = useMemo(() => {
    const allItemsObject = getAllItemsObjectFromState(items);

    return cartDeal.itemIdsSelected.map((itemId) => allItemsObject[itemId]);
  }, [items, cartDeal]);

  const selectedOptionsArray = useMemo(() => {
    const finalArray: {
      option: OptionFragment;
      optionValue: OptionValueFragment[];
    }[][] = [];

    if (options) {
      for (let i = 0; i < cartDeal.optionValuesSelected.length; i++) {
        const thisItemArray: {
          option: OptionFragment;
          optionValue: OptionValueFragment[];
        }[] = [];
        const itemOptions = Object.values(options).filter((option) =>
          option.itemIds.includes(dealItems[i].id),
        );
        const optionsValuesSelectedForEachOption =
          cartDeal.optionValuesSelected[i];

        for (const optionId in optionsValuesSelectedForEachOption) {
          const option = itemOptions.find(
            (option) => option.id === optionId,
          ) as OptionFragment;
          const optionValue = optionsValuesSelectedForEachOption[optionId];
          thisItemArray.push({
            option,
            optionValue,
          });
        }

        finalArray.push(thisItemArray);
      }
    }

    return finalArray;
  }, [cartDeal, options]);

  if (
    !items ||
    !selectedOptionsArray ||
    !dealItems ||
    !cartDeal ||
    !deal ||
    !restaurant ||
    !options
  ) {
    captureManualSentryException(
      new Error(
        "items, selectedOptionsArray, dealItems, deal, restaurant, options, or cartDeal is undefined in CartBogoDeal",
      ),
    );
    return <div />;
  }

  return (
    <div className={styles.CartDealCard}>
      {dealItems.map((item, index) => {
        const { priceBeforeDealBeforeOptions, priceAfterDealBeforeOptions } =
          cartDeal;
        const thisItemBeforeDealBeforeOptions =
          priceBeforeDealBeforeOptions[index];
        const thisItemAfterDealBeforeOptions =
          priceAfterDealBeforeOptions[index];

        return (
          <div key={index}>
            <div
              className={styles.row}
              data-testid={`cart-deal-item-${item.id}`}
            >
              <h3 className={styles.dealItemName} data-testid={`name`}>
                {item.name}
              </h3>

              {deal.type !== DEAL_TYPE.COMBO && (
                <div className={styles.strikeThroughRow}>
                  {thisItemBeforeDealBeforeOptions !==
                    thisItemAfterDealBeforeOptions && (
                    <h3
                      className={styles.dealItemPrice}
                      data-testid={`discounted-price`}
                    >
                      {`$${thisItemAfterDealBeforeOptions.toFixed(2)}`}
                    </h3>
                  )}

                  <h3
                    data-testid={`original-price`}
                    className={classNames(styles.dealItemPrice, {
                      [styles.strikeThroughPrice]:
                        thisItemBeforeDealBeforeOptions !==
                        thisItemAfterDealBeforeOptions,
                    })}
                  >
                    {`$${thisItemBeforeDealBeforeOptions.toFixed(2)}`}
                  </h3>
                </div>
              )}
            </div>
            {selectedOptionsArray[index].map((selectedOption) => {
              const optionValuesNames = selectedOption.optionValue
                .map((optionValue) => optionValue.name)
                .join(", ");
              const optionValuesTotalPrice = selectedOption.optionValue.reduce(
                (totalPrice, optionValue) => totalPrice + optionValue.price,
                0,
              );

              return (
                <div
                  className={styles.row}
                  key={selectedOption.option.id}
                  data-testid={`item-option-${selectedOption.option.id}`}
                >
                  <p
                    className={styles.optionName}
                    data-testid={"selection-name"}
                  >{`${selectedOption.option.name}: ${optionValuesNames}`}</p>
                  <p
                    className={styles.optionPrice}
                    data-testid={"selection-price"}
                  >
                    ${optionValuesTotalPrice.toFixed(2)}
                  </p>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
