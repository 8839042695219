// Actions associated with restaurants
import { Dispatch } from "redux";
import { RestaurantFragment } from "src/state/restaurant/types";

export enum RESTAURANT_ACTION {
  SET_RESTAURANT_FROM_DATABASE_ACTION = "SET_RESTAURANT_FROM_DATABASE_ACTION",
}

export interface SetRestaurantFromDatabaseAction {
  type: RESTAURANT_ACTION.SET_RESTAURANT_FROM_DATABASE_ACTION;
  response: RestaurantFragment;
}

export const setRestaurantFromDatabaseAction = (
  restaurant: RestaurantFragment,
) => {
  return async (dispatch: Dispatch<SetRestaurantFromDatabaseAction>) => {
    const action = {
      type: RESTAURANT_ACTION.SET_RESTAURANT_FROM_DATABASE_ACTION,
      response: {
        ...restaurant,
        // Resets the image url to force a reload
        logoUrl: `${restaurant.logoUrl}?${Date.now()}`,
        coverPhotoUrl: `${restaurant.coverPhotoUrl}?${Date.now()}`,
      },
    } as SetRestaurantFromDatabaseAction;

    dispatch(action);

    return action;
  };
};
