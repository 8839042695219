// Restaurant reducer
import {
  MENU_ACTION,
  SetMenusForRestaurantFromDatabaseAction,
} from "src/state/menu/actions";
import { MenuState } from "src/state/menu/types";

type MenuAction = SetMenusForRestaurantFromDatabaseAction;

export const menuReducer = (state: MenuState = {}, action: MenuAction) => {
  switch (action.type) {
    case MENU_ACTION.SET_MENUS_FOR_RESTAURANT_FROM_DATABASE_ACTION: {
      const next: MenuState = { ...state };

      const { response: menus, restaurantId } = action;

      next[restaurantId] = {};

      for (const menu of menus) {
        next[restaurantId][menu.id] = menu;
      }

      return next;
    }
    default: {
      return state;
    }
  }
};
