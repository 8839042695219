export const isInDemoEnvironment = (): boolean => {
  let rootDomain = window.location.hostname;
  if (rootDomain.startsWith("www.")) {
    rootDomain = rootDomain.substring(4);
  }
  return rootDomain.startsWith("demo.");
};

export const isSlugEnvironment = (): boolean => {
  const rootDomain = window.location.hostname;
  if (rootDomain.includes("whatisthemenu")) {
    return true;
  }

  return false;
};
