import { Provider } from "react-redux";
import { store } from "src/state/store";
import { Router } from "src/Router/Router";
import { ThemeProvider } from "src/themeProvider";
import "leaflet/dist/leaflet.css";
import iconRetinaUrl from "leaflet/dist/images/marker-icon-2x.png";
import iconUrl from "leaflet/dist/images/marker-icon.png";
import shadowUrl from "leaflet/dist/images/marker-shadow.png";
import L from "leaflet";
import { useEffect } from "react";
import { getCurrentEnvironment } from "src/config/getConfig";

L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
});

export const App = () => {
  useEffect(() => {
    if (getCurrentEnvironment() === "test") {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).store = store;
    }
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </Provider>
  );
};
