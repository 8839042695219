import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBagShopping,
  faCalendar,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import styles from "src/pages/CateringCheckout/CheckoutOrderDetails/CheckoutOrderDetailsOptions/styles.module.scss";
import { useSelector } from "react-redux";
import { State } from "src/state/state";
import { captureManualSentryException } from "src/common/sentry";
import { formatISOStringToLongDateAndTime } from "src/common/date";

export const CheckoutOrderDetailsOptions = () => {
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const checkoutSession = useSelector(
    (state: State) => state.scheduledCateringCheckoutSession,
  );
  if (!restaurant || !checkoutSession) {
    captureManualSentryException(
      new Error("restaurant is undefined in CheckoutOrderDetailsOptions"),
    );
    return <div />;
  }
  const niceLookingEventTime = formatISOStringToLongDateAndTime(
    checkoutSession.order.eventTime,
  );
  return (
    <div className={styles.CheckoutOrderDetailsOptions}>
      <h1 className={styles.title}>Order Details</h1>
      <div className={styles.orderDetailOptionRow}>
        <FontAwesomeIcon className={styles.icon} icon={faBagShopping} />
        <p
          className={styles.orderDetailsOptionSelection}
          data-testid="order-type"
        >
          Catering
        </p>
      </div>
      <div className={styles.orderDetailOptionRow}>
        <FontAwesomeIcon className={styles.icon} icon={faCalendar} />
        <p
          className={styles.orderDetailsOptionSelection}
          data-testid="event-time"
        >
          {niceLookingEventTime}
        </p>
      </div>
      <div className={styles.orderDetailOptionRow}>
        <FontAwesomeIcon className={styles.icon} icon={faLocationDot} />
        <p
          className={styles.orderDetailsOptionSelection}
          data-testid="event-location"
        >
          {checkoutSession.order.eventLocation}
        </p>
      </div>
    </div>
  );
};
