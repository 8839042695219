import styles from "src/components/Checkbox/styles.module.scss";
import classNames from "classnames";

interface CheckboxProps {
  className?: string;
  testId?: string;
  checked: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  size?: "small" | "medium" | "large";
}

export const Checkbox = ({
  className,
  testId,
  checked,
  onChange,
  disabled,
  size = "medium",
}: CheckboxProps) => {
  return (
    <div
      className={classNames(styles.checkboxContainer, className, {
        [styles.disabled]: disabled,
        [styles.smallContainer]: size === "small",
        [styles.mediumContainer]: size === "medium",
        [styles.largeContainer]: size === "large",
      })}
    >
      <input
        className={classNames(styles.Checkbox)}
        data-testid={testId}
        type="checkbox"
        checked={checked}
        onChange={(event) =>
          onChange ? onChange(event.target.checked) : undefined
        }
        disabled={disabled}
      />
      <div
        className={classNames(styles.customCheckbox, {
          [styles.checked]: checked,
          [styles.small]: size === "small",
          [styles.medium]: size === "medium",
          [styles.large]: size === "large",
        })}
      />
    </div>
  );
};
