import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isValidEmailAddress, isValidPhoneNumber } from "src/common/validation";
import { TextInput, Button, Image } from "src/components";
import styles from "src/pages/PersonalInfo/styles.module.scss";
import { State } from "src/state/state";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import ReactLoading from "react-loading";
import { updateCustomerInDatabaseAction } from "src/state/customer/actions";
import { getAccountPath, getSignInPath } from "src/Router/routes";
import { updateUserEmailInAWSCognito } from "src/common/auth";
import { captureManualSentryException } from "src/common/sentry";
import { logUpdatePersonalInfoToAnalytics } from "src/common/analytics";
import { useScrollToTop, useDesign } from "src/common/hooks";
interface PersonalInfoFieldsErrors {
  firstName: string | undefined;
  lastName: string | undefined;
  emailAddress: string | undefined;
  phoneNumber: string | undefined;
}

export const PersonalInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useScrollToTop();
  const design = useDesign();
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const customer = useSelector(
    (state: State) => state.customers.currentCustomer,
  );

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessages, setErrorMessages] = useState<PersonalInfoFieldsErrors>({
    firstName: undefined,
    lastName: undefined,
    emailAddress: undefined,
    phoneNumber: undefined,
  });
  const [errorBox, setErrorBox] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const areFieldsValid = useCallback(() => {
    const newErrorMessages = {
      ...errorMessages,
    };

    if (firstName.trim() === "") {
      newErrorMessages.firstName = "Please enter your first name.";
    }

    if (lastName.trim() === "") {
      newErrorMessages.lastName = "Please enter your last name.";
    }

    if (emailAddress.trim() === "") {
      newErrorMessages.emailAddress = "Please enter an email address.";
    }

    if (
      emailAddress.trim() !== "" &&
      !isValidEmailAddress(emailAddress.trim())
    ) {
      newErrorMessages.emailAddress = "Please enter a valid email address.";
    }

    if (phoneNumber.trim() === "") {
      newErrorMessages.phoneNumber = "Please enter a phone number.";
    }

    if (phoneNumber.trim() !== "" && !isValidPhoneNumber(phoneNumber.trim())) {
      newErrorMessages.phoneNumber = "Please enter a valid phone number.";
    }

    for (const key in newErrorMessages) {
      if (newErrorMessages[key] !== undefined) {
        setErrorMessages(newErrorMessages);
        return false;
      }
    }

    return true;
  }, [firstName, lastName, emailAddress, phoneNumber, errorMessages]);

  const handleSave = useCallback(async () => {
    setIsLoading(true);

    if (customer) {
      try {
        await updateUserEmailInAWSCognito(customer.id, emailAddress.trim());
        await updateCustomerInDatabaseAction(
          customer.id,
          firstName.trim(),
          lastName.trim(),
          emailAddress.trim(),
          phoneNumber.trim(),
        )(dispatch);

        logUpdatePersonalInfoToAnalytics(
          customer.id,
          firstName !== customer.firstName,
          lastName !== customer.lastName,
          emailAddress !== customer.email,
          phoneNumber !== customer.phone,
        );

        navigate(getAccountPath());
      } catch (e) {
        setIsLoading(false);
        const errorMessage = typeof e === "string" ? e : (e as Error).message;

        if (errorMessage.includes("already exists")) {
          setErrorBox("An account with this email address already exists.");
        } else {
          captureManualSentryException(e as Error);
          setErrorBox("Something went wrong. Please try again.");
        }
      }
    }

    setIsLoading(false);
  }, [
    customer,
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    if (!customer) {
      navigate(getSignInPath());
    } else {
      setFirstName(customer.firstName);
      setLastName(customer.lastName);
      setEmailAddress(customer.email);
      setPhoneNumber(customer.phone);
    }
  }, [customer]);

  if (!restaurant) {
    captureManualSentryException(
      new Error("restaurant is not defined in PersonalInfo"),
    );

    return <div />;
  }

  if (!customer) {
    return <div />;
  }

  return (
    <div className={styles.PersonalInfo} data-testid="personal-info-container">
      {restaurant.isOwnerManaged ? (
        <Image src={restaurant.logoUrl} alt="logo" className={styles.logo} />
      ) : (
        <div className={styles.logo} />
      )}
      <h1
        className={styles.headingText}
      >{`Your Account with ${restaurant.restaurantName}`}</h1>
      <TextInput
        className={styles.input}
        testId="first-name-input"
        label="First Name"
        autoComplete="given-name"
        value={firstName}
        onChangeText={(newText) => {
          setFirstName(newText);
          setErrorMessages({
            ...errorMessages,
            firstName: undefined,
          });
        }}
        placeholder="Enter your first name..."
        errorMessage={errorMessages.firstName}
      />
      <TextInput
        className={styles.input}
        testId="last-name-input"
        label="Last Name"
        autoComplete="family-name"
        value={lastName}
        onChangeText={(newText) => {
          setLastName(newText);
          setErrorMessages({
            ...errorMessages,
            lastName: undefined,
          });
        }}
        placeholder="Enter your last name..."
        errorMessage={errorMessages.lastName}
      />
      <TextInput
        className={styles.input}
        testId="email-address-input"
        label="Email Address"
        type="email"
        autoComplete="email"
        value={emailAddress}
        onChangeText={(newText) => {
          setEmailAddress(newText);
          setErrorMessages({
            ...errorMessages,
            emailAddress: undefined,
          });
        }}
        placeholder="Enter your email address..."
        errorMessage={errorMessages.emailAddress}
      />
      <TextInput
        className={styles.input}
        testId="phone-number-input"
        label="Phone Number"
        type="tel"
        autoComplete="tel"
        value={phoneNumber}
        onChangeText={(newText) => {
          setPhoneNumber(newText);
          setErrorMessages({
            ...errorMessages,
            phoneNumber: undefined,
          });
        }}
        placeholder="Enter your phone number..."
        errorMessage={errorMessages.phoneNumber}
      />
      {errorBox && (
        <div className={styles.errorBox}>
          <FontAwesomeIcon
            className={styles.errorIcon}
            icon={faTriangleExclamation}
          />
          <p className={styles.errorBoxText} data-testid="error-box-text">
            {errorBox}
          </p>
        </div>
      )}
      {isLoading ? (
        <ReactLoading
          type="spin"
          color={design.buttonColor}
          height={40}
          width={40}
        />
      ) : (
        <Button
          testId="save-info-button"
          className={styles.saveInfoButton}
          onClick={() => {
            setErrorBox(undefined);

            if (areFieldsValid()) {
              handleSave();
            }
          }}
        >
          <h3 className={styles.buttonText}>Save Information</h3>
        </Button>
      )}
    </div>
  );
};
