import { ImgHTMLAttributes } from "react";
import { getCurrentEnvironment } from "src/config/getConfig";
import { testImageUrl } from "src/test/mockData";

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  src?: string;
}

export const Image = ({ src, ...rest }: ImageProps) => {
  const currentEnvironment = getCurrentEnvironment();

  return (
    <img {...rest} src={currentEnvironment === "test" ? testImageUrl : src} />
  );
};
