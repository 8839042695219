import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { State } from "src/state/state";
import Modal from "react-awesome-modal";
import { Button, TextInput } from "src/components";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { useDesign } from "src/common/hooks";
import ReactLoading from "react-loading";
import styles from "src/pages/CateringCart/CateringOrderDetails/OrderDetailsOptions/styles.module.scss";
import usePlacesAutocompleteService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { getCurrentEnvironment, getEnvVariable } from "src/config/getConfig";
import { useMockPlacesService } from "src/__mocks__/useMockPlacesService";

interface OrderDetailsOptionsProps {
  eventTime: string | undefined; // Use string for easier manipulation
  setEventTime: (eventTime: string | undefined) => void;
  eventLocation: string | undefined;
  setEventLocation: (eventLocation: string | undefined) => void;
  receiptEmail: string | undefined;
  setReceiptEmail: (receiptEmail: string | undefined) => void;
}

export const CateringOrderDetailsOptions = ({
  eventTime,
  setEventTime,
  eventLocation,
  setEventLocation,
  receiptEmail,
  setReceiptEmail,
}: OrderDetailsOptionsProps) => {
  const design = useDesign();
  const [isLoading] = useState(false);
  const [eventLocationTextInModal, setEventLocationTextInModal] = useState("");
  const [eventLocationError, setEventLocationError] = useState<
    string | undefined
  >();
  const [eventTimeError, setEventTimeError] = useState<string | undefined>();
  const [eventReceiptEmailTextInModal, setEventReceiptEmailTextInModal] =
    useState("");
  const [eventReceiptEmailError, setEventReceiptEmailError] = useState<
    string | undefined
  >();
  const [isEventDetailsModalOpen, setIsEventDetailsModalOpen] = useState(false);
  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    placesService,
  } =
    getCurrentEnvironment() === "test"
      ? useMockPlacesService()
      : usePlacesAutocompleteService({
          apiKey: getEnvVariable("GOOGLE_MAPS_API_KEY"),
        });

  const customer = useSelector(
    (state: State) => state.customers.currentCustomer,
  );
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );

  const cateringLeadDays = restaurant?.restaurantSettings.cateringLeadDays || 0;

  const syncModalStateWithProps = useCallback(() => {
    setEventLocationTextInModal(eventLocation || "");
    setEventReceiptEmailTextInModal(receiptEmail || customer?.email || "");
  }, [eventLocation, receiptEmail, customer?.email]);

  useEffect(() => {
    if (isEventDetailsModalOpen) {
      syncModalStateWithProps();
    }
  }, [isEventDetailsModalOpen, syncModalStateWithProps]);

  useEffect(() => {
    if (!eventLocation || !eventTime) {
      setIsEventDetailsModalOpen(true);
    }
  }, [eventLocation, eventTime]);

  const isDesktop = useMediaQuery({
    query: "(min-width: 875px)",
  });

  const validateEventDetailsInput = useCallback(() => {
    let isValid = true;

    if (!eventLocationTextInModal.trim()) {
      setEventLocationError("Please enter a valid event location.");
      isValid = false;
    } else {
      setEventLocationError(undefined);
      setEventLocation(eventLocationTextInModal.trim());
    }

    // Convert eventTime string to Date for validation
    const parsedEventTime = eventTime ? new Date(eventTime) : undefined;

    if (!parsedEventTime || isNaN(parsedEventTime.getTime())) {
      setEventTimeError("Please select a valid event date and time.");
      isValid = false;
    } else {
      const leadDate = new Date();
      leadDate.setDate(leadDate.getDate() + cateringLeadDays);
      if (parsedEventTime < leadDate) {
        setEventTimeError(
          `Please choose a date at least ${cateringLeadDays} days from today.`,
        );
        isValid = false;
      } else {
        setEventTimeError(undefined);
      }
    }

    if (!customer?.email && !eventReceiptEmailTextInModal.trim()) {
      setEventReceiptEmailError(
        "Please enter a valid email address to receive the receipt.",
      );
      isValid = false;
    } else {
      setEventReceiptEmailError(undefined);
      setReceiptEmail(eventReceiptEmailTextInModal.trim());
    }

    if (isValid) {
      setIsEventDetailsModalOpen(false);
    }
  }, [
    eventLocationTextInModal,
    eventTime,
    eventReceiptEmailTextInModal,
    customer,
    cateringLeadDays,
    setReceiptEmail,
  ]);

  const minDate = new Date();
  minDate.setDate(minDate.getDate() + cateringLeadDays);

  const showAddressSearchResults = useMemo(() => {
    return (
      placePredictions.length > 0 &&
      !isPlacePredictionsLoading &&
      !eventLocationError
    );
  }, [placePredictions, isPlacePredictionsLoading, eventLocationError]);

  return (
    <div className={styles.OrderDetailsOptions}>
      <h1 className={styles.title}>Catering Order Details</h1>
      <div className={styles.orderDetailOptionRow}>
        <FontAwesomeIcon className={styles.icon} icon={faCalendar} />
        <button
          data-testid="event-details-button"
          className={classNames(styles.orderDetailsModalButton)}
          onClick={() => setIsEventDetailsModalOpen(true)}
        >
          {eventLocation && eventTime
            ? `${eventLocation}, \n ${new Date(eventTime).toLocaleString()}`
            : "Enter Event Details"}
        </button>
      </div>
      <Modal
        visible={isEventDetailsModalOpen}
        width={isDesktop ? "40%" : "80%"}
        height="600"
        effect="fadeInUp"
        onClickAway={() => setIsEventDetailsModalOpen(false)}
        className={styles.eventDetailsModal}
      >
        <div
          className={styles.eventDetailsModalContainer}
          data-testid="event-details-modal"
        >
          <h2 className={styles.modalTitle}>Event Details</h2>
          <div className={styles.inputContainer}>
            <TextInput
              className={styles.addressInput}
              testId="event-location-input"
              label="Event Location"
              placeholder="Enter address of event..."
              value={eventLocationTextInModal}
              onChangeText={(newText) => {
                setEventLocationError(undefined);
                setEventLocationTextInModal(newText);
                getPlacePredictions({
                  input: newText,
                  componentRestrictions: { country: "us" },
                });
              }}
              errorMessage={eventLocationError}
            />
            {isPlacePredictionsLoading && (
              <div className={styles.addressLoading}>
                <ReactLoading
                  color={design.buttonColor}
                  type="spin"
                  height={25}
                  width={25}
                />
              </div>
            )}
            {showAddressSearchResults && (
              <div className={styles.addressPredictions}>
                {placePredictions.map((prediction, index) => (
                  <button
                    key={index}
                    className={styles.addressPredictionButton}
                    data-testid="address-autocomplete-option"
                    onClick={() => {
                      placesService?.getDetails(
                        { placeId: prediction.place_id },
                        (placeDetails) => {
                          const zipCode = placeDetails.address_components.find(
                            (component) =>
                              component.types.includes("postal_code"),
                          )?.long_name;
                          const fullAddress = zipCode
                            ? `${prediction.description}, ${zipCode}`
                            : prediction.description;
                          setEventLocationTextInModal(fullAddress);
                          setEventLocation(fullAddress);
                          getPlacePredictions({ input: "" });
                        },
                      );
                    }}
                  >
                    <p className={styles.addressPredictionText}>
                      {prediction.description}
                    </p>
                  </button>
                ))}
              </div>
            )}
          </div>
          <div className={styles.datePickerContainer}>
            <TextInput
              className={styles.input}
              testId="event-date-time-input"
              label="Event Date & Time*"
              value={eventTime || ""}
              onChangeText={(newText) => {
                setEventTime(newText);
                setEventTimeError(undefined);
              }}
              placeholder="Enter the event date and time..."
              type="datetime-local"
              min={minDate.toISOString().slice(0, 16)}
              errorMessage={eventTimeError}
            />
          </div>
          <div className={styles.inputContainer}>
            <TextInput
              className={styles.addressInput}
              testId="event-receipt-email-input"
              label="Receipt Email"
              placeholder="Enter email to receive receipt..."
              value={eventReceiptEmailTextInModal}
              onChangeText={(newText) => {
                setEventReceiptEmailError(undefined);
                setEventReceiptEmailTextInModal(newText);
              }}
              errorMessage={eventReceiptEmailError}
            />
          </div>
          {isLoading ? (
            <div className={styles.loadingContainer}>
              <ReactLoading
                color={design.buttonColor}
                type="spin"
                height={25}
                width={25}
              />
            </div>
          ) : (
            <div className={styles.buttonsContainer}>
              <Button
                secondary={true}
                className={styles.cancelButton}
                onClick={() => setIsEventDetailsModalOpen(false)}
                testId="cancel-button"
              >
                <h3 className={styles.buttonText}>Cancel</h3>
              </Button>
              <Button
                className={styles.saveButton}
                onClick={validateEventDetailsInput}
                testId="save-button"
              >
                <h3 className={styles.buttonTextSave}>Save</h3>
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};
