// Common sentry code
import * as Sentry from "@sentry/react";
import { getCurrentEnvironment } from "src/config/getConfig";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const captureManualSentryException = (error: any) => {
  if (getCurrentEnvironment() === "prod") {
    Sentry.captureException(error);
  }
};
