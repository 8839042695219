// Restaurant wide discount reducer
import {
  RESTAURANT_WIDE_DISCOUNT_ACTION,
  SetRestaurantWideDiscountForRestaurantAction,
} from "src/state/restaurantWideDiscount/actions";
import { RestaurantWideDiscountState } from "src/state/restaurantWideDiscount/types";

type RestaurantWideDiscountAction =
  SetRestaurantWideDiscountForRestaurantAction;

export const restaurantWideDiscountReducer = (
  state: RestaurantWideDiscountState = {},
  action: RestaurantWideDiscountAction,
) => {
  switch (action.type) {
    case RESTAURANT_WIDE_DISCOUNT_ACTION.SET_RESTAURANT_WIDE_DISCOUNT_FOR_RESTAURANT_ACTION: {
      const { response: restaurantWideDiscount, restaurantId } = action;

      const next: RestaurantWideDiscountState = {
        ...state,
      };

      next[restaurantId] = restaurantWideDiscount.restaurantWideDiscount;

      return next;
    }
    default: {
      return state;
    }
  }
};
