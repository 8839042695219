// Actions associated with order type
import { Dispatch } from "redux";

export enum ORDER_BANNER_ACTION {
  UPDATE_ORDER_DISPLAYED_ACTION = "UPDATE_ORDER_DISPLAYED_ACTION",
}

export interface UpdateOrderBannerDisplayedAction {
  type: ORDER_BANNER_ACTION.UPDATE_ORDER_DISPLAYED_ACTION;
  response: boolean;
}

export const updateOrderBannerDisplayedAction = (isDisplayed: boolean) => {
  return async (dispatch: Dispatch<UpdateOrderBannerDisplayedAction>) => {
    const action = {
      type: ORDER_BANNER_ACTION.UPDATE_ORDER_DISPLAYED_ACTION,
      response: isDisplayed,
    } as UpdateOrderBannerDisplayedAction;

    dispatch(action);

    return action;
  };
};
