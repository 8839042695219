import styles from "src/pages/Cart/CartDeal/styles.module.scss";
import classNames from "classnames";
import { CartDealFragment } from "src/state/cart/types";
import { useDispatch, useSelector } from "react-redux";
import { State } from "src/state/state";
import { useCallback, useState } from "react";
import { BasicModal, Image } from "src/components";
import { removeEntityFromCartAction } from "src/state/cart/actions";
import { captureManualSentryException } from "src/common/sentry";
import { CartDealCard } from "src/pages/Cart/CartDeal/CartDealCard/CartDealCard";
import { DEAL_TYPE } from "src/state/deal/types";

interface CartDealProps {
  cartDeal: CartDealFragment;
  className?: string;
}

export const CartDeal = ({ cartDeal, className }: CartDealProps) => {
  const dispatch = useDispatch();

  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);
  const [isRemovingCartDeal, setIsRemovingCartDeal] = useState(false);

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const deal = useSelector(
    (state: State) => restaurant && state.deals[restaurant.id][cartDeal.dealId],
  );

  const handleRemoveCartDeal = useCallback(async () => {
    setIsRemovingCartDeal(true);
    await removeEntityFromCartAction(cartDeal.id)(dispatch);
    setIsRemoveModalVisible(false);
    setIsRemovingCartDeal(false);
  }, [cartDeal, dispatch]);

  if (!restaurant || !deal) {
    captureManualSentryException(
      new Error("restaurant or deal is undefined in CartDeal"),
    );
    return <div />;
  }

  return (
    <div
      className={classNames(styles.CartDeal, className)}
      data-testid={`cart-deal-${deal.id}`}
    >
      <Image src={deal.imageUrl} className={styles.dealImage} />
      <div className={styles.infoRows}>
        <div className={styles.dealInfoContainer}>
          <div className={styles.titleRow}>
            <h3 className={styles.titleText}>{deal.name}</h3>
            {deal.type === DEAL_TYPE.COMBO ? (
              <h3
                className={styles.titleText}
              >{`$${deal.dealValue.toFixed(2)}`}</h3>
            ) : (
              <div />
            )}
          </div>
          <CartDealCard cartDeal={cartDeal} deal={deal} />
        </div>
        <button
          data-testid="remove-button"
          className={styles.removeButton}
          onClick={() => setIsRemoveModalVisible(true)}
        >
          Remove
        </button>
      </div>
      <BasicModal
        testId="remove-cart-deal-modal"
        isModalVisible={isRemoveModalVisible}
        onClickOutside={() => setIsRemoveModalVisible(false)}
        onCancel={() => setIsRemoveModalVisible(false)}
        onConfirm={() => {
          handleRemoveCartDeal();
        }}
        title="Remove Deal"
        message="Are you sure you want to remove this deal from your cart?"
        isLoading={isRemovingCartDeal}
      />
    </div>
  );
};
