import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  logFooterGetDirectionsClicked,
  logFooterOrderOnlineClicked,
} from "src/common/analytics";
import { Button } from "src/components/Button/Button";
import styles from "src/components/Footer/NonOwnerManagedFooter/styles.module.scss";
import { Image } from "src/components/Image/Image";
import { RestaurantFragment } from "src/state/restaurant/types";
import { State } from "src/state/state";

export const NonOwnerManagedFooter = () => {
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );

  const cloverLink = useMemo(() => {
    const { menuSourceLink } = restaurant.menuSource;
    const merchantId = menuSourceLink.split("/")[6];

    return `https://www.clover.com/online-ordering/${merchantId}`;
  }, [restaurant.menuSource]);

  return (
    <div className={styles.NonOwnerManagedFooter}>
      <div className={styles.footerLeft}>
        <p className={styles.orderOnlineText}>Order Online</p>
        <div className={styles.onlineOrderingLinks}>
          <Image
            src={require("../../../assets/clover.webp")}
            className={styles.image}
            onClick={() => {
              logFooterOrderOnlineClicked("clover");
              window.open(cloverLink, "_blank");
            }}
          />
          {restaurant.socialMediaLinks.doordash && (
            <Image
              src={require("../../../assets/doordash.webp")}
              className={styles.image}
              onClick={() => {
                logFooterOrderOnlineClicked("doordash");
                window.open(
                  restaurant.socialMediaLinks.doordash as string,
                  "_blank",
                );
              }}
            />
          )}
          {restaurant.socialMediaLinks.ubereats && (
            <Image
              src={require("../../../assets/ubereats.webp")}
              className={styles.image}
              onClick={() => {
                logFooterOrderOnlineClicked("ubereats");
                window.open(
                  restaurant.socialMediaLinks.ubereats as string,
                  "_blank",
                );
              }}
            />
          )}
          {restaurant.socialMediaLinks.grubhub && (
            <Image
              src={require("../../../assets/grubhub.webp")}
              className={styles.image}
              onClick={() => {
                logFooterOrderOnlineClicked("grubhub");
                window.open(
                  restaurant.socialMediaLinks.grubhub as string,
                  "_blank",
                );
              }}
            />
          )}
          {restaurant.socialMediaLinks.postmates && (
            <Image
              src={require("../../../assets/postmates.webp")}
              className={styles.image}
              onClick={() => {
                logFooterOrderOnlineClicked("postmates");
                window.open(
                  restaurant.socialMediaLinks.postmates as string,
                  "_blank",
                );
              }}
            />
          )}
        </div>
      </div>
      <div className={styles.footerRight}>
        <Button
          className={styles.getDirectionsButton}
          onClick={() => {
            const { longitude, latitude } = restaurant.locations[0];

            const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
            window.open(googleMapsUrl, "_blank");
            logFooterGetDirectionsClicked();
          }}
        >
          <h3 className={styles.getDirectionsButtonText}>
            {"Get Directions →"}
          </h3>
        </Button>
      </div>
    </div>
  );
};
