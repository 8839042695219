import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "src/components/Header/OrderBanner/styles.module.scss";
import {
  getActiveOrdersForCustomerAction,
  getRecentlyCancelledOrderForCustomerAction,
  getRecentlyCompletedOrderForCustomerAction,
  getSpecificOrderAction,
} from "src/state/order/actions";
import { ORDER_STATUS, OrderFragment } from "src/state/order/types";
import { State } from "src/state/state";
import classNames from "classnames";
import { orderStatusText } from "src/common/constants";
import { getMenuPath, getReceiptPath } from "src/Router/routes";
import { WebsocketClient } from "src/api/websocket";
import { WEBSOCKET_URL } from "src/api/types";
import { CheckoutSessionState } from "src/state/checkoutSession/types";
import { Link } from "react-router-dom";
import { logViewReceiptClickedToAnalytics } from "src/common/analytics";
import { updateOrderBannerDisplayedAction } from "src/state/orderBanner/actions";
import { getItemFromLocalStorage } from "src/common/localStorage";

export const OrderBanner = () => {
  const dispatch = useDispatch();
  const websocket = new WebsocketClient();

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const customer = useSelector(
    (state: State) => state.customers.currentCustomer,
  );
  const checkoutSessionFromState = useSelector(
    (state: State) => state.checkoutSession,
  );

  const [order, setOrder] = useState<OrderFragment | undefined>(undefined);

  const checkoutSession = useMemo(() => {
    if (checkoutSessionFromState && checkoutSessionFromState.order) {
      return checkoutSessionFromState;
    }

    const itemFromLocalStorage = getItemFromLocalStorage("checkoutSession");

    if (itemFromLocalStorage) {
      return JSON.parse(itemFromLocalStorage) as CheckoutSessionState;
    }
  }, [checkoutSessionFromState]);

  const fetchAndHandleOrdersBanner = useCallback(async () => {
    if (restaurant && customer) {
      return await handleOrderBannerForLoggedInCustomer();
    }

    if (!customer) {
      return await handleOrderBannerForGuestCustomer();
    }
  }, [customer, checkoutSession, restaurant, dispatch]);

  const handleOrderBannerForLoggedInCustomer = useCallback(async () => {
    if (restaurant && customer) {
      const activeOrders = await getActiveOrdersForCustomerAction(
        customer.id,
        restaurant.id,
      )(dispatch);

      if (activeOrders.response.length > 0) {
        const activeOrdersArraySortedByTime = activeOrders.response.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        );

        setOrder(activeOrdersArraySortedByTime[0]);
        return activeOrdersArraySortedByTime[0];
      }

      const recentCancelledOrder =
        await getRecentlyCancelledOrderForCustomerAction(
          customer.id,
          restaurant.id,
        )(dispatch);

      if (recentCancelledOrder.response.order) {
        setOrder(recentCancelledOrder.response.order);
        return recentCancelledOrder.response.order;
      }

      const recentCompletedOrder =
        await getRecentlyCompletedOrderForCustomerAction(customer.id)(dispatch);

      if (recentCompletedOrder.response.order) {
        setOrder(recentCompletedOrder.response.order);
        return recentCompletedOrder.response.order;
      }
    }
  }, [customer, restaurant, dispatch]);

  const handleOrderBannerForGuestCustomer = useCallback(async () => {
    if (checkoutSession && checkoutSession.order && restaurant) {
      const order = await getSpecificOrderAction(
        restaurant.id,
        checkoutSession.order.id,
      )(dispatch);

      if (order.response.paymentIntentSucceeded || checkoutSession.completed) {
        setOrder(order.response);
        return order.response;
      }
    }
  }, [checkoutSession, restaurant, dispatch]);

  const setupOrderStatusWebsocket = useCallback(
    async (order: OrderFragment) => {
      if (order) {
        if (!websocket.isConnecting) {
          await websocket.initializeWebsocketConnection(
            WEBSOCKET_URL.ORDER_STATUS,
            order.id,
            async (orderId) => {
              if (restaurant) {
                const { response } = await getSpecificOrderAction(
                  restaurant.id,
                  orderId,
                )(dispatch);
                setOrder(response);
              }
            },
          );
        }
      }
    },
    [order, restaurant, dispatch],
  );

  const setupOrderBanner = useCallback(async () => {
    const order = await fetchAndHandleOrdersBanner();

    if (order) {
      await updateOrderBannerDisplayedAction(true)(dispatch);

      setupOrderStatusWebsocket(order);
    } else {
      await updateOrderBannerDisplayedAction(false)(dispatch);
    }
  }, [websocket, dispatch]);

  useEffect(() => {
    if (location.pathname === getMenuPath()) {
      setupOrderBanner();
    }
  }, [location.pathname]);

  if (!order) {
    return null;
  }

  return (
    <div className={styles.OrderBanner} data-testid="order-banner">
      <div className={styles.orderBannerInner}>
        <div>
          <h3
            className={styles.text}
            data-testid="order-number"
          >{`Order #${order.orderNumber}`}</h3>
          <Link
            data-testid="view-receipt"
            to={getReceiptPath(order.id)}
            onClick={() => {
              logViewReceiptClickedToAnalytics(customer?.id, order.id);
            }}
          >
            <h4 className={styles.viewReceipt}>View Receipt</h4>
          </Link>
        </div>
        <div
          data-testid="order-status"
          className={classNames(styles.statusContainer, {
            [styles.cancelledContainer]:
              order.status === ORDER_STATUS.CANCELLED,
          })}
        >
          <h3 className={styles.statusText}>
            {`• ${orderStatusText[order.status]}`}
          </h3>
        </div>
      </div>
    </div>
  );
};
