import classNames from "classnames";
import { useSelector } from "react-redux";
import { getItemPriceAfterDealApplied } from "src/common/price";
import { captureManualSentryException } from "src/common/sentry";
import styles from "src/pages/Deal/Pricing/styles.module.scss";
import { CartItemOptionsSelected } from "src/state/cart/types";
import { DEAL_TYPE, DealFragment } from "src/state/deal/types";
import { ItemFragment } from "src/state/item/types";
import { State } from "src/state/state";

interface ComponentProps {
  deal: DealFragment;
  itemsSelected: ItemFragment[];
  itemOptionsSelected: CartItemOptionsSelected[];
}

export const Pricing = ({
  deal,
  itemsSelected,
  itemOptionsSelected,
}: ComponentProps) => {
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const options = useSelector(
    (state: State) => restaurant && state.options[restaurant.id],
  );

  if (!options) {
    captureManualSentryException(
      new Error("options is not defined in Pricing"),
    );

    return <div />;
  }

  return (
    <div className={styles.Pricing} data-testid="pricing">
      {itemsSelected.map((itemSelected, index) => {
        const thisItemsSelectedOptions = itemOptionsSelected[index] || {};
        const discountedPrice = getItemPriceAfterDealApplied(
          deal,
          itemSelected,
          index,
        );

        return (
          <div key={index}>
            <div className={styles.row} key={index}>
              <h4
                className={styles.priceMainText}
                data-testid={`item-name-${itemSelected.id}`}
              >
                {itemSelected.name}
              </h4>
              {deal.type !== DEAL_TYPE.COMBO && (
                <div className={styles.strikeThroughContainer}>
                  {discountedPrice !== itemSelected.price && (
                    <h4
                      className={styles.priceMainText}
                      data-testid={`${itemSelected.id}-discounted-price`}
                    >{`$${discountedPrice.toFixed(2)}`}</h4>
                  )}
                  <h4
                    data-testid={`${itemSelected.id}-original-price`}
                    className={classNames(styles.priceMainText, {
                      [styles.priceStrikeThrough]:
                        discountedPrice !== itemSelected.price,
                    })}
                  >{`$${itemSelected.price.toFixed(2)}`}</h4>
                </div>
              )}
            </div>
            {Object.keys(thisItemsSelectedOptions).map((optionId, index) => {
              const option = options[optionId];
              const optionValues = thisItemsSelectedOptions[optionId];
              if (optionValues && optionValues.length > 0) {
                const optionValuesNames = optionValues
                  .map((optionValue) => optionValue.name)
                  .join(", ");
                const optionValuesTotalPrice = optionValues.reduce(
                  (totalPrice, optionValue) => totalPrice + optionValue.price,
                  0,
                );

                return (
                  <div
                    className={styles.row}
                    key={`${optionId}-${index}`}
                    data-testid={`option-values-for-${option.id}`}
                  >
                    <h4
                      className={styles.priceSubText}
                      data-testid={`option-values-name`}
                    >
                      {`${option.name}: ${optionValuesNames}`}
                    </h4>
                    <h4
                      data-testid={`option-values-total-price`}
                      className={styles.priceSubText}
                    >{`$${optionValuesTotalPrice.toFixed(2)}`}</h4>
                  </div>
                );
              }
            })}
          </div>
        );
      })}
    </div>
  );
};
