import classNames from "classnames";
import styles from "src/pages/CateringCart/CateringOrderDetails/styles.module.scss";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "src/state/state";
import { CateringOrderDetailsOptions } from "src/pages/CateringCart/CateringOrderDetails/OrderDetailsOptions/OrderDetailsOptions";
import { Button, TextArea } from "src/components";
import { useNavigate } from "react-router-dom";
import {
  getCartPath,
  getCateringCheckoutPath,
  getSignUpPath,
} from "src/Router/routes";
import ReactLoading from "react-loading";
import { createScheduledCateringCheckoutStateAction } from "src/state/scheduledCateringCheckoutSession/actions";
import {
  calculateSalesTaxRateByScheduledCateringOrder,
  roundToNearestCent,
} from "src/common/price";
import { captureManualSentryException } from "src/common/sentry";
import { logInvalidOrderIntentToAnalytics } from "src/common/analytics";
import { OrderDetailsCartItem } from "src/pages/CateringCart/CateringOrderDetails/OrderDetailsCartItem/OrderDetailsCartItem";
import { getAllItemsObjectFromState } from "src/state/item/utils";
import { TipAmountType } from "src/common/types/Order";
import { LocationFragment } from "src/common/types/Location";
import { useDesign } from "src/common/hooks";
import { TipPicker } from "src/pages/CateringCart/CateringOrderDetails/TipPicker/TipPicker";

interface OrderDetailsProps {
  className?: string;
}

export const CateringOrderDetails = ({ className }: OrderDetailsProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const design = useDesign();

  const customer = useSelector(
    (state: State) => state.customers.currentCustomer,
  );
  const cart = useSelector((state: State) => state.scheduledCateringCart);
  const items = useSelector((state: State) => state.items);
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const selectedLocation = useSelector(
    (state: State) => state.location.selectedLocation as LocationFragment,
  );

  const allItemsObject = useMemo(
    () => getAllItemsObjectFromState(items),
    [items],
  );

  const cartArray = useMemo(() => Object.values(cart), [cart]);

  const cartSubTotal = useMemo(
    () => cartArray.reduce((total, item) => total + item.totalPrice, 0),
    [cartArray],
  );

  const [eventTime, setEventTime] = useState<string | undefined>(undefined);
  const [eventLocation, setEventLocation] = useState<string | undefined>();
  const [receiptEmail, setReceiptEmail] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [additionalNotes, setAdditionalNotes] = useState<string>("");
  const [restaurantTipAmountType, setRestaurantTipAmountType] = useState(
    TipAmountType.FIFTEEN_PERCENT,
  );
  const [restaurantCustomTipAmountString, setRestaurantCustomTipAmountString] =
    useState("");
  const [restaurantTipError, setRestaurantTipError] = useState<
    string | undefined
  >();
  const [invalidOrderItemsError, setInvalidOrderItemsError] = useState<
    string | undefined
  >();
  const [
    restaurantNotAcceptingOrdersError,
    setRestaurantNotAcceptingOrdersError,
  ] = useState<string | undefined>();

  const isRestaurantTipValid = useCallback((): boolean => {
    const isCustom = restaurantTipAmountType === TipAmountType.CUSTOM;
    if (
      isCustom &&
      (isNaN(parseFloat(restaurantCustomTipAmountString)) ||
        parseFloat(restaurantCustomTipAmountString) < 0)
    ) {
      setRestaurantTipError("Please enter a valid tip amount.");
      return false;
    }
    return true;
  }, [restaurantCustomTipAmountString, restaurantTipAmountType]);

  const restaurantTipAmount = useMemo(() => {
    if (restaurant && !restaurant.restaurantSettings.isRestaurantTipsEnabled) {
      return 0;
    }
    if (restaurantTipAmountType === TipAmountType.CUSTOM) {
      if (restaurantCustomTipAmountString.trim() === "") {
        setRestaurantTipError(undefined);
        return 0;
      } else if (isRestaurantTipValid()) {
        setRestaurantTipError(undefined);
        return roundToNearestCent(parseFloat(restaurantCustomTipAmountString));
      }
      return 0;
    }
    return roundToNearestCent(cartSubTotal * restaurantTipAmountType);
  }, [
    restaurant,
    restaurantTipAmountType,
    cartSubTotal,
    restaurantCustomTipAmountString,
    isRestaurantTipValid,
  ]);

  const cartTotal = useMemo(() => {
    return cartSubTotal + restaurantTipAmount;
  }, [cartSubTotal, restaurantTipAmount]);

  const handleCheckout = useCallback(async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    if (
      restaurant &&
      selectedLocation &&
      eventTime &&
      eventLocation &&
      receiptEmail
    ) {
      const salesTax = await calculateSalesTaxRateByScheduledCateringOrder(
        eventLocation,
        cartSubTotal,
      );
      const orderIntentResponse =
        await createScheduledCateringCheckoutStateAction(
          customer?.id,
          restaurant.id,
          cartSubTotal,
          salesTax,
          cart,
          eventTime,
          eventLocation,
          additionalNotes,
          restaurantTipAmount,
          selectedLocation.id,
          receiptEmail,
        )(dispatch);
      if (orderIntentResponse === "SCHEDULED_CATERING_ORDER_ITEMS_NOT_VALID") {
        setInvalidOrderItemsError(
          "Some of the items in your cart are no longer available. Please refresh the site to see the updated menu.",
        );
        logInvalidOrderIntentToAnalytics(
          customer?.id,
          "SCHEDULED_CATERING_ORDER_ITEMS_NOT_VALID",
        );
        setIsLoading(false);
        return;
      }

      if (
        orderIntentResponse === "SCHEDULED_CATERING_ORDER_EVENT_TIME_TOO_EARLY"
      ) {
        setRestaurantNotAcceptingOrdersError(
          `We're sorry, your event date is too early, please select a future date. The minimum lead time is ${restaurant.restaurantSettings.cateringLeadDays} days.`,
        );
        logInvalidOrderIntentToAnalytics(
          customer?.id,
          "SCHEDULED_CATERING_ORDER_EVENT_TIME_TOO_EARLY",
        );
        setIsLoading(false);
        return;
      }
      if (
        orderIntentResponse === "SCHEDULED_CATERING_ORDER_MIN_AMOUNT_NOT_MET"
      ) {
        setRestaurantNotAcceptingOrdersError(
          `Please add more items to your cart. The minimum order amount is $${restaurant.restaurantSettings.minCateringAmount.toFixed(2)}.`,
        );
        logInvalidOrderIntentToAnalytics(
          customer?.id,
          "SCHEDULED_CATERING_ORDER_MIN_AMOUNT_NOT_MET",
        );
        setIsLoading(false);
        return;
      }
      if (orderIntentResponse === "SCHEDULED_CATERING_ORDER_NOT_ENABLED") {
        setRestaurantNotAcceptingOrdersError(
          "Scheduled catering orders are not enabled for this restaurant.",
        );
        logInvalidOrderIntentToAnalytics(
          customer?.id,
          "SCHEDULED_CATERING_ORDER_NOT_ENABLED",
        );
        setIsLoading(false);
        return;
      }

      navigate(getCateringCheckoutPath());
    }

    setIsLoading(false);
  }, [
    isLoading,
    restaurant,
    selectedLocation,
    eventTime,
    eventLocation,
    cartSubTotal,
    cartTotal,
    customer,
    cart,
    restaurantTipAmount,
    additionalNotes,
    dispatch,
    navigate,
    cartArray.length,
    receiptEmail,
  ]);

  if (!items || !restaurant) {
    captureManualSentryException(
      new Error("items or restaurant is undefined in OrderDetails"),
    );
    return <div />;
  }

  return (
    <div
      className={classNames(styles.OrderDetails, className)}
      data-testid="order-details"
    >
      <CateringOrderDetailsOptions
        eventTime={eventTime}
        setEventTime={setEventTime}
        eventLocation={eventLocation}
        setEventLocation={setEventLocation}
        receiptEmail={receiptEmail}
        setReceiptEmail={setReceiptEmail}
      />
      <div className={styles.cartItemsContainer}>
        {cartArray.map((cartItem) => {
          const item = allItemsObject[cartItem.itemId];
          return (
            <OrderDetailsCartItem
              key={cartItem.itemId}
              name={item.name}
              totalPrice={cartItem.totalPrice}
              testId={`cart-item-summary-${cartItem.itemId}`}
            />
          );
        })}
      </div>
      <div className={styles.additionalNotesContainer}>
        <TextArea
          testId="additional-notes-input"
          label="Additional Notes"
          placeholder="Enter any notes about your order here..."
          onChangeText={setAdditionalNotes}
          value={additionalNotes}
          maxLength={75}
        />
      </div>
      <div className={styles.priceContainer}>
        <div className={styles.priceRow}>
          <h4 className={styles.priceRowText}>Subtotal</h4>
          <h4 className={styles.priceRowText} data-testid="cart-subtotal">
            ${cartSubTotal.toFixed(2)}
          </h4>
        </div>
        {restaurant.restaurantSettings.isRestaurantTipsEnabled && (
          <div data-testid="restaurant-tip">
            <div className={styles.priceRow}>
              <h4 className={styles.tipRowText}>Tip</h4>
              <h4 className={styles.tipRowText} data-testid="tip-amount">
                ${restaurantTipAmount.toFixed(2)}
              </h4>
            </div>
            <TipPicker
              tipAmountType={restaurantTipAmountType}
              setTipAmountType={setRestaurantTipAmountType}
              customTipAmountString={restaurantCustomTipAmountString}
              setCustomTipAmountString={setRestaurantCustomTipAmountString}
              error={restaurantTipError}
            />
          </div>
        )}
        <div className={styles.priceRow}>
          <h4 className={styles.orderTotalText}>Order Total</h4>
          <h4 className={styles.orderTotalText} data-testid="cart-total">
            ${cartTotal.toFixed(2)}
          </h4>
        </div>
      </div>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <ReactLoading
            type="spin"
            color={design.buttonColor}
            height={40}
            width={40}
          />
        </div>
      ) : customer ? (
        <Button
          testId="checkout-button"
          className={styles.checkoutButton}
          disabled={
            cartTotal === 0 || isLoading || !eventTime || !eventLocation
          }
          onClick={handleCheckout}
        >
          <h3 className={styles.buttonText}>Checkout</h3>
        </Button>
      ) : (
        <>
          <Button
            testId="sign-up-to-earn-rewards-button"
            className={classNames(
              styles.checkoutButton,
              styles.loginToEarnRewardsButton,
            )}
            onClick={() => {
              navigate(getSignUpPath(getCartPath()));
            }}
          >
            <h3 className={styles.buttonText}>{"Sign Up & Checkout"}</h3>
          </Button>
          <Button
            testId="checkout-as-guest-button"
            className={styles.checkoutButton}
            secondary={true}
            disabled={
              cartTotal === 0 || isLoading || !eventTime || !eventLocation
            }
            onClick={handleCheckout}
          >
            <h3 className={styles.checkoutAsGuestText}>Checkout as Guest</h3>
          </Button>
        </>
      )}
      {invalidOrderItemsError && (
        <h4
          className={styles.invalidOrderItemsText}
          data-testid="invalid-order-items-error"
        >
          {invalidOrderItemsError}
        </h4>
      )}
      {restaurantNotAcceptingOrdersError && (
        <h4
          className={styles.invalidOrderItemsText}
          data-testid="restaurant-not-accepting-orders-error"
        >
          {restaurantNotAcceptingOrdersError}
        </h4>
      )}
    </div>
  );
};
