import React, { useEffect } from "react";
import { useDesign } from "src/common/hooks";

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const design = useDesign();

  useEffect(() => {
    const root = document.documentElement;
    Object.entries(design).forEach(([key, value]) => {
      root.style.setProperty(`--${key}`, value);
    });
  }, [design]);
  return <>{children}</>;
};
