// Actions associated with cart
import { Dispatch } from "redux";
import { roundToNearestCent } from "src/common/price";
import { ScheduledCateringCartItemFragment } from "src/state/scheduledCateringCart/types";
import { v4 as uuid } from "uuid";

export enum SCHEDULED_CATERING_CART_ACTION {
  ADD_ITEM_TO_CATERING_CART_ACTION = "ADD_ITEM_TO_CATERING_CART_ACTION",
  CLEAR_CATERING_CART_ACTION = "CLEAR_CATERING_CART_ACTION",
  REMOVE_ENTITY_FROM_CATERING_CART_ACTION = "REMOVE_ENTITY_FROM_CATERING_CART_ACTION",
  UPDATE_ENTITY_IN_CATERING_CART_ACTION = "UPDATE_ENTITY_IN_CATERING_CART_ACTION",
}

export interface AddItemToScheduledCateringCartAction {
  type: SCHEDULED_CATERING_CART_ACTION.ADD_ITEM_TO_CATERING_CART_ACTION;
  response: ScheduledCateringCartItemFragment;
}

export const addItemToScheduledCateringCartAction = (
  itemId: string,
  quantity: number,
  totalPrice: number,
) => {
  return (dispatch: Dispatch<AddItemToScheduledCateringCartAction>) => {
    const cartItemId = uuid();

    const cartItem: ScheduledCateringCartItemFragment = {
      id: cartItemId,
      itemId,
      quantity,
      totalPrice: roundToNearestCent(totalPrice),
    };

    const action = {
      type: SCHEDULED_CATERING_CART_ACTION.ADD_ITEM_TO_CATERING_CART_ACTION,
      response: cartItem,
    } as AddItemToScheduledCateringCartAction;

    dispatch(action);
    return action;
  };
};

export interface ClearScheduledCateringCartAction {
  type: SCHEDULED_CATERING_CART_ACTION.CLEAR_CATERING_CART_ACTION;
}

export const clearScheduledCateringCartAction = () => {
  return (dispatch: Dispatch<ClearScheduledCateringCartAction>) => {
    const action = {
      type: SCHEDULED_CATERING_CART_ACTION.CLEAR_CATERING_CART_ACTION,
    } as ClearScheduledCateringCartAction;

    dispatch(action);

    return action;
  };
};

export interface RemoveEntityFromScheduledCateringCartAction {
  type: SCHEDULED_CATERING_CART_ACTION.REMOVE_ENTITY_FROM_CATERING_CART_ACTION;
  response: string;
}

export const removeEntityFromScheduledCateringCartAction = (
  cartItemId: string,
) => {
  return (dispatch: Dispatch<RemoveEntityFromScheduledCateringCartAction>) => {
    const action = {
      type: SCHEDULED_CATERING_CART_ACTION.REMOVE_ENTITY_FROM_CATERING_CART_ACTION,
      response: cartItemId,
    } as RemoveEntityFromScheduledCateringCartAction;

    dispatch(action);

    return action;
  };
};

export interface UpdateEntityInScheduledCateringCartAction {
  type: SCHEDULED_CATERING_CART_ACTION.UPDATE_ENTITY_IN_CATERING_CART_ACTION;
  response: {
    cartEntityId: string;
    newCartEntity: ScheduledCateringCartItemFragment;
  };
}

export const updateEntityInScheduledCateringCartAction = (
  cartEntityId: string,
  newCartEntity: ScheduledCateringCartItemFragment,
) => {
  return (dispatch: Dispatch<UpdateEntityInScheduledCateringCartAction>) => {
    const action = {
      type: SCHEDULED_CATERING_CART_ACTION.UPDATE_ENTITY_IN_CATERING_CART_ACTION,
      response: {
        cartEntityId,
        newCartEntity,
      },
    } as UpdateEntityInScheduledCateringCartAction;

    dispatch(action);

    return action;
  };
};
