// Component for circular buttons that can be selected
import classNames from "classnames";
import styles from "src/components/SelectableCircle/styles.module.scss";

interface ComponentProps {
  className?: string;
  testID?: string;
  isSelected: boolean;
  onClick?: () => void;
}

export const SelectableCircle = ({
  className,
  testID,
  isSelected,
  onClick,
}: ComponentProps) => {
  return (
    <div
      onClick={onClick}
      className={classNames(styles.SelectableCircle, className)}
      data-testid={testID}
    >
      <div className={classNames(isSelected && styles.selected)} />
    </div>
  );
};
