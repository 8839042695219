import styles from "src/pages/Cart/CartItem/styles.module.scss";
import classNames from "classnames";
import { CartItemFragment } from "src/state/cart/types";
import { useDispatch, useSelector } from "react-redux";
import { State } from "src/state/state";
import { useCallback, useMemo, useState } from "react";
import { ItemFragment } from "src/state/item/types";
import { OptionFragment } from "src/state/option/types";
import { OptionValueFragment } from "src/common/types/OptionValue";
import { BasicModal, Image, QuantityPicker } from "src/components";
import { getOptionValuePrice, roundToNearestCent } from "src/common/price";
import {
  removeEntityFromCartAction,
  updateEntityInCartAction,
} from "src/state/cart/actions";
import { captureManualSentryException } from "src/common/sentry";
import { selectOptionsForItem } from "src/state/option/utils";

interface CartItemProps {
  cartItem: CartItemFragment;
  className?: string;
}

export const CartItem = ({ cartItem, className }: CartItemProps) => {
  const dispatch = useDispatch();

  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);
  const [isRemovingCartItem, setIsRemovingCartItem] = useState(false);

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const items = useSelector((state: State) => state.items);
  const optionsForThisItem = useSelector(
    (state: State) =>
      restaurant && selectOptionsForItem(state, cartItem.itemId),
  );

  const allItemsObject = useMemo(() => {
    let allItemsObject: { [itemId: string]: ItemFragment } = {};

    for (const categoryId in items) {
      const itemsInCategory = items[categoryId];
      allItemsObject = {
        ...allItemsObject,
        ...itemsInCategory,
      };
    }

    return allItemsObject;
  }, [items]);
  const item = useMemo(
    () => cartItem && allItemsObject[cartItem.itemId],
    [allItemsObject, cartItem],
  );

  const selectedOptionsArray = useMemo(() => {
    if (!optionsForThisItem) {
      return [];
    }

    const finalArray: {
      option: OptionFragment;
      optionValue: OptionValueFragment[];
    }[] = [];

    for (const optionId in cartItem.optionsValuesSelectedForEachOption) {
      const option = optionsForThisItem[optionId];
      const optionValue = cartItem.optionsValuesSelectedForEachOption[optionId];
      finalArray.push({
        option,
        optionValue,
      });
    }

    return finalArray;
  }, [optionsForThisItem, cartItem.optionsValuesSelectedForEachOption]);

  const handleUpdateCartItemQuantity = useCallback(
    async (newQuantity: number) => {
      const newItemPrice = item.price * newQuantity;
      const newOptionsPrice =
        getOptionValuePrice(cartItem.optionsValuesSelectedForEachOption) *
        newQuantity;
      const newTotalPrice = newItemPrice + newOptionsPrice;

      await updateEntityInCartAction(cartItem.id, {
        ...cartItem,
        quantity: newQuantity,
        priceBeforeOptions: roundToNearestCent(newItemPrice),
        totalPrice: roundToNearestCent(newTotalPrice),
      })(dispatch);
    },
    [cartItem, item, dispatch],
  );

  const handleRemoveCartItem = useCallback(async () => {
    setIsRemovingCartItem(true);
    await removeEntityFromCartAction(cartItem.id)(dispatch);
    setIsRemoveModalVisible(false);
    setIsRemovingCartItem(false);
  }, [cartItem, dispatch]);

  if (!item || !optionsForThisItem) {
    captureManualSentryException(
      new Error("item or optionsForThisItem is undefined in CartItem"),
    );
    return <div />;
  }

  return (
    <div
      className={classNames(styles.CartItem, className)}
      data-testid={`cart-item-${cartItem.itemId}`}
    >
      {item.hasImage && (
        <Image src={item.imageURL} className={styles.itemImage} />
      )}
      <div
        className={classNames(styles.infoRows, {
          [styles.infoRowsWithoutImage]: !item.hasImage,
        })}
      >
        <div className={styles.row}>
          <h3 className={styles.itemName}>{item.name}</h3>
          <h3 className={styles.itemPrice} data-testid="price-before-options">
            ${cartItem.priceBeforeOptions.toFixed(2)}
          </h3>
        </div>
        {selectedOptionsArray.map((selectedOption) => {
          const optionValuesNames = selectedOption.optionValue
            .map((optionValue) => optionValue.name)
            .join(", ");
          const optionValuesTotalPrice = selectedOption.optionValue.reduce(
            (totalPrice, optionValue) => totalPrice + optionValue.price,
            0,
          );

          return (
            <div
              className={styles.row}
              key={selectedOption.option.id}
              data-testid={`cart-item-option-${selectedOption.option.id}`}
            >
              <p
                className={styles.optionName}
                data-testid={"selection-name"}
              >{`${selectedOption.option.name}: ${optionValuesNames}`}</p>
              <p className={styles.optionPrice} data-testid={"selection-price"}>
                ${(optionValuesTotalPrice * cartItem.quantity).toFixed(2)}
              </p>
            </div>
          );
        })}
        <div className={styles.editRow}>
          <QuantityPicker
            withBorder={false}
            quantity={cartItem.quantity}
            onQuantityChange={(newQuantity) => {
              handleUpdateCartItemQuantity(newQuantity);
            }}
            className={styles.quantityPicker}
          />
          <button
            data-testid="remove-button"
            className={styles.removeButton}
            onClick={() => setIsRemoveModalVisible(true)}
          >
            Remove
          </button>
        </div>
      </div>
      <BasicModal
        testId="remove-cart-item-modal"
        isModalVisible={isRemoveModalVisible}
        onClickOutside={() => setIsRemoveModalVisible(false)}
        onCancel={() => setIsRemoveModalVisible(false)}
        onConfirm={() => {
          handleRemoveCartItem();
        }}
        title="Remove Item"
        message="Are you sure you want to remove this item from your cart?"
        isLoading={isRemovingCartItem}
      />
    </div>
  );
};
