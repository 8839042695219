export const BotUserAgents = [
  // Search Engine Bots
  "Googlebot",
  "Bingbot",
  "Slurp",
  "DuckDuckBot",
  "Baiduspider",
  "YandexBot",
  "Sogou",
  "Exabot",
  "facebot",
  "ia_archiver",

  // Common Web Scrapers and SEO Tools
  "AhrefsBot",
  "MJ12bot",
  "SEMrushBot",
  "DotBot",
  "BLEXBot",
  "spbot",
  "Surdotlybot",
  "LinkpadBot",
  "SerpstatBot",

  // Social Media Bots
  "Twitterbot",
  "Pinterestbot",
  "Slackbot",
  "WhatsApp",
  "TelegramBot",

  // Other Known Crawlers
  "Mediapartners-Google",
  "AdsBot-Google",
  "Google-Structured-Data-Testing-Tool",
  "Google Favicon",
  "CCBot",
  "Applebot",

  // Lesser-Known Bots
  "AspiegelBot",
  "YisouSpider",
  "Dataprovider",
  "SeekportBot",
  "WBSearchBot",
  "archive.org_bot",
  "Jetbot",

  // Some Generic Bot Patterns
  "bot",
  "crawler",
  "spider",
  "robot",
  "crawling",
];
