import { useState } from "react";

export const useMockPlacesService = () => {
  const mockPlaces = [
    "11811 NE 128th St, Kirkland, WA, USA",
    "933 W 3rd Ave, Spokane, WA, USA",
    "1234 1st Ave, Seattle, WA, USA",
    "Los Angeles, CA, USA",
    "7480 West Lake Sammamish Pkwy NE, Redmond, WA 98052",
  ];

  const [isPlacePredictionsLoading, setIsPlacePredictionsLoading] =
    useState<boolean>(false);
  const [placePredictions, setPlacePredictions] = useState<
    { description: string }[]
  >([]);

  interface PlacePredictionsParams {
    input: string;
    types?: string[];
    componentRestrictions?: {
      country?: string;
    };
  }

  const getPlacePredictions = async ({ input }: PlacePredictionsParams) => {
    setIsPlacePredictionsLoading(true);

    setTimeout(() => {
      const results = mockPlaces.filter((place) =>
        place.toLowerCase().includes(input.toLowerCase()),
      );
      setPlacePredictions(results.map((description) => ({ description })));
      setIsPlacePredictionsLoading(false);
    }, 1000);
  };

  return {
    getPlacePredictions,
    isPlacePredictionsLoading,
    placePredictions,
    placesService: {
      getDetails: (_, callback: (placeDetails) => void) => {
        const placesDetails = {
          address_components: [
            {
              types: ["postal_code"],
              long_name: "98033",
            },
          ],
        };

        callback(placesDetails);
        return placesDetails;
      },
    },
  };
};
