import styles from "src/components/QuantityPicker/styles.module.scss";
import classNames from "classnames";
import { useCallback } from "react";

interface QuantityPickerProps {
  className?: string;
  withBorder?: boolean;
  quantity: number;
  onQuantityChange: (quantity: number) => void;
}

export const QuantityPicker = ({
  className,
  withBorder = true,
  quantity,
  onQuantityChange,
}: QuantityPickerProps) => {
  const handleIncrease = useCallback(() => {
    onQuantityChange(quantity + 1);
  }, [quantity, onQuantityChange]);

  const handleDecrease = useCallback(() => {
    if (quantity > 1) {
      onQuantityChange(quantity - 1);
    }
  }, [quantity, onQuantityChange]);

  return (
    <div
      className={classNames(styles.QuantityPicker, className, {
        [styles.border]: withBorder,
      })}
      data-testid="quantity-picker"
    >
      <button
        data-testid="decrease"
        className={classNames(styles.quantityButton, styles.buttonLeft, {
          [styles.buttonLeftPadding]: withBorder,
        })}
        onClick={handleDecrease}
      >
        <h4 className={styles.text}>-</h4>
      </button>
      <h4 data-testid="number" className={styles.text}>
        {quantity}
      </h4>
      <button
        data-testid="increase"
        className={classNames(styles.quantityButton, styles.buttonRight, {
          [styles.buttonRightPadding]: withBorder,
        })}
        onClick={handleIncrease}
      >
        <h4 className={styles.text}>+</h4>
      </button>
    </div>
  );
};
