// Actions associated with category
import { Dispatch } from "redux";
import { getCategoryImageURLFromCategoryId } from "src/common/s3";
import { CategoryFragment } from "src/state/category/types";

export enum CATEGORY_ACTION {
  SET_ALL_CATEGORIES_FOR_RESTAURANT_ACTION = "SET_ALL_CATEGORIES_FOR_RESTAURANT_ACTION",
}

export interface SetAllCategoriesForRestaurantAction {
  type: CATEGORY_ACTION.SET_ALL_CATEGORIES_FOR_RESTAURANT_ACTION;
  restaurantId: string;
  response: CategoryFragment[];
}

export const setAllCategoriesForRestaurantAction = (
  restaurantId: string,
  categories: CategoryFragment[],
) => {
  return async (dispatch: Dispatch<SetAllCategoriesForRestaurantAction>) => {
    const action = {
      type: CATEGORY_ACTION.SET_ALL_CATEGORIES_FOR_RESTAURANT_ACTION,
      restaurantId,
      response: categories.map((eachCategory) => ({
        ...eachCategory,
        imageUrl: eachCategory.hasImage
          ? getCategoryImageURLFromCategoryId(eachCategory.id)
          : undefined,
      })),
    } as SetAllCategoriesForRestaurantAction;

    dispatch(action);

    return action;
  };
};
