// Actions associated with cart
import { Dispatch } from "redux";
import { roundToNearestCent } from "src/common/price";
import {
  CartItemFragment,
  CartDealFragment,
  CartItemOptionsSelected,
} from "src/state/cart/types";
import { v4 as uuid } from "uuid";

export enum CART_ACTION {
  ADD_ITEM_TO_CART_ACTION = "ADD_ITEM_TO_CART_ACTION",
  ADD_DEAL_TO_CART_ACTION = "ADD_DEAL_TO_CART_ACTION",
  CLEAR_CART_ACTION = "CLEAR_CART_ACTION",
  REMOVE_ENTITY_FROM_CART_ACTION = "REMOVE_ENTITY_FROM_CART_ACTION",
  UPDATE_ENTITY_IN_CART_ACTION = "UPDATE_ENTITY_IN_CART_ACTION",
}

export interface AddItemToCartAction {
  type: CART_ACTION.ADD_ITEM_TO_CART_ACTION;
  response: CartItemFragment;
}

export const addItemToCartAction = (
  itemId: string,
  quantity: number,
  options: CartItemOptionsSelected,
  priceBeforeOptions: number,
  totalPrice: number,
) => {
  return (dispatch: Dispatch<AddItemToCartAction>) => {
    const cartItemId = uuid();

    const cartItem: CartItemFragment = {
      id: cartItemId,
      itemId,
      quantity,
      optionsValuesSelectedForEachOption: options,
      priceBeforeOptions: roundToNearestCent(priceBeforeOptions),
      totalPrice: roundToNearestCent(totalPrice),
    };

    const action = {
      type: CART_ACTION.ADD_ITEM_TO_CART_ACTION,
      response: cartItem,
    } as AddItemToCartAction;

    dispatch(action);

    return action;
  };
};

export interface AddDealToCartAction {
  type: CART_ACTION.ADD_DEAL_TO_CART_ACTION;
  response: CartDealFragment;
}

export const addDealToCartAction = (
  dealId: string,
  optionValuesSelected: CartItemOptionsSelected[],
  itemIdsSelected: string[],
  priceBeforeDealBeforeOptions: number[],
  priceAfterDealBeforeOptions: number[],
  priceAfterDealAfterOptions: number[],
  totalPrice: number,
) => {
  return (dispatch: Dispatch<AddDealToCartAction>) => {
    const cartItemId = uuid();

    const cartDeal: CartDealFragment = {
      id: cartItemId,
      dealId,
      optionValuesSelected,
      itemIdsSelected,
      priceBeforeDealBeforeOptions,
      priceAfterDealBeforeOptions,
      priceAfterDealAfterOptions,
      totalPrice: roundToNearestCent(totalPrice),
    };

    const action = {
      type: CART_ACTION.ADD_DEAL_TO_CART_ACTION,
      response: cartDeal,
    } as AddDealToCartAction;

    dispatch(action);

    return action;
  };
};

export interface ClearCartAction {
  type: CART_ACTION.CLEAR_CART_ACTION;
}

export const clearCartAction = () => {
  return (dispatch: Dispatch<ClearCartAction>) => {
    const action = {
      type: CART_ACTION.CLEAR_CART_ACTION,
    } as ClearCartAction;

    dispatch(action);

    return action;
  };
};

export interface RemoveEntityFromCartAction {
  type: CART_ACTION.REMOVE_ENTITY_FROM_CART_ACTION;
  response: string;
}

export const removeEntityFromCartAction = (cartItemId: string) => {
  return (dispatch: Dispatch<RemoveEntityFromCartAction>) => {
    const action = {
      type: CART_ACTION.REMOVE_ENTITY_FROM_CART_ACTION,
      response: cartItemId,
    } as RemoveEntityFromCartAction;

    dispatch(action);

    return action;
  };
};

export interface UpdateEntityInCartAction {
  type: CART_ACTION.UPDATE_ENTITY_IN_CART_ACTION;
  response: {
    cartEntityId: string;
    newCartEntity: CartItemFragment | CartDealFragment;
  };
}

export const updateEntityInCartAction = (
  cartEntityId: string,
  newCartEntity: CartItemFragment | CartDealFragment,
) => {
  return (dispatch: Dispatch<UpdateEntityInCartAction>) => {
    const action = {
      type: CART_ACTION.UPDATE_ENTITY_IN_CART_ACTION,
      response: {
        cartEntityId,
        newCartEntity,
      },
    } as UpdateEntityInCartAction;

    dispatch(action);

    return action;
  };
};
