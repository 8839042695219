// Category reducer
import {
  CATEGORY_ACTION,
  SetAllCategoriesForRestaurantAction,
} from "src/state/category/actions";
import { CategoryState } from "src/state/category/types";

type CategoryAction = SetAllCategoriesForRestaurantAction;

export const categoryReducer = (
  state: CategoryState = {},
  action: CategoryAction,
) => {
  switch (action.type) {
    case CATEGORY_ACTION.SET_ALL_CATEGORIES_FOR_RESTAURANT_ACTION: {
      const { response: categories, restaurantId } = action;

      const next: CategoryState = {
        ...state,
        [restaurantId]: { ...(state[restaurantId] || {}) },
      };

      categories.forEach((category) => {
        next[restaurantId][category.id] = category;
      });

      return next;
    }

    default: {
      return state;
    }
  }
};
