import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift } from "@fortawesome/free-solid-svg-icons";
import { Checkbox } from "src/components";
import classNames from "classnames";
import styles from "src/pages/Cart/OrderDetails/PointsSelector/styles.module.scss";

interface PointsSelectorProps {
  className?: string;
  isSelected: boolean;
  points: number;
  pointsToDollars: number;
  disabled: boolean;
  onClick: () => void;
}

export const PointsSelector = ({
  className,
  points,
  isSelected,
  pointsToDollars,
  disabled,
  onClick,
}: PointsSelectorProps) => {
  return (
    <button
      className={classNames(styles.PointsSelector, className)}
      onClick={onClick}
      data-testid={"points-selector"}
      disabled={disabled}
    >
      <div className={styles.pointsContainerLeft}>
        <FontAwesomeIcon className={styles.icon} icon={faGift} />
        <div>
          <p
            data-testid="points-available"
            className={classNames(styles.pointsText, styles.pointsSpacer)}
          >{`${points} Available Points`}</p>
          <p
            data-testid="points-available-in-dollars"
            className={styles.pointsText}
          >{`$${pointsToDollars} Reward`}</p>
        </div>
      </div>
      <Checkbox checked={isSelected} onChange={onClick} disabled={disabled} />
    </button>
  );
};
