import {
  isNowWithinMenuHours,
  isNowWithinOpenHoursOfOperation,
} from "src/common/date";
import {
  ItemFragment,
  ItemState,
  UpsellItemsFragment,
} from "src/state/item/types";
import { State } from "src/state/state";
import { MenuFragment } from "src/state/menu/types";

export const getAllItemsObjectFromState = (
  itemsState: ItemState,
): {
  [itemId: string]: ItemFragment;
} => {
  const itemsObject = {};

  for (const categoryId in itemsState) {
    for (const itemId in itemsState[categoryId]) {
      const item = itemsState[categoryId][itemId];

      itemsObject[itemId] = item;
    }
  }

  return itemsObject;
};

export const selectDisplayedItemsFromState = (state: State): ItemState => {
  const restaurant = state.restaurants.currentRestaurant;
  const selectedLocation = state.location.selectedLocation;

  if (!restaurant || !selectedLocation) {
    return {};
  }

  const restaurantId = restaurant.id;
  const itemsObject = getAllItemsObjectFromState(state.items);
  const menus = state.menus[restaurantId];
  const menusArray = Object.values(menus);

  const itemsWithAssociatedMenuIds: {
    [itemId: string]: ItemFragment & { menuIds: string[] };
  } = {};
  for (const itemId in itemsObject) {
    const menusThisItemIsIn = menusArray.filter((menu: MenuFragment) => {
      return menu.itemIds.includes(itemId);
    });

    itemsWithAssociatedMenuIds[itemId] = {
      ...itemsObject[itemId],
      menuIds: menusThisItemIsIn.map((menu: MenuFragment) => menu.id),
    };
  }

  for (const itemId in itemsWithAssociatedMenuIds) {
    const item = itemsWithAssociatedMenuIds[itemId];

    if (item.isCateringItem) {
      delete itemsObject[itemId];
      continue;
    }

    if (item.menuIds.length === 0) {
      continue;
    }

    // Only enforce menu when restaurant is open
    if (!isNowWithinOpenHoursOfOperation(selectedLocation.hoursOfOperation)) {
      continue;
    }

    let areAnyMenusCurrentlyActive = false;
    for (const menuId of item.menuIds) {
      const menu = menus[menuId];

      if (isNowWithinMenuHours(menu)) {
        areAnyMenusCurrentlyActive = true;
        break;
      }
    }

    if (!areAnyMenusCurrentlyActive) {
      delete itemsObject[itemId];
    }
  }

  const displayedItems: ItemState = {};

  for (const itemId in itemsObject) {
    const item = itemsObject[itemId];

    if (!displayedItems[item.categoryId]) {
      displayedItems[item.categoryId] = {};
    }

    displayedItems[item.categoryId][itemId] = item;
  }

  return displayedItems;
};

export const selectUpsellItemsFromState = (
  state: State,
): UpsellItemsFragment => {
  const displayedItems = selectDisplayedItemsFromState(state);

  const upsellItems: UpsellItemsFragment = {};

  const restaurant = state.restaurants.currentRestaurant;
  if (!restaurant) {
    return {};
  }

  const restaurantId = restaurant.id;

  const itemIdsWithOptions = new Set<string>();

  for (const optionId in state.options[restaurantId]) {
    const option = state.options[restaurantId][optionId];

    option.itemIds.forEach((itemId: string) => {
      itemIdsWithOptions.add(itemId);
    });
  }

  for (const categoryId in displayedItems) {
    for (const itemId in displayedItems[categoryId]) {
      if (itemIdsWithOptions.has(itemId)) {
        continue;
      }

      const item = displayedItems[categoryId][itemId];

      if (item.price < 10 && item.price > 1) {
        upsellItems[itemId] = item;
      }
    }
  }

  return upsellItems;
};
