import { post } from "src/api/server";
import {
  CreateScheduledCateringOrderItemAPIParams,
  ScheduledCateringOrderFragment,
} from "src/state/scheduledCateringOrder/types";

export interface ScheduledCateringOrderIntentResponse {
  paymentIntentClientSecret: string;
  ephemeralKey: string;
  paymentIntentId: string;
  scheduledCateringOrder: ScheduledCateringOrderFragment;
  stripeCustomerId: string;
}

export interface ScheduledCateringOrderIntentErrorResponse {
  message:
    | "SCHEDULED_CATERING_ORDER_EVENT_TIME_TOO_EARLY"
    | "SCHEDULED_CATERING_ORDER_ITEMS_NOT_VALID"
    | "SCHEDULED_CATERING_ORDER_MIN_AMOUNT_NOT_MET"
    | "SCHEDULED_CATERING_ORDER_NOT_ENABLED";
}

export const createScheduledCateringOrderIntentInDatabase = async (
  customerId: string | undefined,
  restaurantId: string,
  subtotalInDollars: number,
  salesTaxInDollars: number,
  scheduledCateringOrderItems: CreateScheduledCateringOrderItemAPIParams[],
  eventTime: string,
  additionalNotes: string,
  tipAmountInDollars: number,
  locationId: string,
  eventLocation: string,
  receiptEmail: string,
): Promise<
  | ScheduledCateringOrderIntentResponse
  | ScheduledCateringOrderIntentErrorResponse
> => {
  const response = (await post({
    endpoint: "/scheduled_catering/create_order_intent",
    params: {
      customerId,
      restaurantId,
      subtotalInDollars,
      salesTaxInDollars,
      scheduledCateringOrderItems,
      eventTime,
      additionalNotes,
      tipAmountInDollars,
      locationId,
      eventLocation,
      receiptEmail,
    },
    service: "thanos",
  })) as
    | ScheduledCateringOrderIntentResponse
    | ScheduledCateringOrderIntentErrorResponse;

  return response;
};

export const updateGuestCustomerName = async (
  guestCustomerId: string,
  name: string,
  phone: string,
  email: string,
) => {
  await post({
    endpoint: "/guest_customer/update",
    params: {
      id: guestCustomerId,
      name,
      phone,
      email,
    },
    service: "thanos",
  });
};
