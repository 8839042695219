// File contains functions to interact with environment variables.
import { Environment, EnvVariable } from "src/config/types";

export const getCurrentEnvironment = (): Environment => {
  return atob(
    process.env[`REACT_APP_CURRENT_ENVIRONMENT`] as string,
  ) as Environment;
};

export const getEnvVariable = (variable: EnvVariable): string => {
  const envVariable = process.env[`REACT_APP_${variable}`];

  return atob(envVariable as string);
};
