import { useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import { getItemFromLocalStorage } from "src/common/localStorage";

export const useScrollToTop = () => {
  useEffect(() => {
    if (getItemFromLocalStorage("mockAutoscroll")) {
      return;
    }

    scroll.scrollToTop();
  }, []);
};
