export const testFirebaseServiceAccount = {
  firebaseServiceAccountProjectId: "test",
  firebaseServiceAccountPrivateKeyId: "test",
  firebaseServiceAccountPrivateKey: "test",
  firebaseServiceAccountClientEmail: "test",
  firebaseServiceAccountClientId: "test",
  firebaseServiceAccountAuthUri: "test",
  firebaseServiceAccountTokenUri: "test",
  firebaseServiceAccountAuthProviderX509CertUrl: "test",
  firebaseServiceAccountClientX509CertUrl: "test",
};

export const testImageUrl =
  "https://iamhomesteader.com/wp-content/uploads/2023/05/rodeo-burger-500x500.jpg";
