// Location reducer
import {
  LOCATION_ACTION,
  SelectLocationAction,
} from "src/state/location/actions";
import { LocationState } from "src/state/location/types";

type LocationAction = SelectLocationAction;

export const locationReducer = (
  state: LocationState = {},
  action: LocationAction,
) => {
  switch (action.type) {
    case LOCATION_ACTION.SELECT_LOCATION_ACTION: {
      const next: LocationState = { ...state };

      const { response: newLocation } = action;

      next.selectedLocation = newLocation;

      return next;
    }
    default: {
      return state;
    }
  }
};
