// Order Type reducer
import {
  ORDER_TYPE_ACTION,
  UpdateOrderTypeAction,
} from "src/state/orderType/actions";
import { OrderTypeState } from "src/state/orderType/types";
import { ORDER_TYPE } from "src/state/order/types";

type OrderTypeAction = UpdateOrderTypeAction;

export const orderTypeReducer = (
  state: OrderTypeState = {
    selectedOrderType: ORDER_TYPE.PICKUP,
  },
  action: OrderTypeAction,
) => {
  switch (action.type) {
    case ORDER_TYPE_ACTION.UPDATE_ORDER_TYPE_ACTION: {
      const next: OrderTypeState = { ...state };

      const { response: newOrderType } = action;

      next.selectedOrderType = newOrderType;

      return next;
    }
    default: {
      return state;
    }
  }
};
