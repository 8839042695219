import {
  ScheduledCateringCartState,
  ScheduledCateringCartItemFragment,
} from "src/state/scheduledCateringCart/types";
import { CreateScheduledCateringOrderItemAPIParams } from "src/state/scheduledCateringOrder/types";

const createOrderItemFromCartItem = (
  cartItem: ScheduledCateringCartItemFragment,
): CreateScheduledCateringOrderItemAPIParams => {
  return {
    itemId: cartItem.itemId,
    quantity: cartItem.quantity,
  };
};

export const createOrderItemsFromCart = (
  cart: ScheduledCateringCartState,
): CreateScheduledCateringOrderItemAPIParams[] => {
  const orderItems: CreateScheduledCateringOrderItemAPIParams[] = [];

  const cartAsArray = Object.values(cart);

  for (const eachCartItemOrDeal of cartAsArray) {
    const cartItem = eachCartItemOrDeal as ScheduledCateringCartItemFragment;
    const orderItem = createOrderItemFromCartItem(cartItem);

    orderItems.push(orderItem);
  }

  return orderItems;
};
