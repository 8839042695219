// Actions associated with order type
import { Dispatch } from "redux";
import { ORDER_TYPE } from "src/state/order/types";

export enum ORDER_TYPE_ACTION {
  UPDATE_ORDER_TYPE_ACTION = "UPDATE_ORDER_TYPE_ACTION",
}

export interface UpdateOrderTypeAction {
  type: ORDER_TYPE_ACTION.UPDATE_ORDER_TYPE_ACTION;
  response: ORDER_TYPE;
}

export const updateOrderTypeAction = (newOrderType: ORDER_TYPE) => {
  return async (dispatch: Dispatch<UpdateOrderTypeAction>) => {
    const action = {
      type: ORDER_TYPE_ACTION.UPDATE_ORDER_TYPE_ACTION,
      response: newOrderType,
    } as UpdateOrderTypeAction;

    dispatch(action);

    return action;
  };
};
