import { useMemo } from "react";
import { getCateringPath } from "src/Router/routes";
import { Button, PageTitle } from "src/components";
import styles from "src/pages/CateringCart/styles.module.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { State } from "src/state/state";
import { useScrollToTop } from "src/common/hooks";
import { CateringCartItem } from "src/pages/CateringCart/CateringCartItem/CartItem";
import { CateringOrderDetails } from "src/pages/CateringCart/CateringOrderDetails/OrderDetails";

export const CateringCart = () => {
  useScrollToTop();

  const cart = useSelector((state: State) => state.scheduledCateringCart);

  const cartArray = useMemo(() => {
    return Object.values(cart);
  }, [cart]);
  if (cartArray.length === 0) {
    return (
      <div className={styles.Cart} data-testid="cart-container">
        <PageTitle title="Catering Cart" className={styles.title} />
        <div
          className={styles.emptyTitleContainer}
          data-testid="empty-cart-container"
        >
          <h2 className={styles.emptyMessage}>Your Catering Cart is Empty</h2>
          <Link to={getCateringPath()} className={styles.orderNowLink}>
            <Button
              secondary={true}
              className={styles.orderNowButton}
              testId="empty-order-now-button"
            >
              <h2 className={styles.orderNowButtonText}>Order Now</h2>
            </Button>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.Cart} data-testid="cart-container">
      <div className={styles.cartContainer}>
        <div className={styles.cartItemsContainer}>
          <div className={styles.cartTitleRow}>
            <PageTitle title="Catering Cart" className={styles.title} />
            <Link
              className={styles.addMoreItemsButtonLink}
              to={getCateringPath()}
            >
              <Button
                className={styles.addMoreItemsButton}
                testId="add-more-items-button"
              >
                <h3 className={styles.addMoreItemsText}>Add More Items</h3>
              </Button>
            </Link>
          </div>
          {cartArray.map((cartItem) => {
            return <CateringCartItem key={cartItem.id} cartItem={cartItem} />;
          })}
        </div>
        <CateringOrderDetails className={styles.orderDetails} />
      </div>
    </div>
  );
};
