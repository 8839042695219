// Actions associated with client config
import { Dispatch } from "redux";
import { ClientConfigFragment } from "src/state/clientConfig/types";
import { isInDemoEnvironment, isSlugEnvironment } from "src/common/host";
import { post } from "src/api/server";
import { RestaurantFragment } from "src/state/restaurant/types";

export enum CLIENT_CONFIG_ACTION {
  SET_CLIENT_CONFIG_ACTION = "SET_CLIENT_CONFIG_ACTION",
}

export interface SetClientConfigAction {
  type: CLIENT_CONFIG_ACTION.SET_CLIENT_CONFIG_ACTION;
  response: ClientConfigFragment;
}

export const setClientConfigAction = () => {
  return async (dispatch: Dispatch<SetClientConfigAction>) => {
    let response: ClientConfigFragment;

    if (isInDemoEnvironment()) {
      const bundleId = new URLSearchParams(window.location.search).get(
        "bundleId",
      );
      response = (await post({
        service: "thanos",
        endpoint: "/customer_app_config/get_client_config_by_bundle_id",
        params: {
          bundleId,
        },
      })) as ClientConfigFragment;
    } else if (isSlugEnvironment()) {
      const pathname = window.location.pathname;
      const parts = pathname.split("/");
      const slug = parts[1];

      const restaurantBySlug = (await post({
        service: "thanos",
        endpoint: "/restaurant/get_by_slug",
        params: {
          slug,
        },
      })) as RestaurantFragment;

      response = {
        name: restaurantBySlug.restaurantName,
        restaurantId: restaurantBySlug.id,
      };
    } else {
      let rootDomain = window.location.hostname;
      if (rootDomain.startsWith("www.")) {
        rootDomain = rootDomain.substring(4);
      }

      response = (await post({
        service: "thanos",
        endpoint: "/customer_app_config/get_client_config_by_domain",
        params: {
          domain: rootDomain,
        },
      })) as ClientConfigFragment;
    }

    const action = {
      type: CLIENT_CONFIG_ACTION.SET_CLIENT_CONFIG_ACTION,
      response,
    } as SetClientConfigAction;

    dispatch(action);

    return action;
  };
};
