// Actions associated with options
import { Dispatch } from "redux";
import { OptionFragment } from "src/state/option/types";

export enum OPTION_ACTION {
  SET_OPTIONS_FOR_RESTAURANT_ACTION = "SET_OPTIONS_FOR_RESTAURANT_ACTION",
}

export interface SetOptionsForRestaurantAction {
  type: OPTION_ACTION.SET_OPTIONS_FOR_RESTAURANT_ACTION;
  restaurantId: string;
  options: OptionFragment[];
}

export const setOptionsForRestaurantAction = (
  restaurantId: string,
  options: OptionFragment[],
) => {
  return async (dispatch: Dispatch<SetOptionsForRestaurantAction>) => {
    const action = {
      type: OPTION_ACTION.SET_OPTIONS_FOR_RESTAURANT_ACTION,
      restaurantId,
      options,
    } as SetOptionsForRestaurantAction;

    dispatch(action);

    return action;
  };
};
