// Auth reducer
import {
  AUTH_ACTION,
  CreateNewUserInAWSActionResponse,
  LoginUserInAWSActionResponse,
  SetAuthStateActionResponse,
  SignOutUserActionResponse,
} from "src/state/auth/actions";
import { AuthState } from "src/state/auth/types";

type AuthActions =
  | CreateNewUserInAWSActionResponse
  | LoginUserInAWSActionResponse
  | SetAuthStateActionResponse
  | SignOutUserActionResponse;

export const authReducer = (state: AuthState = {}, action: AuthActions) => {
  switch (action.type) {
    case AUTH_ACTION.LOGIN_USER_IN_AWS_ACTION: {
      const next: AuthState = { ...state };

      const { userId, refreshToken } = action.response;

      next.currentUser = {
        userId,
        refreshToken,
      };

      return next;
    }
    case AUTH_ACTION.CREATE_NEW_USER_IN_AWS_ACTION: {
      const next: AuthState = { ...state };

      const { userId, refreshToken } = action.response;

      next.currentUser = {
        userId,
        refreshToken,
      };

      return next;
    }
    case AUTH_ACTION.SET_AUTH_STATE_ACTION: {
      const next: AuthState = { ...state };

      const { userId, refreshToken } = action.response;

      next.currentUser = {
        userId,
        refreshToken,
      };

      return next;
    }
    case AUTH_ACTION.SIGN_OUT_USER_ACTION: {
      const next: AuthState = { ...state };

      next.currentUser = undefined;

      return next;
    }
    default: {
      return state;
    }
  }
};
