// Actions associated with menus
import { Dispatch } from "redux";
import { MenuFragment } from "src/state/menu/types";

export enum MENU_ACTION {
  SET_MENUS_FOR_RESTAURANT_FROM_DATABASE_ACTION = "SET_MENUS_FOR_RESTAURANT_FROM_DATABASE_ACTION",
}

export interface SetMenusForRestaurantFromDatabaseAction {
  type: MENU_ACTION.SET_MENUS_FOR_RESTAURANT_FROM_DATABASE_ACTION;
  response: MenuFragment[];
  restaurantId: string;
}

export const setMenusForRestaurantFromDatabaseAction = (
  restaurantId: string,
  menus: MenuFragment[],
) => {
  return async (
    dispatch: Dispatch<SetMenusForRestaurantFromDatabaseAction>,
  ) => {
    const action = {
      type: MENU_ACTION.SET_MENUS_FOR_RESTAURANT_FROM_DATABASE_ACTION,
      response: menus,
      restaurantId,
    } as SetMenusForRestaurantFromDatabaseAction;

    dispatch(action);

    return action;
  };
};
