// Actions associated with a scheduled catering order checkout session
import { Dispatch } from "redux";
import { createScheduledCateringOrderIntentInDatabase } from "src/common/scheduledCateringOrder";
import { ScheduledCateringOrderFragment } from "src/state/scheduledCateringOrder/types";
import { ScheduledCateringCartState } from "src/state/scheduledCateringCart/types";
import { createOrderItemsFromCart } from "src/state/scheduledCateringOrder/utils";
import { ScheduledCateringCheckoutSessionState } from "src/state/scheduledCateringCheckoutSession/types";

export enum SCHEDULED_CATERING_CHECKOUT_SESSION {
  CREATE_SCHEDULED_CATERING_CHECKOUT_SESSION = "CREATE_SCHEDULED_CATERING_CHECKOUT_SESSION",
  COMPLETE_SCHEDULED_CATERING_CHECKOUT_SESSION_ACTION = "COMPLETE_SCHEDULED_CATERING_CHECKOUT_SESSION_ACTION",
}

export interface CreateScheduledCateringCheckoutSession {
  type: SCHEDULED_CATERING_CHECKOUT_SESSION.CREATE_SCHEDULED_CATERING_CHECKOUT_SESSION;
  response: ScheduledCateringCheckoutSessionState;
}

export const createScheduledCateringCheckoutStateAction = (
  customerId: string | undefined,
  restaurantId: string,
  cartTotal: number,
  salesTax: number,
  cart: ScheduledCateringCartState,
  eventTime: string,
  eventLocation: string,
  additionalNotes: string,
  tipAmount: number | undefined,
  locationId: string,
  receiptEmail: string,
) => {
  return async (dispatch: Dispatch<CreateScheduledCateringCheckoutSession>) => {
    let paymentIntentClientSecret: string;
    let order: ScheduledCateringOrderFragment;

    const orderIntentResponse =
      await createScheduledCateringOrderIntentInDatabase(
        customerId,
        restaurantId,
        cartTotal === 0 ? 1 : cartTotal,
        salesTax === 0 ? 0 : salesTax,
        createOrderItemsFromCart(cart),
        eventTime,
        additionalNotes,
        tipAmount ?? 0,
        locationId,
        eventLocation,
        receiptEmail,
      );

    if ("message" in orderIntentResponse) {
      return orderIntentResponse.message;
    } else {
      paymentIntentClientSecret = orderIntentResponse.paymentIntentClientSecret;
      order = orderIntentResponse.scheduledCateringOrder;
    }

    const checkoutSessionState: ScheduledCateringCheckoutSessionState = {
      paymentIntentClientSecret: paymentIntentClientSecret,
      order: order,
      completed: false,
    };

    const action = {
      type: SCHEDULED_CATERING_CHECKOUT_SESSION.CREATE_SCHEDULED_CATERING_CHECKOUT_SESSION,
      response: checkoutSessionState,
    } as CreateScheduledCateringCheckoutSession;

    dispatch(action);

    return action;
  };
};

export interface CompleteScheduledCateringCheckoutSessionAction {
  type: SCHEDULED_CATERING_CHECKOUT_SESSION.COMPLETE_SCHEDULED_CATERING_CHECKOUT_SESSION_ACTION;
}

export const completeScheduledCateringCheckoutSessionAction = () => {
  return async (
    dispatch: Dispatch<CompleteScheduledCateringCheckoutSessionAction>,
  ) => {
    const action = {
      type: SCHEDULED_CATERING_CHECKOUT_SESSION.COMPLETE_SCHEDULED_CATERING_CHECKOUT_SESSION_ACTION,
    } as CompleteScheduledCateringCheckoutSessionAction;

    dispatch(action);

    return action;
  };
};
