// Item reducer
import {
  ITEM_ACTION,
  SetAllItemsForRestaurantAction,
} from "src/state/item/actions";
import { ItemState, ItemFragment } from "src/state/item/types";

type ItemAction = SetAllItemsForRestaurantAction;

export const itemReducer = (state: ItemState = {}, action: ItemAction) => {
  switch (action.type) {
    case ITEM_ACTION.SET_ALL_ITEMS_FOR_RESTAURANT_ACTION: {
      const next: ItemState = { ...state };

      const { response: items } = action;

      const objectOfItems = items.reduce(
        (acc, item) => {
          acc[item.id] = item;

          return acc;
        },
        {} as { [key: string]: ItemFragment },
      );

      Object.keys(objectOfItems).forEach((itemId) => {
        const item = objectOfItems[itemId];

        const { categoryId } = item;

        next[categoryId] = {
          ...(next[categoryId] || {}),
          [itemId]: item,
        };
      });

      return next;
    }
    default: {
      return state;
    }
  }
};
