import styles from "src/pages/Account/AccountItem/styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import classNames from "classnames";

interface AccountItemProps {
  testId?: string;
  className?: string;
  title: string;
  subtitle: string;
  path?: string;
  onClick?: () => void;
}

export const AccountItem = ({
  testId,
  className,
  title,
  subtitle,
  path,
  onClick,
}: AccountItemProps) => {
  const component = (
    <>
      <div className={styles.left}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.subtitle}>{subtitle}</p>
      </div>
      <div className={styles.right}>
        <FontAwesomeIcon className={styles.angleRight} icon={faAngleRight} />
      </div>
    </>
  );

  const style = classNames(styles.AccountItem, className);

  if (path) {
    return (
      <Link to={path} className={style} data-testid={testId}>
        {component}
      </Link>
    );
  }

  if (onClick) {
    return (
      <button onClick={onClick} className={style} data-testid={testId}>
        {component}
      </button>
    );
  }

  return <div />;
};
