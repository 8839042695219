import { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { captureManualSentryException } from "src/common/sentry";
import styles from "src/pages/Home/styles.module.scss";
import { getAllItemsObjectFromState } from "src/state/item/utils";
import { State } from "src/state/state";
import { defaultCoverPhoto } from "src/common/constants";
import { getAddressStringFromLocationEntity } from "src/common/address";
import { Button } from "src/components";
import { getItemPath, getMenuPath } from "src/Router/routes";
import { useNavigate } from "react-router-dom";
import { useDesign } from "src/common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";
import { daysOfWeek, formatISOStringToHoursAndMinutes } from "src/common/date";
import { formatPhoneNumber } from "src/common/phone";
import { SocialIcon } from "react-social-icons";
import { motion } from "framer-motion";
import {
  logGetDirectionsClickedToAnalytics,
  logItemClickedFromHomeToAnalytics,
  logLocationClickedFromHomeToAnalytics,
  logOrderNowClickedFromHomeToAnalytics,
  logPhoneClickedFromHomeToAnalytics,
  logSocialMediaClickedFromHomeToAnalytics,
} from "src/common/analytics";
import classNames from "classnames";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import "src/pages/Home/map.css";

export const Home = () => {
  const placesRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const design = useDesign();

  const isDesktop = useMediaQuery({
    query: "(min-width: 875px)",
  });

  const isTablet = useMediaQuery({
    query: "(min-width: 525px)",
  });

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const customer = useSelector(
    (state: State) => state.customers.currentCustomer,
  );
  const selectedLocation = useSelector(
    (state: State) => state.location.selectedLocation,
  );
  const allItems = useSelector((state: State) =>
    getAllItemsObjectFromState(state.items),
  );

  const coverPhotoImageToDisplay = useMemo(() => {
    if (!restaurant || !allItems) {
      return "";
    }

    if (restaurant.isOwnerManaged) {
      return restaurant.coverPhotoUrl;
    }

    for (const item of Object.values(allItems)) {
      if (item.hasImage) {
        return item.imageURL;
      }
    }

    return defaultCoverPhoto;
  }, [restaurant, allItems, defaultCoverPhoto]);

  const aboutUsImage = useMemo(() => {
    if (!restaurant || !allItems) {
      return "";
    }

    for (const item of Object.values(allItems)) {
      if (item.hasImage) {
        return item.imageURL;
      }
    }

    return "";
  }, [restaurant, allItems, defaultCoverPhoto]);

  const mostPopularItems = useMemo(() => {
    const itemsArray = Object.values(allItems);

    let itemsAboveTwentyDollarsWithImages = itemsArray.filter(
      (item) => item.price > 7 && item.hasImage && !item.isCateringItem,
    );

    if (isTablet) {
      itemsAboveTwentyDollarsWithImages =
        itemsAboveTwentyDollarsWithImages.slice(0, 3);
    } else {
      itemsAboveTwentyDollarsWithImages =
        itemsAboveTwentyDollarsWithImages.slice(0, 4);
    }

    return itemsAboveTwentyDollarsWithImages;
  }, [allItems, isTablet]);

  const hoursOfOperationTextArray = useMemo(() => {
    if (!selectedLocation) {
      return [];
    }

    const arrayOfStrings: string[] = [];

    for (const day of daysOfWeek) {
      const key = day.toLowerCase();

      if (!selectedLocation.hoursOfOperation[key]) {
        arrayOfStrings.push(`Closed`);
        continue;
      }

      let stringToPush = "";
      for (const hours of selectedLocation.hoursOfOperation[key]) {
        const openTime = formatISOStringToHoursAndMinutes(hours.open);
        const closeTime = formatISOStringToHoursAndMinutes(hours.close);

        stringToPush += `${openTime} - ${closeTime}\n`;
      }

      arrayOfStrings.push(stringToPush);
    }

    return arrayOfStrings;
  }, [selectedLocation?.hoursOfOperation]);

  const backgroundColorToRgba = useMemo(() => {
    let hex = design.backgroundColor;

    if (hex.length === 4) {
      hex = `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
    }

    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, 0.65)`;
  }, [design.backgroundColor]);

  if (!restaurant) {
    captureManualSentryException(
      new Error("restaurant or categoriesArray or deals is undefined in Home"),
    );
    return <div />;
  }

  return (
    <div className={styles.Home} data-testid="home-container">
      <div ref={placesRef} style={{ display: "none" }} />
      <motion.div
        className={classNames(styles.coverPhotoContainer, {
          [styles.ownerManagedCoverPhotoMargin]: restaurant.isOwnerManaged,
        })}
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true, amount: 0.2 }}
      >
        <img src={coverPhotoImageToDisplay} className={styles.coverImage} />
        {selectedLocation && (
          <div
            className={styles.coverPhotoInfoContainer}
            style={{
              backgroundColor: backgroundColorToRgba,
            }}
          >
            <h4 className={styles.coverPhotoName}>
              {restaurant.restaurantName}
            </h4>
            <h3 className={styles.coverPhotoTagline}>
              {restaurant.restaurantSettings.tagLine}
            </h3>
            <h4 className={styles.coverPhotoAddress}>
              {getAddressStringFromLocationEntity(selectedLocation)}
            </h4>
            <Button
              className={styles.coverPhotoButton}
              onClick={() => {
                navigate(getMenuPath());
                logOrderNowClickedFromHomeToAnalytics(
                  customer?.id,
                  "HOME_COVER_PHOTO",
                );
              }}
            >
              <h3 className={styles.coverPhotoButtonText}>
                {restaurant.restaurantSettings.isOnlineOrderingEnabled
                  ? "Order Online →"
                  : "View Menu"}
              </h3>
            </Button>
          </div>
        )}
      </motion.div>
      {restaurant.isOwnerManaged && (
        <motion.div
          className={styles.aboutUsSection}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true, amount: 0.2 }}
        >
          <div
            className={classNames(styles.aboutUsInfoContainer, {
              [styles.fullWidthAboutUs]: aboutUsImage === "",
            })}
          >
            <h3 className={styles.aboutUsTitle}>
              {`About ${restaurant.restaurantName}`}
            </h3>
            <p className={styles.aboutUsText}>
              {restaurant.restaurantSettings.aboutUs}
            </p>
            <Button
              className={styles.aboutUsButton}
              onClick={() => {
                navigate(getMenuPath());
                logOrderNowClickedFromHomeToAnalytics(
                  customer?.id,
                  "HOME_ABOUT_US",
                );
              }}
            >
              <h3 className={styles.aboutUsButtonText}>
                {restaurant.restaurantSettings.isOnlineOrderingEnabled
                  ? "Order Online →"
                  : "View Menu"}
              </h3>
            </Button>
          </div>
          {aboutUsImage !== "" && (
            <img src={aboutUsImage} className={styles.aboutUsImage} />
          )}
        </motion.div>
      )}
      {((mostPopularItems.length === 3 && isTablet) ||
        (mostPopularItems.length === 4 && !isTablet)) && (
        <motion.div
          className={styles.popularFoodSection}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.25 }}
          viewport={{ once: true, amount: 0.2 }}
        >
          <h3 className={styles.popularFoodTitle}>
            {`Check Out the Most Popular Food`}
          </h3>
          <div className={styles.popularItemsContainer}>
            {mostPopularItems.map((item) => (
              <div
                key={item.id}
                className={styles.popularItemContainer}
                onClick={() => {
                  navigate(getItemPath(item.categoryId, item.id));
                  logItemClickedFromHomeToAnalytics(
                    customer?.id,
                    item.id,
                    item.name,
                  );
                }}
              >
                <img src={item.imageURL} className={styles.popularItemImage} />
                <p className={styles.popularItemName}>{item.name}</p>
                <p
                  className={styles.popularItemPrice}
                >{`$${item.price.toFixed(2)}`}</p>
              </div>
            ))}
          </div>
          <Button
            className={styles.popularItemsButton}
            onClick={() => {
              navigate(getMenuPath());
              logOrderNowClickedFromHomeToAnalytics(
                customer?.id,
                "HOME_POPULAR_ITEMS",
              );
            }}
          >
            <h3 className={styles.popularItemsButtonText}>
              {"View Full Menu →"}
            </h3>
          </Button>
        </motion.div>
      )}
      {restaurant.sampleReviews.length > 1 && (
        <motion.div
          className={styles.reviewsSection}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.25 }}
          viewport={{ once: true, amount: 0.2 }}
        >
          <h3 className={styles.reviewsTitle}>{`What Customers Are Saying`}</h3>
          <div className={styles.reviewsContainer}>
            {(isDesktop
              ? restaurant.sampleReviews
              : restaurant.sampleReviews.slice(0, 1)
            ).map((review, index) => (
              <div key={index} className={styles.reviewContainer}>
                <div className={styles.authorInfoRow}>
                  <img
                    src={review.authorPhotoUrl}
                    className={styles.reviewAuthorImage}
                    referrerPolicy="no-referrer"
                  />
                  <p className={styles.reviewAuthorName}>{review.authorName}</p>
                </div>
                <div className={styles.starsRow}>
                  {Array.from({ length: review.rating }).map((_, index) => (
                    <FontAwesomeIcon
                      key={index}
                      className={styles.star}
                      icon={faStar}
                    />
                  ))}
                </div>
                <p className={styles.reviewText}>{`"${review.text}"`}</p>
              </div>
            ))}
          </div>
          <Button
            className={styles.popularItemsButton}
            onClick={() => {
              navigate(getMenuPath());
              logOrderNowClickedFromHomeToAnalytics(
                customer?.id,
                "HOME_REVIEW_SECTION",
              );
            }}
          >
            <h3 className={styles.popularItemsButtonText}>
              {restaurant.restaurantSettings.isOnlineOrderingEnabled
                ? "Order Online →"
                : "View Menu"}
            </h3>
          </Button>
        </motion.div>
      )}
      <motion.div
        className={styles.getInTouchSection}
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.25 }}
        viewport={{ once: true, amount: 0.2 }}
      >
        <h3 className={styles.getInTouchTitle}>{`Get in Touch`}</h3>
        {selectedLocation && (
          <div className={styles.getInTouchContainer}>
            <div className={styles.mapContainer}>
              <MapContainer
                center={[selectedLocation.latitude, selectedLocation.longitude]}
                zoom={13}
                scrollWheelZoom={false}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                  position={[
                    selectedLocation.latitude,
                    selectedLocation.longitude,
                  ]}
                />
              </MapContainer>
              <Button
                className={styles.getDirectionsButton}
                onClick={() => {
                  const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${selectedLocation.latitude},${selectedLocation.longitude}`;
                  window.open(googleMapsUrl, "_blank");
                  logGetDirectionsClickedToAnalytics(customer?.id);
                }}
              >
                <h3 className={styles.getDirectionsButtonText}>
                  Get Directions
                </h3>
              </Button>
            </div>
            <div className={styles.getInTouchRestaurantInfo}>
              <div className={styles.getInTouchInformationRow}>
                <div className={styles.getInTouchLocations}>
                  <p className={styles.getInTouchSectionTitle}>Locations</p>
                  {restaurant.locations.map((location) => (
                    <div
                      key={location.id}
                      className={styles.getInTouchLocationContainer}
                    >
                      <p
                        className={styles.getInTouchLocationCity}
                      >{`${location.city}, ${location.state}`}</p>
                      <a
                        className={styles.link}
                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(getAddressStringFromLocationEntity(location))}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          logLocationClickedFromHomeToAnalytics(
                            customer?.id,
                            location.id,
                          );
                        }}
                      >
                        <p className={styles.getInTouchLocationStreet}>
                          {location.line1}
                        </p>
                        {location.line2.length > 0 && (
                          <p className={styles.getInTouchLocationStreet}>
                            {location.line2}
                          </p>
                        )}
                        <p className={styles.getInTouchLocationCityStateZip}>
                          {`${location.city}, ${location.state} ${location.country} ${location.postalCode}`}
                        </p>
                      </a>
                    </div>
                  ))}
                </div>
                <div className={styles.getInTouchHours}>
                  <p className={styles.getInTouchSectionTitle}>
                    Hours of Operation
                  </p>
                  {hoursOfOperationTextArray.map((hoursOfOperationText, i) => (
                    <div key={i} className={styles.getInTouchHoursRow}>
                      <p className={styles.getInTouchHoursDay}>
                        {`${daysOfWeek[i]}:`}
                      </p>
                      <p className={styles.getInTouchHoursText}>
                        {hoursOfOperationText}
                      </p>
                    </div>
                  ))}
                </div>
                <div className={styles.getInTouchContact}>
                  <p className={styles.getInTouchSectionTitle}>Contact</p>
                  <a
                    className={styles.link}
                    href={`tel://${restaurant.contactPhoneNumber}`}
                    onClick={() => {
                      logPhoneClickedFromHomeToAnalytics(customer?.id);
                    }}
                  >
                    {formatPhoneNumber(restaurant.contactPhoneNumber)}
                  </a>
                  <div className={styles.getInTouchSocialMedias}>
                    {restaurant.socialMediaLinks.facebook && (
                      <SocialIcon
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.socialMediaLink}
                        url={restaurant.socialMediaLinks.facebook}
                        onClick={() => {
                          logSocialMediaClickedFromHomeToAnalytics(
                            customer?.id,
                            "facebook",
                          );
                        }}
                      />
                    )}
                    {restaurant.socialMediaLinks.instagram && (
                      <SocialIcon
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.socialMediaLink}
                        url={restaurant.socialMediaLinks.instagram}
                        onClick={() => {
                          logSocialMediaClickedFromHomeToAnalytics(
                            customer?.id,
                            "instagram",
                          );
                        }}
                      />
                    )}
                    {restaurant.socialMediaLinks.yelp && (
                      <SocialIcon
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.socialMediaLink}
                        url={restaurant.socialMediaLinks.yelp}
                        onClick={() => {
                          logSocialMediaClickedFromHomeToAnalytics(
                            customer?.id,
                            "yelp",
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </motion.div>
    </div>
  );
};
