import styles from "src/pages/Deal/ItemPicker/styles.module.scss";
import classNames from "classnames";
import Modal from "react-awesome-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useMemo, useState } from "react";
import { Button } from "src/components";
import { State } from "src/state/state";
import { useSelector } from "react-redux";
import { captureManualSentryException } from "src/common/sentry";
import { ItemFragment } from "src/state/item/types";
import {
  getAllItemsObjectFromState,
  selectDisplayedItemsFromState,
} from "src/state/item/utils";
import { singular } from "pluralize";

interface ItemPickerProps {
  testId?: string;
  className?: string;
  disabled?: boolean;
  displayPrice?: boolean;
  categoryId: string;
  itemSelected: ItemFragment | undefined;
  onItemSelected: (item: ItemFragment | undefined) => void;
}

export const ItemPicker = ({
  testId,
  className,
  disabled,
  categoryId,
  itemSelected,
  onItemSelected,
  displayPrice = true,
}: ItemPickerProps) => {
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const category = useSelector(
    (state: State) =>
      restaurant &&
      state.categories[restaurant.id] &&
      state.categories[restaurant.id][categoryId],
  );
  const items = useSelector(selectDisplayedItemsFromState);

  const itemsArray = useMemo(
    () =>
      Object.values(getAllItemsObjectFromState(items)).filter(
        (item) => item.categoryId === categoryId,
      ),
    [items],
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalItemSelected, setModalItemSelected] = useState<
    ItemFragment | undefined
  >();

  if (!restaurant || !category || !items || !itemsArray) {
    captureManualSentryException(
      new Error("restaurant, category, or item is not defined in ItemPicker"),
    );
    return <div />;
  }

  return (
    <div
      className={classNames(styles.ItemPicker, className, {
        [styles.disabled]: disabled,
      })}
      data-testid={testId}
    >
      <div className={styles.itemNameReqContainer}>
        <h3
          data-testid={`category-title`}
          className={styles.categoryName}
        >{`Choose ${singular(category.name)}`}</h3>
        <div className={styles.requiredContainer}>
          <p className={styles.requiredText} data-testid={`required-text`}>
            Required
          </p>
        </div>
      </div>
      <button
        className={styles.categoryButton}
        disabled={disabled}
        onClick={() => setIsModalVisible(true)}
        data-testid={`item-button`}
      >
        <h3 className={styles.itemText} data-testid={`item-text`}>
          {itemSelected ? itemSelected.name : "No Selection"}
        </h3>
        <FontAwesomeIcon
          className={styles.angleRightIcon}
          icon={faAngleRight}
        />
      </button>
      <Modal
        visible={isModalVisible}
        width="400"
        height="500"
        effect="fadeInUp"
        onClickAway={() => {
          setModalItemSelected(itemSelected);
          setIsModalVisible(false);
        }}
      >
        <div data-testid={`item-picker-modal`}>
          <div className={styles.modalTitleContainer}>
            <h2>{category.name}</h2>
          </div>
          <div className={styles.itemsContainer}>
            {itemsArray.map((item) => (
              <button
                key={item.id}
                className={styles.itemButton}
                onClick={() => {
                  if (modalItemSelected?.id === item.id) {
                    setModalItemSelected(undefined);
                  } else {
                    setModalItemSelected(item);
                  }
                }}
                data-testid={`item-button-${item.id}`}
              >
                <div className={styles.itemLeft}>
                  <div className={styles.selectCircleOuter}>
                    {modalItemSelected?.id === item.id && (
                      <div
                        data-testid={`selected-circle`}
                        className={styles.selectCircleInner}
                      />
                    )}
                  </div>
                  <h3
                    data-testid={`item-title`}
                    className={styles.modalItemText}
                  >
                    {item.name}
                  </h3>
                </div>
                {displayPrice && (
                  <h4
                    data-testid={`item-price`}
                    className={styles.modalItemText}
                  >{`$${item.price.toFixed(2)}`}</h4>
                )}
              </button>
            ))}
          </div>
          <div className={styles.modalButtonsContainer}>
            <Button
              testId={`cancel-button`}
              className={styles.modalButton}
              secondary={true}
              onClick={() => {
                setModalItemSelected(itemSelected);
                setIsModalVisible(false);
              }}
            >
              <h4 className={styles.modalButtonTextCancel}>Cancel</h4>
            </Button>
            <Button
              testId={`save-button`}
              className={styles.modalButton}
              onClick={async () => {
                onItemSelected(modalItemSelected);
                setIsModalVisible(false);
              }}
            >
              <h4 className={styles.modalButtonTextSave}>Save</h4>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
