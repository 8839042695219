// Scheduled Catering Cart reducer
import {
  SCHEDULED_CATERING_CART_ACTION,
  AddItemToScheduledCateringCartAction,
  ClearScheduledCateringCartAction,
  RemoveEntityFromScheduledCateringCartAction,
  UpdateEntityInScheduledCateringCartAction,
} from "src/state/scheduledCateringCart/actions";
import { ScheduledCateringCartState } from "src/state/scheduledCateringCart/types";

type ScheduledCateringCartAction =
  | AddItemToScheduledCateringCartAction
  | ClearScheduledCateringCartAction
  | RemoveEntityFromScheduledCateringCartAction
  | UpdateEntityInScheduledCateringCartAction;

export const scheduledCateringCartReducer = (
  state: ScheduledCateringCartState = {},
  action: ScheduledCateringCartAction,
) => {
  switch (action.type) {
    case SCHEDULED_CATERING_CART_ACTION.ADD_ITEM_TO_CATERING_CART_ACTION: {
      const next: ScheduledCateringCartState = { ...state };

      const cartItem = action.response;

      next[cartItem.id] = cartItem;

      return next;
    }
    case SCHEDULED_CATERING_CART_ACTION.CLEAR_CATERING_CART_ACTION: {
      return {};
    }
    case SCHEDULED_CATERING_CART_ACTION.REMOVE_ENTITY_FROM_CATERING_CART_ACTION: {
      const next: ScheduledCateringCartState = { ...state };

      const entityId = action.response;

      delete next[entityId];

      return next;
    }
    case SCHEDULED_CATERING_CART_ACTION.UPDATE_ENTITY_IN_CATERING_CART_ACTION: {
      const next: ScheduledCateringCartState = { ...state };

      const { newCartEntity, cartEntityId } = action.response;

      next[cartEntityId] = newCartEntity;

      return next;
    }
    default: {
      return state;
    }
  }
};
