// The initializing actions dispatched to Redux
import { Dispatch } from "redux";
import { post } from "src/api/server";
import { RestaurantFragment } from "src/state/restaurant/types";
import { MenuFragment } from "src/state/menu/types";
import { setRestaurantFromDatabaseAction } from "src/state/restaurant/actions";
import { setMenusForRestaurantFromDatabaseAction } from "src/state/menu/actions";
import { CategoryFragment } from "src/state/category/types";
import { ItemFragment } from "src/state/item/types";
import { DealFragment } from "src/state/deal/types";
import { setAllCategoriesForRestaurantAction } from "src/state/category/actions";
import { setAllItemsForRestaurantAction } from "src/state/item/actions";
import { setDealsForRestaurantAction } from "src/state/deal/actions";
import { OptionFragment } from "src/state/option/types";
import { setOptionsForRestaurantAction } from "src/state/option/actions";
import { RestaurantWideDiscountFragment } from "src/state/restaurantWideDiscount/types";
import { setRestaurantWideDiscountForRestaurantAction } from "src/state/restaurantWideDiscount/actions";
import { setSessionIdAction } from "src/state/session/actions";
import { setUIVariants } from "src/state/globalUIVariant/actions";

export const initializeState = async (
  restaurantId: string,
  dispatch: Dispatch,
) => {
  const response = (await post({
    service: "thanos",
    endpoint: "/initialize/customer_website",
    params: {
      restaurantId,
    },
  })) as {
    restaurant: RestaurantFragment;
    menus: MenuFragment[];
    restaurantWideDiscount: RestaurantWideDiscountFragment;
    categories: CategoryFragment[];
    items: ItemFragment[];
    deals: DealFragment[];
    options: OptionFragment[];
  };

  await Promise.all([
    setRestaurantFromDatabaseAction(response.restaurant)(dispatch),
    setMenusForRestaurantFromDatabaseAction(
      restaurantId,
      response.menus,
    )(dispatch),
    setRestaurantWideDiscountForRestaurantAction(
      restaurantId,
      response.restaurantWideDiscount,
    )(dispatch),
    setAllCategoriesForRestaurantAction(
      restaurantId,
      response.categories,
    )(dispatch),
    setAllItemsForRestaurantAction(restaurantId, response.items)(dispatch),
    setDealsForRestaurantAction(restaurantId, response.deals)(dispatch),
    setOptionsForRestaurantAction(restaurantId, response.options)(dispatch),
    setSessionIdAction()(dispatch),
    setUIVariants()(dispatch),
  ]);

  return response;
};
