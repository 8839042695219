import { useSelector } from "react-redux";
import { captureManualSentryException } from "src/common/sentry";
import styles from "src/pages/Deal/DealPicker/styles.module.scss";
import {
  DEAL_ENTITY_TYPE,
  DEAL_TYPE,
  DealFragment,
} from "src/state/deal/types";
import { State } from "src/state/state";
import { ItemPicker } from "src/pages/Deal/ItemPicker/ItemPicker";
import { useCallback, useEffect, useState } from "react";
import { ItemFragment } from "src/state/item/types";
import { getAllItemsObjectFromState } from "src/state/item/utils";
import {
  getOptionValuePrice,
  getTotalPriceAfterDealAppliedWithoutOptions,
} from "src/common/price";
import { CartItemOptionsSelected } from "src/state/cart/types";
import { OptionPicker } from "src/components";

interface ComponentProps {
  deal: DealFragment;
  onItemsSelected: (item: (ItemFragment | undefined)[]) => void;
  onTotalPriceChange: (totalPrice: number) => void;
  onItemOptionsSelected: (optionsSelected: CartItemOptionsSelected[]) => void;
}

export const DealPicker = ({
  deal,
  onItemsSelected,
  onTotalPriceChange,
  onItemOptionsSelected,
}: ComponentProps) => {
  const [itemsSelected, setItemsSelected] = useState<
    (ItemFragment | undefined)[]
  >([]);
  const [optionsSelected, setOptionsSelected] = useState<
    CartItemOptionsSelected[]
  >([]);

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const items = useSelector(
    (state: State) => restaurant && getAllItemsObjectFromState(state.items),
  );
  const options = useSelector(
    (state: State) => restaurant && state.options[restaurant.id],
  );

  const initializeDealPicker = useCallback(async () => {
    if (items && options) {
      const itemsSelected: (ItemFragment | undefined)[] = [];
      const optionsSelected: CartItemOptionsSelected[] = [];

      for (let i = 0; i < deal.entityIds.length; i++) {
        const entityType = deal.entityTypes[i];

        if (entityType === DEAL_ENTITY_TYPE.CATEGORY) {
          itemsSelected.push(undefined);
          optionsSelected.push({});
        } else {
          itemsSelected.push(items[deal.entityIds[i]]);
          optionsSelected.push({});
        }
      }

      setItemsSelected(itemsSelected);
      setOptionsSelected(optionsSelected);
      onItemsSelected(itemsSelected);
      onItemOptionsSelected(optionsSelected);
    }
  }, [items, options]);

  const fetchOptionsForThisItem = async (itemId: string, index: number) => {
    if (!options) {
      return [];
    }

    const optionsForThisItem = Object.values(options).filter((option) =>
      option.itemIds.includes(itemId),
    );

    const newOptionsSelected = [...optionsSelected];
    newOptionsSelected[index] = {};

    setOptionsSelected(newOptionsSelected);
    onItemOptionsSelected(newOptionsSelected);

    return optionsForThisItem;
  };

  useEffect(() => {
    initializeDealPicker();
  }, []);

  useEffect(() => {
    onItemOptionsSelected(optionsSelected);
  }, [optionsSelected]);

  useEffect(() => {
    const definedItems = itemsSelected.filter((item) => item !== undefined);

    if (definedItems && definedItems.length === deal.entityIds.length) {
      let finalPrice = getTotalPriceAfterDealAppliedWithoutOptions(
        deal,
        definedItems as ItemFragment[],
      );

      for (const itemOptions of optionsSelected) {
        finalPrice += getOptionValuePrice(
          itemOptions as CartItemOptionsSelected,
        );
      }

      onTotalPriceChange(finalPrice);
    }
  }, [deal, itemsSelected, optionsSelected]);

  if (!restaurant || !items || !deal || !options) {
    captureManualSentryException(
      new Error(
        "restaurant, items, deal or options is not defined in DealPicker",
      ),
    );
    return <div />;
  }

  return (
    <div className={styles.DealPicker} data-testid={``}>
      {deal.entityTypes.map((entityType, index) => {
        const entityId = deal.entityIds[index];
        const itemSelected = itemsSelected[index];
        const itemOptions =
          itemSelected &&
          Object.values(options).filter((option) =>
            option.itemIds.includes(itemSelected.id),
          );

        return (
          <div key={index}>
            {deal.entityTypes[0] === DEAL_ENTITY_TYPE.ITEM &&
              deal.entityTypes.length === 1 && (
                <p
                  className={styles.itemDescription}
                  data-testid="item-description"
                >
                  {items[deal.entityIds[0]].description}
                </p>
              )}
            {entityType === DEAL_ENTITY_TYPE.ITEM && (
              <div className={styles.itemNameIncludedContainer}>
                <h3
                  data-testid={`category-title`}
                  className={styles.categoryName}
                >
                  {itemSelected?.name}
                </h3>
                <div className={styles.includedContainer}>
                  <p className={styles.includedText}>Included</p>
                </div>
              </div>
            )}
            {entityType === DEAL_ENTITY_TYPE.CATEGORY && (
              <ItemPicker
                testId={`item-picker-${deal.entityIds[index]}`}
                className={styles.itemPicker}
                categoryId={entityId}
                itemSelected={itemSelected}
                displayPrice={
                  deal.type !== DEAL_TYPE.COMBO &&
                  !(deal.type === DEAL_TYPE.BOGO && index === 1)
                }
                onItemSelected={async (item) => {
                  const newItemsSelected = [...itemsSelected];
                  const newOptionsSelected = [...optionsSelected];

                  if (!item) {
                    newItemsSelected[index] = undefined;
                    newOptionsSelected[index] = {};

                    setItemsSelected(newItemsSelected);
                    setOptionsSelected(newOptionsSelected);
                    onItemsSelected(newItemsSelected);

                    return;
                  }

                  newItemsSelected[index] = item;
                  newOptionsSelected[index] = {};

                  setItemsSelected(newItemsSelected);
                  setOptionsSelected(newOptionsSelected);
                  onItemsSelected(newItemsSelected);

                  await fetchOptionsForThisItem(item.id, index);
                }}
              />
            )}
            {itemSelected && itemOptions && itemOptions.length > 0 && (
              <div className={styles.optionsContainer}>
                {itemOptions.map((option) => (
                  <OptionPicker
                    className={styles.optionPicker}
                    option={option}
                    key={option.id}
                    onOptionValuesSelected={(optionValue) => {
                      setOptionsSelected((prevOptionsSelected) => {
                        const newItemOptionsSelected = {
                          ...prevOptionsSelected[index],
                        };

                        if (optionValue) {
                          newItemOptionsSelected[option.id] = optionValue;
                        } else {
                          delete newItemOptionsSelected[option.id];
                        }

                        const newOptionsSelected = [...prevOptionsSelected];
                        newOptionsSelected[index] = newItemOptionsSelected;

                        return newOptionsSelected;
                      });
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
