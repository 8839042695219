import styles from "src/components/TextArea/styles.module.scss";
import classNames from "classnames";

interface TextAreaProps
  extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, "onChange"> {
  className?: string;
  testId?: string;
  onChangeText?: (text: string) => void;
  label?: string;
  errorMessage?: string;
}

export const TextArea = (props: TextAreaProps) => {
  const { className, testId, onChangeText, label, errorMessage, ...rest } =
    props;

  return (
    <div
      className={classNames(styles.TextArea, className)}
      data-testid={testId}
    >
      <div className={styles.labelErrorRow}>
        {label && (
          <h3
            className={classNames(styles.label, {
              [styles.errorLabel]: errorMessage !== undefined,
            })}
            data-testid="label"
          >
            {label}
          </h3>
        )}
        {errorMessage && label && (
          <h3 className={styles.errorMessage}>&nbsp;-&nbsp;</h3>
        )}
        {errorMessage && (
          <h3 className={styles.errorMessage} data-testid="error-message">
            {errorMessage}
          </h3>
        )}
      </div>
      <textarea
        data-testid="input"
        className={classNames(styles.input, {
          [styles.errorInput]: errorMessage !== undefined,
        })}
        onChange={(e) => onChangeText && onChangeText(e.target.value)}
        {...rest}
      />
      {rest.maxLength && (
        <p className={styles.maxLengthText}>
          {rest.value?.toString().length || 0} / {rest.maxLength}
        </p>
      )}
    </div>
  );
};
