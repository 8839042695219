// Catering invoice reducer
import { CateringInvoiceState } from "src/state/cateringInvoice/types";
import {
  CATERING_INVOICE_ACTION,
  GetCateringInvoiceFromDatabaseAction,
} from "src/state/cateringInvoice/actions";

type CateringInvoiceAction = GetCateringInvoiceFromDatabaseAction;

export const cateringInvoiceReducer = (
  state: CateringInvoiceState = {},
  action: CateringInvoiceAction,
) => {
  switch (action.type) {
    case CATERING_INVOICE_ACTION.GET_CATERING_INVOICE_FROM_DATABASE_ACTION: {
      const { response: cateringInvoice } = action;

      const next: CateringInvoiceState = {
        ...state,
        [cateringInvoice.id]: cateringInvoice,
      };

      return next;
    }
    default: {
      return state;
    }
  }
};
