// Date utility functions

import {
  HoursOfOperationFragment,
  HoursOfOperationMode,
  HoursOfOperationFragmentWithMode,
} from "src/common/types/HoursOfOperation";
import { DayOfTheWeekHours } from "src/common/types/DayOfTheWeekHours";
import { MenuFragment } from "src/state/menu/types";
import { DealScheduleFragment } from "src/common/types/DealSchedule";

export const convertAMPMTimeToDateObject = (time: string): Date => {
  const [timeString, ampm] = time.split(" ");
  const [hours, minutes] = timeString.split(":");
  const date = new Date();

  date.setMinutes(parseInt(minutes));

  if (parseInt(hours) === 12 && ampm === "AM") {
    date.setHours(0);
    return date;
  }

  if (parseInt(hours) === 12 && ampm === "PM") {
    date.setHours(12);
    return date;
  }

  date.setHours(ampm === "AM" ? parseInt(hours) : parseInt(hours) + 12);

  return date;
};

export const formatISOStringToHoursAndMinutes = (ISOString: string) => {
  const date = new Date(ISOString);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const amPm = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12;
  const minutesString = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours12}:${minutesString} ${amPm}`;
};

export const formatISOStringToDate = (ISOString: string) => {
  const date = new Date(ISOString);
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();
  const year = date.getFullYear();

  let suffix = "th";

  if (day === 1 || day === 21 || day === 31) {
    suffix = "st";
  } else if (day === 2 || day === 22) {
    suffix = "nd";
  } else if (day === 3 || day === 23) {
    suffix = "rd";
  }

  return `${month} ${day}${suffix}, ${year}`;
};

export const isNowWithinOpenHoursOfOperation = (
  hoursOfOperation: HoursOfOperationFragmentWithMode,
): boolean => {
  const nowDate = new Date();
  const nowDayIndex = nowDate.getDay();

  const daysOfWeek = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ] as (keyof HoursOfOperationFragment)[];

  const nowDayString = daysOfWeek[nowDayIndex];

  if (
    hoursOfOperation[nowDayString] === null ||
    hoursOfOperation[nowDayString] === undefined
  ) {
    return false;
  }

  const thisDayHoursOfOperation = hoursOfOperation[
    nowDayString
  ] as DayOfTheWeekHours[];

  return isNowWithinGivenHours(thisDayHoursOfOperation, nowDate);
};

export const isRestaurantCurrentlyOpen = (
  hoursOfOperation: HoursOfOperationFragmentWithMode,
): boolean => {
  if (
    hoursOfOperation.modeSelected === HoursOfOperationMode.MANUALLY_CLOSED ||
    hoursOfOperation.modeSelected === HoursOfOperationMode.CLOSED_UNTIL_X
  ) {
    return false;
  }

  return isNowWithinOpenHoursOfOperation(hoursOfOperation);
};

export const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const getTenMinuteTimesBetweenDates = (
  startDateISOString: string,
  endDateISOString: string,
): string[] => {
  const startDate = new Date(startDateISOString);
  const endDate = new Date(endDateISOString);

  const result: string[] = [];

  if (startDate.getMinutes() % 10 !== 0) {
    startDate.setMinutes(
      startDate.getMinutes() + (10 - (startDate.getMinutes() % 10)),
    );
  }

  if (endDate.getMinutes() % 10 !== 0) {
    endDate.setMinutes(endDate.getMinutes() - (endDate.getMinutes() % 10));
  }
  startDate.setSeconds(0);
  endDate.setSeconds(0);
  startDate.setMilliseconds(0);
  endDate.setMilliseconds(0);

  const current = new Date(startDate);
  while (current <= endDate) {
    result.push(
      current.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
      }),
    );
    current.setTime(current.getTime() + 10 * 60 * 1000); // Add 10 minutes
  }

  return result;
};

export const isNowWithinMenuHours = (menu: MenuFragment): boolean => {
  const nowDate = new Date();
  const nowDayIndex = nowDate.getDay();

  const daysOfWeek: (keyof MenuFragment)[] = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const nowDayString = daysOfWeek[nowDayIndex];

  if (menu[nowDayString] === null || menu[nowDayString] === undefined) {
    return false;
  }

  const thisDayMenuSchedule = menu[nowDayString] as DayOfTheWeekHours[];

  return isNowWithinGivenHours(thisDayMenuSchedule, nowDate);
};

const isNowWithinGivenHours = (
  dayOfTheWeekHours: DayOfTheWeekHours[],
  nowDate: Date,
) => {
  const nowHour = nowDate.getHours();
  const nowMinute = nowDate.getMinutes();

  if (!dayOfTheWeekHours) {
    return false;
  } else {
    for (const eachActiveTime of dayOfTheWeekHours) {
      const open = new Date(eachActiveTime.open);
      const close = new Date(eachActiveTime.close);

      const openHour = open.getHours();
      const openMinute = open.getMinutes();

      const closeHour = close.getHours();
      const closeMinute = close.getMinutes();

      if (nowHour > openHour && nowHour < closeHour) {
        return true;
      }

      if (nowHour === openHour && nowHour === closeHour) {
        if (nowMinute >= openMinute && nowMinute < closeMinute) {
          return true;
        }
      }

      if (
        nowHour === openHour &&
        nowHour !== closeHour &&
        nowMinute >= openMinute
      ) {
        return true;
      }

      if (
        nowHour === closeHour &&
        nowHour !== openHour &&
        nowMinute < closeMinute
      ) {
        return true;
      }
    }
  }

  return false;
};

export const isNowWithinDealScheduleHours = (
  dealSchedule: DealScheduleFragment | undefined,
): boolean => {
  // If dealSchedule is null, consider it always within hours
  if (!dealSchedule) {
    return true;
  }

  const nowDate = new Date();
  const nowDayIndex = nowDate.getDay();

  const daysOfWeek: (keyof DealScheduleFragment)[] = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const nowDayString = daysOfWeek[nowDayIndex];

  if (
    dealSchedule[nowDayString] === null ||
    dealSchedule[nowDayString] === undefined
  ) {
    return false;
  }

  const thisDayDealSchedule = dealSchedule[nowDayString] as DayOfTheWeekHours[];

  return isNowWithinGivenHours(thisDayDealSchedule, nowDate);
};

export const formatISOStringToLongDateAndTime = (ISOString: string) => {
  const date = new Date(ISOString);
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const amPm = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12;
  const minutesString = minutes < 10 ? `0${minutes}` : minutes;

  let suffix = "th";

  if (day === 1 || day === 21 || day === 31) {
    suffix = "st";
  } else if (day === 2 || day === 22) {
    suffix = "nd";
  } else if (day === 3 || day === 23) {
    suffix = "rd";
  }

  return `${month} ${day}${suffix}, ${year} @ ${hours12}:${minutesString} ${amPm}`;
};
