// Actions associated with deal
import { Dispatch } from "redux";
import { DealFragment } from "src/state/deal/types";

export enum DEAL_ACTION {
  SET_DEALS_FOR_RESTAURANT_ACTION = "SET_DEALS_FOR_RESTAURANT_ACTION",
}

export interface SetDealsForRestaurantAction {
  type: DEAL_ACTION.SET_DEALS_FOR_RESTAURANT_ACTION;
  restaurantId: string;
  response: DealFragment[];
}

export const setDealsForRestaurantAction = (
  restaurantId: string,
  deals: DealFragment[],
) => {
  return async (dispatch: Dispatch<SetDealsForRestaurantAction>) => {
    const action = {
      type: DEAL_ACTION.SET_DEALS_FOR_RESTAURANT_ACTION,
      restaurantId,
      response: deals.map((deal) => ({
        ...deal,
        // Resets the image url to force a reload
        imageUrl: `${deal.imageUrl}?${Date.now()}`,
      })),
    } as SetDealsForRestaurantAction;

    dispatch(action);

    return action;
  };
};
