// Customer reducer
import {
  CUSTOMER_ACTION,
  CreateCustomerInDatabaseAction,
  DeleteCustomerFromDatabaseAction,
  GetCustomerFromDatabaseAction,
  SignOutCustomerAction,
  UpdateCustomerInDatabaseAction,
  UpdateCustomerPointsBasedOnOrderAction,
} from "src/state/customer/actions";
import { CustomerFragment, CustomerState } from "src/state/customer/types";

type CustomerAction =
  | CreateCustomerInDatabaseAction
  | GetCustomerFromDatabaseAction
  | UpdateCustomerPointsBasedOnOrderAction
  | UpdateCustomerInDatabaseAction
  | DeleteCustomerFromDatabaseAction
  | SignOutCustomerAction;

export const customerReducer = (
  state: CustomerState = {},
  action: CustomerAction,
) => {
  switch (action.type) {
    case CUSTOMER_ACTION.CREATE_CUSTOMER_IN_DATABASE_ACTION: {
      const next: CustomerState = { ...state };

      const { response: customer } = action;

      next.currentCustomer = customer;

      return next;
    }
    case CUSTOMER_ACTION.GET_CUSTOMER_FROM_DATABASE_ACTION: {
      const next: CustomerState = { ...state };

      const { response: customer } = action;

      next.currentCustomer = customer;

      return next;
    }
    case CUSTOMER_ACTION.UPDATE_CUSTOMER_POINTS_BASED_ON_ORDER_ACTION: {
      const next: CustomerState = { ...state };

      const { pointsToSubtract } = action;

      (next.currentCustomer as CustomerFragment).points -= pointsToSubtract;

      return next;
    }
    case CUSTOMER_ACTION.UPDATE_CUSTOMER_IN_DATABASE_ACTION: {
      const next: CustomerState = { ...state };

      const { response: customer } = action;

      next.currentCustomer = customer;

      return next;
    }
    case CUSTOMER_ACTION.DELETE_CUSTOMER_FROM_DATABASE_ACTION: {
      const next: CustomerState = { ...state };

      next.currentCustomer = undefined;

      return next;
    }
    case CUSTOMER_ACTION.SIGN_OUT_CUSTOMER_ACTION: {
      const next: CustomerState = { ...state };

      next.currentCustomer = undefined;

      return next;
    }
    default: {
      return state;
    }
  }
};
