import ReactLoading from "react-loading";
import classNames from "classnames";
import styles from "src/pages/Cart/OrderDetails/PromoCodeInput/styles.module.scss";
import { useCallback, useState } from "react";
import { post } from "src/api/server";
import { PROMO_CODE_VALUE_TYPE, PromoCode } from "src/common/types/PromoCode";
import { useDesign } from "src/common/hooks";
import { useSelector } from "react-redux";
import { State } from "src/state/state";
import { RestaurantFragment } from "src/state/restaurant/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faTag } from "@fortawesome/free-solid-svg-icons";
import {
  logPromoCodeAppliedToAnalytics,
  logPromoCodeFailedToAnalytics,
} from "src/common/analytics";
import { ORDER_TYPE } from "src/state/order/types";

interface PromoCodeInputProps {
  className?: string;
  cartSubtotal: number;
  promoCodeApplied: PromoCode | undefined;
  onPromoCodeApplied: (promoCode: PromoCode | undefined) => void;
}

export const PromoCodeInput = ({
  className,
  cartSubtotal,
  promoCodeApplied,
  onPromoCodeApplied,
}: PromoCodeInputProps) => {
  const design = useDesign();

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );
  const customer = useSelector(
    (state: State) => state.customers.currentCustomer,
  );
  const orderType = useSelector(
    (state: State) => state.orderType.selectedOrderType,
  );

  const [error, setError] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getPromoCode = useCallback(async () => {
    if (promoCode.trim() === "" || isLoading) {
      return;
    }

    setIsLoading(true);

    const potentialPromoCode = (await post({
      service: "thanos",
      endpoint: "/promo_code/get_by_code",
      params: {
        restaurantId: restaurant.id,
        code: promoCode,
      },
    })) as PromoCode | { message: string };

    if ("message" in potentialPromoCode) {
      setError("Invalid promo code");
      logPromoCodeFailedToAnalytics(customer?.id, promoCode, "INVALID_CODE");
      setPromoCode("");
      setIsLoading(false);
      return;
    } else {
      if (potentialPromoCode.minSize > cartSubtotal) {
        setError(
          `Cart subtotal must be at least $${potentialPromoCode.minSize.toFixed(2)} for this promo code`,
        );
        setIsLoading(false);
        logPromoCodeFailedToAnalytics(
          customer?.id,
          potentialPromoCode.code,
          "CART_TOO_SMALL",
        );
        return;
      }

      if (
        potentialPromoCode.isPlatterSponsored &&
        orderType === ORDER_TYPE.DELIVERY
      ) {
        setError("This promo code can only be applied to pickup orders");
        setIsLoading(false);
        logPromoCodeFailedToAnalytics(
          customer?.id,
          potentialPromoCode.code,
          "DELIVERY_NOT_ALLOWED",
        );
        return;
      }

      setIsLoading(false);
      onPromoCodeApplied(potentialPromoCode);
      logPromoCodeAppliedToAnalytics(
        customer?.id,
        potentialPromoCode.id,
        potentialPromoCode.code,
      );
      setPromoCode("");
    }
  }, [restaurant, promoCode, isLoading, cartSubtotal, customer, orderType]);

  return (
    <div className={classNames(styles.PromoCodeInput, className)}>
      <p className={styles.promoCodeLabel}>Promo Code</p>
      {promoCodeApplied ? (
        <div
          className={styles.promoCodeApplied}
          data-testid="promo-code-applied-container"
        >
          <div className={styles.promoCodeInfo}>
            <FontAwesomeIcon className={styles.promoIcon} icon={faTag} />
            <p className={styles.promoAppliedText}>
              {promoCodeApplied.valueType === PROMO_CODE_VALUE_TYPE.PERCENT
                ? `${promoCodeApplied.value}% OFF`
                : `$${promoCodeApplied.value} OFF`}
            </p>
          </div>
          <FontAwesomeIcon
            className={styles.unapplyPromoButton}
            icon={faClose}
            onClick={() => onPromoCodeApplied(undefined)}
          />
        </div>
      ) : (
        <>
          <div className={styles.inputContainer}>
            <input
              data-testid="promo-code-input"
              placeholder="Enter promo code..."
              className={styles.input}
              value={promoCode}
              onChange={(e) => {
                setPromoCode(e.target.value);
                setError("");
              }}
            />
            {isLoading ? (
              <ReactLoading
                color={design.buttonColor}
                type="spin"
                height={17}
                width={17}
              />
            ) : (
              <p
                className={classNames(styles.applyButton, {
                  [styles.disabledApply]: promoCode.trim() === "",
                })}
                onClick={getPromoCode}
                data-testid="apply-promo-code-button"
              >
                Apply
              </p>
            )}
          </div>
          {error && (
            <p className={styles.error} data-testid="promo-code-error-text">
              {error}
            </p>
          )}
        </>
      )}
    </div>
  );
};
