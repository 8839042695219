// Session reducer
import { SESSION_ACTION, SetSessionAction } from "src/state/session/actions";
import { SessionState } from "src/state/session/types";

type SessionAction = SetSessionAction;

export const sessionReducer = (
  state: SessionState = {},
  action: SessionAction,
) => {
  switch (action.type) {
    case SESSION_ACTION.SET_SESSION_ID: {
      const next: SessionState = { ...state };

      const { sessionId } = action;

      next.sessionId = sessionId;

      return next;
    }
    default: {
      return state;
    }
  }
};
