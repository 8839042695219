import styles from "src/components/PageTitle/styles.module.scss";
import classNames from "classnames";

interface PageTitleProps {
  testId?: string;
  className?: string;
  title: string;
}

export const PageTitle = ({ className, title, testId }: PageTitleProps) => {
  return (
    <h1
      className={classNames(styles.PageTitle, className)}
      data-testid={testId}
    >
      {title}
    </h1>
  );
};
