import styles from "src/components/SelectLocationModal/styles.module.scss";
import Modal from "react-awesome-modal";
import { SelectableButton } from "src/components";
import { useDispatch, useSelector } from "react-redux";
import { State } from "src/state/state";
import { captureManualSentryException } from "src/common/sentry";
import { selectLocationAction } from "src/state/location/actions";
import { getAddressStringFromLocationEntity } from "src/common/address";
import { logSelectLocationToAnalytics } from "src/common/analytics";

interface SelectLocationModalProps {
  testId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const SelectLocationModal = ({
  testId,
  isOpen,
  onClose,
}: SelectLocationModalProps) => {
  const dispatch = useDispatch();

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const selectedLocation = useSelector(
    (state: State) => state.location.selectedLocation,
  );
  const customer = useSelector(
    (state: State) => state.customers.currentCustomer,
  );

  if (!restaurant) {
    captureManualSentryException(
      new Error("restaurant is undefined in SelectLocationModal"),
    );

    return <div />;
  }

  return (
    <Modal visible={isOpen} width="400" height="300" effect="fadeInUp">
      <div className={styles.modal} data-testid={testId}>
        <h3 className={styles.title}>Choose Location</h3>
        {restaurant.locations.map((location) => (
          <SelectableButton
            key={location.id}
            testId={`location-${location.id}`}
            label={getAddressStringFromLocationEntity(location)}
            isSelected={selectedLocation?.id === location.id}
            onClick={() => {
              selectLocationAction(location)(dispatch);
              onClose();
              logSelectLocationToAnalytics(customer?.id, location.id);
            }}
            className={styles.selectableButton}
          />
        ))}
      </div>
    </Modal>
  );
};
