import { TipAmountType } from "src/common/types/Order";
import { Button, TextInput } from "src/components";
import styles from "src/pages/Cart/OrderDetails/TipPicker/styles.module.scss";
import classNames from "classnames";

interface TipPickerProps {
  setTipAmountType: (tipAmountType: TipAmountType) => void;
  tipAmountType: TipAmountType;
  customTipAmountString: string;
  setCustomTipAmountString: (customTipAmountString: string) => void;
  error: string | undefined;
}

export const TipPicker = ({
  setTipAmountType,
  tipAmountType,
  customTipAmountString,
  setCustomTipAmountString,
  error,
}: TipPickerProps) => {
  return (
    <>
      <div className={styles.tipButtonContainer}>
        <Button
          testId="tip-button-10"
          className={classNames(styles.tipButton, {
            [styles.selected]: tipAmountType === TipAmountType.TEN_PERCENT,
          })}
          onClick={() => {
            setTipAmountType(TipAmountType.TEN_PERCENT);
          }}
        >
          10%
        </Button>
        <Button
          testId="tip-button-15"
          className={classNames(styles.tipButton, {
            [styles.selected]: tipAmountType === TipAmountType.FIFTEEN_PERCENT,
          })}
          onClick={() => {
            setTipAmountType(TipAmountType.FIFTEEN_PERCENT);
          }}
        >
          15%
        </Button>
        <Button
          testId="tip-button-20"
          className={classNames(styles.tipButton, {
            [styles.selected]: tipAmountType === TipAmountType.TWENTY_PERCENT,
          })}
          onClick={() => {
            setTipAmountType(TipAmountType.TWENTY_PERCENT);
          }}
        >
          20%
        </Button>
        <Button
          testId="tip-button-custom"
          className={classNames(styles.tipButton, {
            [styles.selected]: tipAmountType === TipAmountType.CUSTOM,
          })}
          onClick={() => {
            setTipAmountType(TipAmountType.CUSTOM);
          }}
        >
          Custom
        </Button>
      </div>
      {tipAmountType === TipAmountType.CUSTOM && (
        <div className={styles.customTipInputContainer}>
          <h4 className={styles.customTipLabel}>
            Enter the amount you'd like to tip
          </h4>
          <TextInput
            className={styles.customTipInput}
            testId="tip-custom-input"
            value={`$${customTipAmountString}`}
            onChangeText={(newText) => {
              setCustomTipAmountString(newText.substring(1));
            }}
            placeholder="0.00"
          />
          {error && (
            <h4
              className={styles.errorMessage}
              data-testid="custom-tip-error-text"
            >
              {error}
            </h4>
          )}
        </div>
      )}
    </>
  );
};
