// Actions associated with selecting a location
import { Dispatch } from "redux";
import { LocationFragment } from "src/common/types/Location";

export enum LOCATION_ACTION {
  SELECT_LOCATION_ACTION = "SELECT_LOCATION_ACTION",
}

export interface SelectLocationAction {
  type: LOCATION_ACTION.SELECT_LOCATION_ACTION;
  response: LocationFragment;
}

export const selectLocationAction = (location: LocationFragment) => {
  return async (dispatch: Dispatch<SelectLocationAction>) => {
    const action = {
      type: LOCATION_ACTION.SELECT_LOCATION_ACTION,
      response: location,
    } as SelectLocationAction;

    dispatch(action);
  };
};
