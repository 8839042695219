import styles from "src/components/OptionPicker/styles.module.scss";
import { OptionFragment } from "src/state/option/types";
import classNames from "classnames";
import { useEffect, useState, useMemo } from "react";
import { Checkbox } from "src/components";
import { OptionValueFragment } from "src/common/types/OptionValue";

interface OptionPickerProps {
  className?: string;
  isEditable?: boolean;
  option: OptionFragment;
  onOptionValuesSelected: (optionValues: OptionValueFragment[]) => void;
}

export const OptionPicker = ({
  className,
  isEditable = true,
  option,
  onOptionValuesSelected,
}: OptionPickerProps) => {
  const [selectedValues, setSelectedValues] = useState<OptionValueFragment[]>(
    [],
  );

  const handleOptionChange = (optionValue: OptionValueFragment) => {
    let newSelectedValues = [...selectedValues];
    const valueIndex = newSelectedValues.findIndex(
      (value) => value.id === optionValue.id,
    );

    if (option.maxSelection === 1) {
      newSelectedValues = valueIndex > -1 ? [] : [optionValue];
    } else {
      if (valueIndex > -1) {
        newSelectedValues.splice(valueIndex, 1);
      } else {
        if (newSelectedValues.length < option.maxSelection) {
          newSelectedValues.push(optionValue);
        }
      }
    }

    setSelectedValues(newSelectedValues);
    onOptionValuesSelected(newSelectedValues);
  };

  useEffect(() => {
    for (const optionValue of option.optionValues) {
      if (optionValue.isDefault) {
        handleOptionChange(optionValue);
      }
    }
  }, []);

  const textToRenderForMinMax = useMemo(() => {
    const isInfiniteSelectionsAllowed =
      option.maxSelection > option.optionValues.length;

    if (option.minSelection === 0 && isInfiniteSelectionsAllowed) {
      return "";
    }

    if (option.minSelection === 0 && !isInfiniteSelectionsAllowed) {
      return `Select up to ${option.maxSelection} option${
        option.maxSelection > 1 ? "s." : "."
      }`;
    }

    if (option.minSelection === option.maxSelection) {
      return `Select ${option.minSelection} option${
        option.minSelection > 1 ? "s." : "."
      }`;
    }

    if (option.minSelection > 0 && isInfiniteSelectionsAllowed) {
      return `Select at least ${option.minSelection} option${
        option.minSelection > 1 ? "s." : "."
      }`;
    }

    if (option.minSelection > 0 && !isInfiniteSelectionsAllowed) {
      return `Select ${option.minSelection} to ${option.maxSelection} options.`;
    }

    return "";
  }, [option.minSelection, option.maxSelection, option.optionValues]);

  return (
    <div
      className={classNames(styles.OptionPicker, className)}
      data-testid={`option-picker-container-${option.id}`}
    >
      <div className={styles.optionHeader}>
        <h4 className={styles.optionName} data-testid={`option-title`}>
          {option.name}
        </h4>
        {isEditable ? (
          option.minSelection > 0 ? (
            <span
              className={styles.requiredLabel}
              data-testid={`required-text`}
            >
              Required
            </span>
          ) : (
            <span
              className={styles.optionalLabel}
              data-testid={`required-text`}
            >
              Optional
            </span>
          )
        ) : (
          <div />
        )}
      </div>
      {textToRenderForMinMax && (
        <div className={styles.minMaxText}>{textToRenderForMinMax}</div>
      )}
      <div className={styles.optionValuesContainer}>
        {option.optionValues
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .map((optionValue: OptionValueFragment) => (
            <button
              key={optionValue.id}
              className={classNames(styles.optionValue, {
                [styles.nonEditable]: !isEditable,
              })}
              onClick={() => {
                if (isEditable) {
                  handleOptionChange(optionValue);
                }
              }}
              data-testid={`option-value-${optionValue.id}`}
              disabled={
                selectedValues.length === option.maxSelection &&
                !selectedValues.includes(optionValue) &&
                option.maxSelection !== 1
              }
            >
              <div className={styles.nameContainer}>
                {isEditable &&
                  (option.maxSelection === 1 ? (
                    <div className={styles.selectCircleOuter}>
                      {selectedValues.some(
                        (value) => value.id === optionValue.id,
                      ) && (
                        <div
                          data-testid={`selected-circle`}
                          className={styles.selectCircleInner}
                        />
                      )}
                    </div>
                  ) : (
                    <Checkbox
                      checked={selectedValues.includes(optionValue) || false}
                      disabled={
                        selectedValues.length === option.maxSelection &&
                        !selectedValues.includes(optionValue)
                      }
                    />
                  ))}
                <span
                  className={styles.optionValueName}
                  data-testid={`option-value-name`}
                >
                  {optionValue.name}
                </span>
              </div>
              {optionValue.price > 0 && (
                <span
                  className={styles.optionValuePrice}
                  data-testid={`option-value-price`}
                >
                  {`$${optionValue.price.toFixed(2)}`}
                </span>
              )}
            </button>
          ))}
      </div>
    </div>
  );
};
