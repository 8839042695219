import { useScrollToTop } from "src/common/hooks";
import { PageTitle } from "src/components";
import styles from "src/pages/Terms/styles.module.scss";

export const Terms = () => {
  useScrollToTop();

  return (
    <div className={styles.Terms} data-testid="terms-and-conditions-container">
      <PageTitle title="Terms and Conditions" />
      <p className={styles.lastUpdated}>Last Updated 8 February 2024</p>
      <h4>
        This web app is created and hosted by Platter, an independent company
        that develops and maintains web apps for restaurants.
      </h4>
      <h4>
        Welcome to our web app. If you continue to browse and use this web app,
        you are agreeing to comply with and be bound by the following terms and
        conditions of use, which together with our privacy policy governs
        Platter's relationship with you in relation to this web app. If you
        disagree with any part of these terms and conditions, please do not use
        our web app.
      </h4>
      <h4>
        The term ‘Platter’ or ‘us’ or ‘we’ refers to the owner of the web app.
        The term ‘you’ refers to the user or viewer of our web app.
      </h4>
      <p>
        The content of the pages of this web app is for your general information
        and use only. It is subject to change without notice.
      </p>
      <p>
        This web app uses cookies to monitor browsing preferences. If you do
        allow cookies to be used, the following personal information may be
        stored by us for use by third parties: browsing data, anonymous
        purchasing data, usage analytics.
      </p>
      <p>
        By signing up for an account on this web app, you agree to receive
        promotional emails or texts from us. You can opt out of these emails at
        any time by clicking unsubscribe at the bottom of the email or respond
        STOP to texts.
      </p>
      <p>
        Neither we nor any third parties provide any warranty or guarantee as to
        the accuracy, timeliness, performance, completeness or suitability of
        the information and materials found or offered on this web app for any
        particular purpose. You acknowledge that such information and materials
        may contain inaccuracies or errors and we expressly exclude liability
        for any such inaccuracies or errors to the fullest extent permitted by
        law.
      </p>
      <p>
        Your use of any information or materials on this web app is entirely at
        your own risk, for which we shall not be liable. It shall be your own
        responsibility to ensure that any products, services or information
        available through this web app meet your specific requirements.
      </p>
      <p>
        This web app contains material which is owned by or licensed to us. This
        material includes, but is not limited to, the design, layout, look,
        appearance and graphics. Reproduction is prohibited other than in
        accordance with the copyright notice, which forms part of these terms
        and conditions.
      </p>
      <p>
        All trademarks reproduced in this web app, which are not the property
        of, or licensed to the operator, are acknowledged on the web app.
        Unauthorized use of this web app may give rise to a claim for damages
        and/or be a criminal offense.
      </p>
      <h4>Contact Us</h4>
      <p>
        If you have any questions or suggestions about my Privacy Policy, do not
        hesitate to contact us at admin@joinplatter.com.
      </p>
      <p>
        Your use of this web app and any dispute arising out of such use of the
        web app is subject to the laws of the United States of America.
      </p>
    </div>
  );
};
