// Restaurant reducer
import {
  RESTAURANT_ACTION,
  SetRestaurantFromDatabaseAction,
} from "src/state/restaurant/actions";
import { RestaurantState } from "src/state/restaurant/types";

type RestaurantAction = SetRestaurantFromDatabaseAction;

export const restaurantReducer = (
  state: RestaurantState = {},
  action: RestaurantAction,
) => {
  switch (action.type) {
    case RESTAURANT_ACTION.SET_RESTAURANT_FROM_DATABASE_ACTION: {
      const next: RestaurantState = { ...state };

      const { response: restaurant } = action;

      next.currentRestaurant = restaurant;

      return next;
    }
    default: {
      return state;
    }
  }
};
