import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBagShopping,
  faClock,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import styles from "src/pages/Checkout/CheckoutOrderDetails/CheckoutOrderDetailsOptions/styles.module.scss";
import { ORDER_TYPE } from "src/state/order/types";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { State } from "src/state/state";
import { formatISOStringToHoursAndMinutes } from "src/common/date";
import { captureManualSentryException } from "src/common/sentry";
import { logClickCheckoutDisclaimerToAnalytics } from "src/common/analytics";

export const CheckoutOrderDetailsOptions = () => {
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const customer = useSelector(
    (state: State) => state.customers.currentCustomer,
  );

  const checkoutSession = useSelector((state: State) => state.checkoutSession);

  const orderPickupLabelMap = useMemo(
    () => ({
      [ORDER_TYPE.PICKUP]: "Pickup",
      [ORDER_TYPE.DELIVERY]: "Delivery",
      [ORDER_TYPE.VIRTUAL_BRAND]: "Virtual Brand",
    }),
    [],
  );

  if (!restaurant) {
    captureManualSentryException(
      new Error("restaurant is undefined in CheckoutOrderDetailsOptions"),
    );
    return <div />;
  }

  return (
    <div className={styles.CheckoutOrderDetailsOptions}>
      <h1 className={styles.title}>Order Details</h1>
      {checkoutSession.delivery && (
        <div className={styles.orderDetailOptionRow}>
          <FontAwesomeIcon className={styles.icon} icon={faLocationDot} />
          <p
            className={styles.orderDetailsOptionSelection}
            data-testid="address"
          >
            {checkoutSession.delivery.customerAddress}
          </p>
        </div>
      )}
      <div className={styles.orderDetailOptionRow}>
        <FontAwesomeIcon className={styles.icon} icon={faBagShopping} />
        <p className={styles.orderDetailsOptionName}>Order Type: </p>
        <p
          className={styles.orderDetailsOptionSelection}
          data-testid="order-type"
        >
          {orderPickupLabelMap[checkoutSession.order.orderType]}
        </p>
      </div>
      <div className={styles.orderDetailOptionRow}>
        <FontAwesomeIcon className={styles.icon} icon={faClock} />
        <p className={styles.orderDetailsOptionName}>
          {checkoutSession.delivery ? "Delivered By:" : "Ready By: "}
        </p>
        <p
          className={styles.orderDetailsOptionSelection}
          data-testid="pickup-time"
        >
          {checkoutSession.delivery
            ? `${formatISOStringToHoursAndMinutes(
                checkoutSession.delivery.deliveryQuote.estimatedDeliveryTime,
              )}`
            : checkoutSession.pickupTime
              ? `${formatISOStringToHoursAndMinutes(
                  checkoutSession.pickupTime.toISOString(),
                )}`
              : `ASAP (${restaurant.restaurantSettings.minPreparationTime} min)`}
        </p>
      </div>
      {!restaurant.isOwnerManaged && (
        <p className={styles.checkoutDisclaimer}>
          {`Orders are routed to ${restaurant.restaurantName}'s POS system by `}
          <span
            className={styles.platterName}
            onClick={() => {
              logClickCheckoutDisclaimerToAnalytics(customer?.id);
              window.open("https://joinplatter.com/what-is-platter", "_black");
            }}
          >
            Platter
          </span>
          {", a third-party unaffliated consumer service."}
        </p>
      )}
    </div>
  );
};
