// Actions associated with items
import { Dispatch } from "redux";
import { getItemImageURLFromItemId } from "src/common/s3";
import { ItemFragment } from "src/state/item/types";

export enum ITEM_ACTION {
  SET_ALL_ITEMS_FOR_RESTAURANT_ACTION = "SET_ALL_ITEMS_FOR_RESTAURANT_ACTION",
}

export interface SetAllItemsForRestaurantAction {
  type: ITEM_ACTION.SET_ALL_ITEMS_FOR_RESTAURANT_ACTION;
  restaurantId: string;
  response: ItemFragment[];
}

export const setAllItemsForRestaurantAction = (
  restaurantId: string,
  items: ItemFragment[],
) => {
  return async (dispatch: Dispatch<SetAllItemsForRestaurantAction>) => {
    const action = {
      type: ITEM_ACTION.SET_ALL_ITEMS_FOR_RESTAURANT_ACTION,
      restaurantId,
      response: items.map((eachItem) => ({
        ...eachItem,
        imageURL: eachItem.hasImage
          ? getItemImageURLFromItemId(eachItem.id)
          : undefined,
      })),
    } as SetAllItemsForRestaurantAction;

    dispatch(action);

    return action;
  };
};
