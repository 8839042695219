// Types for order state
import { OptionValueFragment } from "src/common/types/OptionValue";
import { OptionFragment } from "src/state/option/types";

export enum ORDER_STATUS {
  NEW_ORDER = "NEW_ORDER",
  IN_PROGRESS = "IN_PROGRESS",
  READY_FOR_PICKUP = "READY_FOR_PICKUP",
  EN_ROUTE_TO_CUSTOMER = "EN_ROUTE_TO_CUSTOMER",
  COMPLETE = "COMPLETE",
  CANCELLED = "CANCELLED",
}

export enum ORDER_TYPE {
  PICKUP = "PICKUP",
  DELIVERY = "DELIVERY",
  VIRTUAL_BRAND = "VIRTUAL_BRAND",
}

export interface CreateOrderItemOptionAPIParams {
  optionId: string;
  optionValueIds: string[];
}

export interface CreateOrderItemAPIParams {
  itemId: string;
  quantity: number;
  dealAppliedId?: string | undefined;
  orderItemOptions: CreateOrderItemOptionAPIParams[];
}

export interface CreateOrderAPIParams {
  restaurantId: string;
  customerId: string;
  additionalNotes: string;
  pointsUsed: boolean;
  orderItems: CreateOrderItemAPIParams[];
  stripePaymentIntentId?: string;
  orderType: ORDER_TYPE;
  pickupTime: string;
  tipAmount: number;
}

export interface OrderItemOptionFragment {
  id: string;
  orderItemId: string;
  optionId: string;
  optionValueIds: string[];
  option: OptionFragment;
  optionValues: OptionValueFragment[];
}

export interface OrderItemFragment {
  id: string;
  orderItemOptions: OrderItemOptionFragment[];
  orderId: string;
  itemName: string;
  itemId: string;
  dealAppliedId?: string | undefined;
  quantity: number;
  priceBeforeDealBeforeOptions: number;
  priceAfterDealBeforeOptions: number;
  priceAfterDealAfterOptions: number;
  isRefunded: boolean;
}

export interface DeliveryFragment {
  id: string;
  restaurantId: string;
  orderId: string;
  deliveryFee: number;
  paymentProcessingFee: number;
  customerAddress: string;
  deliveryInstructions: string;
  contactlessDelivery: boolean;
  driverTipInDollars: number;
  quoteAccepted: boolean;
  trackingUrl: string;
  supportReference: string;
  estimatedPickupTime: string;
  actualPickupTime: string;
  estimatedDeliveryTime: string;
  actualDeliveryTime: string;
  dropoffImageUrl: string;
  cancellationReason: string;
  requestedDeliveryTime: string;
}

export interface OrderFragment {
  id: string;
  orderItems: OrderItemFragment[];
  customerId?: string;
  guestCustomerId?: string;
  deliveryId?: string;
  restaurantId: string;
  promoCodeId?: string;
  restaurantWideDiscountId: string | undefined;
  orderNumber: number;
  subtotal: number;
  pointsUsedInDollars: number;
  additionalNotes: string;
  stripePaymentIntentId?: string;
  priceAfterRestaurantWideDiscount: number;
  priceAfterPromoCode: number;
  salesTax: number;
  tipAmount: number;
  paymentProcessingFee: number;
  totalPriceCharged: number;
  serviceFee: number;
  serviceFeeProfit: number;
  status: ORDER_STATUS;
  orderType: ORDER_TYPE;
  paymentIntentSucceeded: boolean;
  delivery?: DeliveryFragment;
  locationId: string;
  pickupTime: string;
  createdAt: string;
}

export interface OrderState {
  [restaurantId: string]: {
    [orderId: string]: OrderFragment;
  };
}
