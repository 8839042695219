import styles from "src/components/TextInput/styles.module.scss";
import classNames from "classnames";

interface TextInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  className?: string;
  testId?: string;
  onChangeText?: (text: string) => void;
  inputPrefix?: string;
  label?: string;
  errorMessage?: string;
  inputClassName?: string;
}

export const TextInput = (props: TextInputProps) => {
  const {
    className,
    testId,
    onChangeText,
    label,
    inputPrefix,
    errorMessage,
    inputClassName,
    ...rest
  } = props;

  return (
    <div
      className={classNames(styles.TextInput, className)}
      data-testid={testId}
    >
      {label && (
        <h3
          className={classNames(styles.label, {
            [styles.errorLabel]: errorMessage !== undefined,
          })}
          data-testid="label"
        >
          {label}
        </h3>
      )}
      <div className={styles.inputRow}>
        {inputPrefix && <h3 className={styles.inputPrefix}>{inputPrefix}</h3>}
        <input
          data-testid="input"
          className={classNames(
            styles.input,
            {
              [styles.errorInput]: errorMessage !== undefined,
            },
            inputClassName,
          )}
          onChange={(e) => onChangeText && onChangeText(e.target.value)}
          {...rest}
        />
      </div>
      {errorMessage && (
        <h3 className={styles.errorMessage} data-testid="error-message">
          {errorMessage}
        </h3>
      )}
    </div>
  );
};
