// Order Banner reducer
import {
  ORDER_BANNER_ACTION,
  UpdateOrderBannerDisplayedAction,
} from "src/state/orderBanner/actions";
import { OrderBannerState } from "src/state/orderBanner/types";

type OrderBannerAction = UpdateOrderBannerDisplayedAction;

export const orderBannerReducer = (
  state: OrderBannerState = {
    isDisplayed: false,
  },
  action: OrderBannerAction,
) => {
  switch (action.type) {
    case ORDER_BANNER_ACTION.UPDATE_ORDER_DISPLAYED_ACTION: {
      const next: OrderBannerState = { ...state };

      const { response: isDisplayed } = action;

      next.isDisplayed = isDisplayed;

      return next;
    }
    default: {
      return state;
    }
  }
};
