import classNames from "classnames";
import styles from "src/pages/Receipt/OrderItem/styles.module.scss";
import { OrderItemFragment } from "src/state/order/types";

interface ComponentProps {
  orderItem: OrderItemFragment;
  testId?: string;
}

export const OrderItem = ({ testId, orderItem }: ComponentProps) => {
  return (
    <div
      className={classNames(styles.OrderItem, {
        [styles.refunded]: orderItem.isRefunded,
      })}
      data-testid={testId}
    >
      <div className={styles.row}>
        <h4 className={styles.itemName} data-testid="item-name">
          {`${orderItem.quantity}x ${orderItem.itemName}${orderItem.isRefunded ? " (Refunded)" : ""}`}
        </h4>
        <h4 className={styles.itemPrice} data-testid="item-price">
          ${orderItem.priceAfterDealBeforeOptions.toFixed(2)}
        </h4>
      </div>
      {orderItem.orderItemOptions.map((eachOptionSelected, index) => {
        const optionValuesNames = eachOptionSelected.optionValues
          .map((optionValue) => optionValue.name)
          .join(", ");
        const optionValuesTotalPrice = eachOptionSelected.optionValues.reduce(
          (totalPrice, optionValue) => totalPrice + optionValue.price,
          0,
        );

        return (
          <div
            className={styles.row}
            key={index}
            data-testid={`option-selected-${eachOptionSelected.optionId}`}
          >
            <h4
              className={styles.optionName}
              data-testid="option-value-selected"
            >
              {`${eachOptionSelected.option.name}: ${optionValuesNames}`}
            </h4>
            <h4 className={styles.optionPrice} data-testid="option-value-price">
              ${(optionValuesTotalPrice * orderItem.quantity).toFixed(2)}
            </h4>
          </div>
        );
      })}
    </div>
  );
};
