// Functions & types for interacting with the locally persisted async storage

export type MockStorageKey =
  | "mockSignUpUserInAWSCognito"
  | "mockLogInUserInAWSCognito"
  | "mockUpdateUserEmailInAWSCognito"
  | "mockGetIdTokenFromAWSCognito"
  | "mockDeleteUserFromAWSCognito"
  | "mockSignOutUser"
  | "mockConfirmUserInAWSCognito"
  | "mockSendForgotPasswordEmailInAWSCognito"
  | "mockConfirmForgotPasswordInAWSCognito"
  | "mockAutoscroll"
  | "mockIncorrectCredentials"
  | "mockEmailAddressAlreadyExists"
  | "mockResetPasswordIncorrectVerificationCode";

export type StorageKey =
  | MockStorageKey
  | "checkoutSession"
  | "scheduledCateringCheckoutSession"
  | "DOMAIN_NAME"
  | "BUNDLE_ID"
  | "RESTAURANT_ID"
  | "AWS_COGNITO_USER_POOL_ID"
  | "AWS_COGNITO_IDENTITY_POOL_ID"
  | "AWS_COGNITO_CLIENT_ID"
  | "ANDROID_GOOGLE_PLAY_STORE_URL"
  | "IOS_APP_STORE_URL"
  | "USER_ID"
  | "REFRESH_TOKEN"
  | "RESTAURANT_NAME";

export const isLocalStorageAvailable = (): boolean => {
  try {
    const storageTestKey = "__platter_storage_test_key__";
    const storageTestValue = "__platter_storage_test_value__";

    localStorage.setItem(storageTestKey, storageTestValue);
    localStorage.removeItem(storageTestKey);

    return true;
  } catch {
    return false;
  }
};

export const putItemIntoLocalStorage = (
  key: StorageKey,
  value: string,
): void => {
  if (!isLocalStorageAvailable()) {
    return;
  }

  localStorage.setItem(key, value);
};

export const getItemFromLocalStorage = (key: StorageKey): string | null => {
  if (!isLocalStorageAvailable()) {
    return null;
  }

  const value = localStorage.getItem(key) as string;

  return value;
};

export const removeItemFromLocalStorage = (key: StorageKey): void => {
  if (!isLocalStorageAvailable()) {
    return;
  }

  localStorage.removeItem(key);
};
