import { ItemState } from "src/state/item/types";
import { CategoryState } from "src/state/category/types";
import { State } from "src/state/state";

export const selectCategoriesWithAtLeastOneDisplayedItem = (
  state: State,
  displayedItems: ItemState,
): CategoryState => {
  const restaurant = state.restaurants.currentRestaurant;
  if (!restaurant) {
    return {};
  }

  const restaurantId = restaurant.id;
  const categoryState = { ...state.categories[restaurantId] };

  for (const categoryId in categoryState) {
    const itemsForThisCategory = displayedItems[categoryId] || {};

    if (Object.keys(itemsForThisCategory).length === 0) {
      delete categoryState[categoryId];
    }
  }

  return {
    [restaurantId]: categoryState,
  };
};
