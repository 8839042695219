// Utils for client config

import { captureManualSentryException } from "src/common/sentry";
import { ClientConfigFragment } from "src/state/clientConfig/types";
import { store } from "src/state/store";

export const getClientConfig = (key: keyof ClientConfigFragment) => {
  const state = store.getState();

  const restaurant = state.restaurants.currentRestaurant;

  if (!restaurant) {
    captureManualSentryException(
      `No restaurant found in getClientConfig. Attempting to fetch: ${key}.`,
    );
    return "";
  }

  const clientConfig = state.clientConfig[restaurant.id];

  if (!clientConfig) {
    captureManualSentryException(
      `No client config found in getClientConfig. Attempting to fetch: ${key}.`,
    );
    return "";
  }

  return clientConfig[key];
};
