// Cart reducer
import {
  CART_ACTION,
  AddItemToCartAction,
  ClearCartAction,
  RemoveEntityFromCartAction,
  UpdateEntityInCartAction,
  AddDealToCartAction,
} from "src/state/cart/actions";
import { CartState } from "src/state/cart/types";

type CartAction =
  | AddItemToCartAction
  | ClearCartAction
  | RemoveEntityFromCartAction
  | UpdateEntityInCartAction
  | AddDealToCartAction;

export const cartReducer = (state: CartState = {}, action: CartAction) => {
  switch (action.type) {
    case CART_ACTION.ADD_ITEM_TO_CART_ACTION: {
      const next: CartState = { ...state };

      const cartItem = action.response;

      next[cartItem.id] = cartItem;

      return next;
    }
    case CART_ACTION.ADD_DEAL_TO_CART_ACTION: {
      const next: CartState = { ...state };

      const cartBogoDeal = action.response;

      next[cartBogoDeal.id] = cartBogoDeal;

      return next;
    }
    case CART_ACTION.CLEAR_CART_ACTION: {
      return {};
    }
    case CART_ACTION.REMOVE_ENTITY_FROM_CART_ACTION: {
      const next: CartState = { ...state };

      const entityId = action.response;

      delete next[entityId];

      return next;
    }
    case CART_ACTION.UPDATE_ENTITY_IN_CART_ACTION: {
      const next: CartState = { ...state };

      const { newCartEntity, cartEntityId } = action.response;

      next[cartEntityId] = newCartEntity;

      return next;
    }
    default: {
      return state;
    }
  }
};
