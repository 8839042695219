// Actions associated with restaurants
import { Dispatch } from "redux";
import { v4 as uuid } from "uuid";

export enum SESSION_ACTION {
  SET_SESSION_ID = "SET_SESSION_ID",
}

export interface SetSessionAction {
  type: SESSION_ACTION.SET_SESSION_ID;
  sessionId: string;
}

export const setSessionIdAction = () => {
  return async (dispatch: Dispatch<SetSessionAction>) => {
    const sessionId = uuid();

    const action = {
      type: SESSION_ACTION.SET_SESSION_ID,
      sessionId,
    } as SetSessionAction;

    dispatch(action);

    return action;
  };
};
