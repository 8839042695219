// Reducer for Scheduled Catering Checkout Session
import { putItemIntoLocalStorage } from "src/common/localStorage";
import {
  SCHEDULED_CATERING_CHECKOUT_SESSION,
  CreateScheduledCateringCheckoutSession,
  CompleteScheduledCateringCheckoutSessionAction,
} from "src/state/scheduledCateringCheckoutSession/actions";
import { ScheduledCateringCheckoutSessionState } from "src/state/scheduledCateringCheckoutSession/types";

type ScheduledCateringCheckoutSessionAction =
  | CreateScheduledCateringCheckoutSession
  | CompleteScheduledCateringCheckoutSessionAction;

export const scheduledCateringCheckoutSessionReducer = (
  state: ScheduledCateringCheckoutSessionState | object = {},
  action: ScheduledCateringCheckoutSessionAction,
) => {
  switch (action.type) {
    case SCHEDULED_CATERING_CHECKOUT_SESSION.CREATE_SCHEDULED_CATERING_CHECKOUT_SESSION: {
      const checkoutSessionState = action.response;

      const cacheKey = `scheduledCateringCheckoutSession`;
      putItemIntoLocalStorage(cacheKey, JSON.stringify(checkoutSessionState));

      return checkoutSessionState;
    }
    case SCHEDULED_CATERING_CHECKOUT_SESSION.COMPLETE_SCHEDULED_CATERING_CHECKOUT_SESSION_ACTION: {
      const existingState = state as ScheduledCateringCheckoutSessionState;

      const next = {
        ...existingState,
        completed: true,
      };
      const cacheKey = `scheduledCateringCheckoutSession`;
      putItemIntoLocalStorage(cacheKey, JSON.stringify(next));

      return next;
    }
    default: {
      return state;
    }
  }
};
