import { OptionFragment } from "src/state/option/types";
import { State } from "src/state/state";

export const selectOptionsForItem = (
  state: State,
  itemId: string,
): {
  [optionId: string]: OptionFragment;
} => {
  const restaurant = state.restaurants.currentRestaurant;
  if (!restaurant) {
    return {};
  }

  const restaurantId = restaurant.id;
  const newState: {
    [optionId: string]: OptionFragment;
  } = {};

  for (const optionId in state.options[restaurantId]) {
    const option = state.options[restaurantId][optionId];

    if (option.itemIds.includes(itemId)) {
      newState[optionId] = option;
    }
  }

  return newState;
};
