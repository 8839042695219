// Actions associated with authentication
import { Dispatch } from "redux";
import { logInUserInAWSCognito, signUpUserInAWSCognito } from "src/common/auth";

export enum AUTH_ACTION {
  CREATE_NEW_USER_IN_AWS_ACTION = "CREATE_NEW_USER_IN_AWS_ACTION",
  LOGIN_USER_IN_AWS_ACTION = "LOGIN_USER_IN_AWS_ACTION",
  SET_AUTH_STATE_ACTION = "SET_AUTH_STATE_ACTION",
  SIGN_OUT_USER_ACTION = "SIGN_OUT_USER_ACTION",
}

export interface CreateNewUserInAWSActionResponse {
  type: AUTH_ACTION.CREATE_NEW_USER_IN_AWS_ACTION;
  response: {
    userId: string;
    refreshToken: string;
  };
}

export const createNewUserInAWSAction = (
  email: string,
  password: string,
  fName: string,
  lName: string,
) => {
  return async (dispatch: Dispatch<CreateNewUserInAWSActionResponse>) => {
    const session = await signUpUserInAWSCognito(email, password, fName, lName);

    const action = {
      type: AUTH_ACTION.CREATE_NEW_USER_IN_AWS_ACTION,
      response: {
        userId: session.userId,
        refreshToken: session.refreshToken,
      },
    } as CreateNewUserInAWSActionResponse;

    dispatch(action);

    return action;
  };
};

export interface LoginUserInAWSActionResponse {
  type: AUTH_ACTION.LOGIN_USER_IN_AWS_ACTION;
  response: {
    userId: string;
    refreshToken: string;
  };
}

export const loginUserInAWSAction = (email: string, password: string) => {
  return async (dispatch: Dispatch<LoginUserInAWSActionResponse>) => {
    const session = await logInUserInAWSCognito(email, password);

    const action = {
      type: AUTH_ACTION.LOGIN_USER_IN_AWS_ACTION,
      response: {
        userId: session.userId,
        refreshToken: session.refreshToken,
      },
    } as LoginUserInAWSActionResponse;

    dispatch(action);

    return action;
  };
};

export interface SetAuthStateActionResponse {
  type: AUTH_ACTION.SET_AUTH_STATE_ACTION;
  response: {
    userId: string;
    refreshToken: string;
  };
}

export const setAuthStateAction = (userId: string, refreshToken: string) => {
  return async (dispatch: Dispatch<SetAuthStateActionResponse>) => {
    const action = {
      type: AUTH_ACTION.SET_AUTH_STATE_ACTION,
      response: {
        userId,
        refreshToken,
      },
    } as SetAuthStateActionResponse;

    dispatch(action);

    return action;
  };
};

export interface SignOutUserActionResponse {
  type: AUTH_ACTION.SIGN_OUT_USER_ACTION;
}

export const signOutUserAction = () => {
  return async (dispatch: Dispatch<SignOutUserActionResponse>) => {
    const action = {
      type: AUTH_ACTION.SIGN_OUT_USER_ACTION,
    } as SignOutUserActionResponse;

    dispatch(action);

    return action;
  };
};
