// Client config reducer
import {
  CLIENT_CONFIG_ACTION,
  SetClientConfigAction,
} from "src/state/clientConfig/actions";
import { ClientConfigState } from "src/state/clientConfig/types";

type ClientConfigAction = SetClientConfigAction;

export const clientConfigReducer = (
  state: ClientConfigState = {},
  action: ClientConfigAction,
) => {
  switch (action.type) {
    case CLIENT_CONFIG_ACTION.SET_CLIENT_CONFIG_ACTION: {
      const { response } = action;

      const next: ClientConfigState = {
        ...state,
        [response.restaurantId]: { ...response },
      };

      return next;
    }
    default: {
      return state;
    }
  }
};
