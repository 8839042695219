// Actions associated with scheduled catering orders
import { Dispatch } from "redux";
import { post } from "src/api/server";
import { ScheduledCateringOrderFragment } from "src/state/scheduledCateringOrder/types";

export enum SCHEDULED_CATERING_ORDER_ACTION {
  GET_ALL_SCHEDULED_CATERING_ORDERS_FOR_CUSTOMER_ACTION = "GET_ALL_SCHEDULED_CATERING_ORDERS_FOR_CUSTOMER_ACTION",
  GET_ACTIVE_SCHEDULED_CATERING_ORDERS_FOR_CUSTOMER_ACTION = "GET_ACTIVE_SCHEDULED_CATERING_ORDERS_FOR_CUSTOMER_ACTION",
  GET_SPECIFIC_SCHEDULED_CATERING_ORDER_ACTION = "GET_SPECIFIC_SCHEDULED_CATERING_ORDER_ACTION",
  CLEAR_SCHEDULED_CATERING_ORDERS_FROM_STATE_ACTION = "CLEAR_SCHEDULED_CATERING_ORDERS_FROM_STATE_ACTION",
}

export interface GetAllScheduledCateringOrdersForCustomerAction {
  type: SCHEDULED_CATERING_ORDER_ACTION.GET_ALL_SCHEDULED_CATERING_ORDERS_FOR_CUSTOMER_ACTION;
  restaurantId: string;
  response: ScheduledCateringOrderFragment[];
}

export const getAllScheduledCateringOrdersForCustomerAction = (
  restaurantId: string,
  customerId: string,
) => {
  return async (
    dispatch: Dispatch<GetAllScheduledCateringOrdersForCustomerAction>,
  ) => {
    const response = (await post({
      endpoint: "/scheduled_catering_order/list",
      params: {
        customerId,
      },
      service: "thanos",
    })) as ScheduledCateringOrderFragment[];

    const action = {
      type: SCHEDULED_CATERING_ORDER_ACTION.GET_ALL_SCHEDULED_CATERING_ORDERS_FOR_CUSTOMER_ACTION,
      restaurantId,
      response,
    } as GetAllScheduledCateringOrdersForCustomerAction;

    dispatch(action);

    return action;
  };
};

export interface GetSpecificScheduledCateringOrderAction {
  type: SCHEDULED_CATERING_ORDER_ACTION.GET_SPECIFIC_SCHEDULED_CATERING_ORDER_ACTION;
  restaurantId: string;
  response: ScheduledCateringOrderFragment;
}

export const getSpecificScheduledCateringOrderAction = (
  restaurantId: string,
  orderId: string,
) => {
  return async (
    dispatch: Dispatch<GetSpecificScheduledCateringOrderAction>,
  ) => {
    const response = (await post({
      endpoint: "/scheduled_catering_order/get",
      params: {
        orderId,
      },
      service: "thanos",
    })) as ScheduledCateringOrderFragment;

    const action = {
      type: SCHEDULED_CATERING_ORDER_ACTION.GET_SPECIFIC_SCHEDULED_CATERING_ORDER_ACTION,
      restaurantId,
      response,
    } as GetSpecificScheduledCateringOrderAction;

    dispatch(action);

    return action;
  };
};

export interface GetActiveScheduledCateringOrdersForCustomerAction {
  type: SCHEDULED_CATERING_ORDER_ACTION.GET_ACTIVE_SCHEDULED_CATERING_ORDERS_FOR_CUSTOMER_ACTION;
  customerId: string;
  restaurantId: string;
  response: ScheduledCateringOrderFragment[];
}

export const getActiveScheduledCateringOrdersForCustomerAction = (
  customerId: string,
  restaurantId: string,
) => {
  return async (
    dispatch: Dispatch<GetActiveScheduledCateringOrdersForCustomerAction>,
  ) => {
    const response = (await post({
      endpoint: "/scheduled_catering_order/list",
      params: {
        customerId,
        getOnlyActiveOrders: true,
      },
      service: "thanos",
    })) as ScheduledCateringOrderFragment[];

    const action = {
      type: SCHEDULED_CATERING_ORDER_ACTION.GET_ACTIVE_SCHEDULED_CATERING_ORDERS_FOR_CUSTOMER_ACTION,
      customerId,
      restaurantId,
      response,
    } as GetActiveScheduledCateringOrdersForCustomerAction;

    dispatch(action);

    return action;
  };
};

export interface ClearScheduledCateringOrdersFromStateAction {
  type: SCHEDULED_CATERING_ORDER_ACTION.CLEAR_SCHEDULED_CATERING_ORDERS_FROM_STATE_ACTION;
}

export const clearScheduledCateringOrdersFromStateAction = () => {
  return async (
    dispatch: Dispatch<ClearScheduledCateringOrdersFromStateAction>,
  ) => {
    const action = {
      type: SCHEDULED_CATERING_ORDER_ACTION.CLEAR_SCHEDULED_CATERING_ORDERS_FROM_STATE_ACTION,
    } as ClearScheduledCateringOrdersFromStateAction;

    dispatch(action);

    return action;
  };
};
