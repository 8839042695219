// Scheduled Catering Order Reducer
import {
  SCHEDULED_CATERING_ORDER_ACTION,
  GetAllScheduledCateringOrdersForCustomerAction,
  GetActiveScheduledCateringOrdersForCustomerAction,
  GetSpecificScheduledCateringOrderAction,
  ClearScheduledCateringOrdersFromStateAction,
} from "src/state/scheduledCateringOrder/actions";
import {
  ScheduledCateringOrderFragment,
  ScheduledCateringOrderState,
} from "src/state/scheduledCateringOrder/types";

type ScheduledCateringOrderAction =
  | GetAllScheduledCateringOrdersForCustomerAction
  | GetActiveScheduledCateringOrdersForCustomerAction
  | GetSpecificScheduledCateringOrderAction
  | ClearScheduledCateringOrdersFromStateAction;

export const scheduledCateringOrderReducer = (
  state: ScheduledCateringOrderState = {},
  action: ScheduledCateringOrderAction,
) => {
  switch (action.type) {
    case SCHEDULED_CATERING_ORDER_ACTION.GET_ALL_SCHEDULED_CATERING_ORDERS_FOR_CUSTOMER_ACTION: {
      const { restaurantId, response: orders } = action;

      const next: ScheduledCateringOrderState = {
        ...state,
        [restaurantId]: {
          ...(state[restaurantId] || {}),
          ...orders.reduce(
            (
              acc: { [orderId: string]: ScheduledCateringOrderFragment },
              order,
            ) => {
              acc[order.id] = order;
              return acc;
            },
            {},
          ),
        },
      };

      return next;
    }
    case SCHEDULED_CATERING_ORDER_ACTION.GET_SPECIFIC_SCHEDULED_CATERING_ORDER_ACTION: {
      const { restaurantId, response: order } = action;

      const next: ScheduledCateringOrderState = {
        ...state,
        [restaurantId]: {
          ...(state[restaurantId] || {}),
          [order.id]: order,
        },
      };

      return next;
    }
    case SCHEDULED_CATERING_ORDER_ACTION.GET_ACTIVE_SCHEDULED_CATERING_ORDERS_FOR_CUSTOMER_ACTION: {
      const { customerId, response: orders } = action;

      const next: ScheduledCateringOrderState = {
        ...state,
        [customerId]: {
          ...(state[customerId] || {}),
          ...orders.reduce(
            (
              acc: { [orderId: string]: ScheduledCateringOrderFragment },
              order,
            ) => {
              acc[order.id] = order;
              return acc;
            },
            {},
          ),
        },
      };

      return next;
    }
    case SCHEDULED_CATERING_ORDER_ACTION.CLEAR_SCHEDULED_CATERING_ORDERS_FROM_STATE_ACTION: {
      return {};
    }
    default: {
      return state;
    }
  }
};
