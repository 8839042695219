// Types for global ui variant state
export enum UIVariants {
  VARIANT_1 = 1,
  VARIANT_2 = 2,
  VARIANT_3 = 3,
  VARIANT_4 = 4,
  VARIANT_5 = 5,
  VARIANT_6 = 6,
  VARIANT_7 = 7,
  VARIANT_8 = 8,
  VARIANT_9 = 9,
  VARIANT_10 = 10,
}

export interface GlobalUIVariantState {
  promotionalCheckboxVariant?: UIVariants;
}
