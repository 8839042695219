import { ReactNode, FunctionComponent } from "react";
import Skeleton from "react-loading-skeleton";
import styles from "src/components/SkeletonLoading/styles.module.scss";
import "react-loading-skeleton/dist/skeleton.css";

interface WrapperProps {
  children?: ReactNode;
}

const Wrapper: FunctionComponent<WrapperProps> = ({ children }) => (
  <div className={styles.Wrapper}>{children}</div>
);

interface SkeletonLoadingProps {
  count?: number;
}

export const SkeletonLoading = ({ count = 10 }: SkeletonLoadingProps) => {
  return <Skeleton height={30} count={count} wrapper={Wrapper} />;
};
