// Actions associated with a checkout session
import { Dispatch } from "redux";
import {
  CreateDeliveryParams,
  createOrderIntentInDatabase,
} from "src/common/order";
import { CheckoutSessionState } from "src/state/checkoutSession/types";
import { ORDER_TYPE, OrderFragment } from "src/state/order/types";
import { CartState } from "src/state/cart/types";
import { createOrderItemsFromCart } from "src/state/order/utils";

export enum CHECKOUT_SESSION {
  CREATE_CHECKOUT_SESSION = "CREATE_CHECKOUT_SESSION",
  COMPLETE_CHECKOUT_SESSION_ACTION = "COMPLETE_CHECKOUT_SESSION_ACTION",
}

export interface CreateCheckoutSessionAction {
  type: CHECKOUT_SESSION.CREATE_CHECKOUT_SESSION;
  response: CheckoutSessionState;
}

export const createCheckoutStateAction = (
  customerId: string | undefined,
  restaurantId: string,
  cartTotal: number,
  salesTax: number,
  platterSponsoredPromoCodeValueInDollars: number,
  isPointsSelected: boolean,
  cart: CartState,
  orderType: ORDER_TYPE,
  timeSelected: Date | undefined,
  additionalNotes: string,
  tipAmount: number | undefined,
  delivery: CreateDeliveryParams | undefined,
  locationId: string,
  promoCodeId: string | undefined,
) => {
  return async (dispatch: Dispatch<CreateCheckoutSessionAction>) => {
    let paymentIntentClientSecret: string;
    let order: OrderFragment;

    const orderIntentResponse = await createOrderIntentInDatabase(
      customerId,
      restaurantId,
      cartTotal === 0 ? 1 : cartTotal,
      salesTax === 0 ? 0 : salesTax,
      platterSponsoredPromoCodeValueInDollars,
      isPointsSelected,
      createOrderItemsFromCart(cart),
      orderType,
      timeSelected && orderType === ORDER_TYPE.PICKUP
        ? timeSelected.toISOString()
        : undefined,
      additionalNotes,
      tipAmount ? tipAmount : 0,
      delivery,
      locationId,
      promoCodeId,
    );

    if ("message" in orderIntentResponse) {
      return orderIntentResponse.message;
    } else {
      paymentIntentClientSecret = orderIntentResponse.paymentIntentClientSecret;
      order = orderIntentResponse.order;
    }

    const checkoutSessionState: CheckoutSessionState = {
      paymentIntentClientSecret: paymentIntentClientSecret,
      order: order,
      pickupTime: timeSelected,
      completed: false,
      delivery: delivery,
    };

    const action = {
      type: CHECKOUT_SESSION.CREATE_CHECKOUT_SESSION,
      response: checkoutSessionState,
    } as CreateCheckoutSessionAction;

    dispatch(action);

    return action;
  };
};

export interface CompleteCheckoutSessionAction {
  type: CHECKOUT_SESSION.COMPLETE_CHECKOUT_SESSION_ACTION;
}

export const completeCheckoutSessionAction = () => {
  return async (dispatch: Dispatch<CompleteCheckoutSessionAction>) => {
    const action = {
      type: CHECKOUT_SESSION.COMPLETE_CHECKOUT_SESSION_ACTION,
    } as CompleteCheckoutSessionAction;

    dispatch(action);

    return action;
  };
};
