import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBagShopping,
  faClock,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import styles from "src/pages/Cart/OrderDetails/OrderDetailsOptions/styles.module.scss";
import { ORDER_TYPE } from "src/state/order/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "src/state/state";
import {
  daysOfWeek,
  getTenMinuteTimesBetweenDates,
  isRestaurantCurrentlyOpen,
  formatISOStringToHoursAndMinutes,
  convertAMPMTimeToDateObject,
  isNowWithinOpenHoursOfOperation,
} from "src/common/date";
import Modal from "react-awesome-modal";
import {
  Button,
  Checkbox,
  SelectableButton,
  TextArea,
  TextInput,
} from "src/components";
import { captureManualSentryException } from "src/common/sentry";
import { DayOfTheWeekHours } from "src/common/types/DayOfTheWeekHours";
import { HoursOfOperationMode } from "src/common/types/HoursOfOperation";
import { updateOrderTypeAction } from "src/state/orderType/actions";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { getCurrentEnvironment, getEnvVariable } from "src/config/getConfig";
import ReactLoading from "react-loading";
import { calculateDeliveryQuote } from "src/common/price";
import { DeliveryQuote } from "src/common/types/DeliveryQuote";
import { useMockPlacesService } from "src/__mocks__/useMockPlacesService";
import {
  logNotAbleToDeliverToAnalytics,
  logUpdateOrderTypeToAnalytics,
} from "src/common/analytics";
import { LocationFragment } from "src/common/types/Location";
import { useDesign } from "src/common/hooks";
interface OrderDetailsOptionsProps {
  time: Date | undefined;
  setTime: (time: Date | undefined) => void;
  selectedAddress: string | undefined;
  setSelectedAddress: (selectedAddress: string | undefined) => void;
  deliveryInstructions: string;
  setDeliveryInstructions: (deliveryInstructions: string) => void;
  contactlessDelivery: boolean;
  setContactlessDelivery: (contactlessDelivery: boolean) => void;
  setDeliveryQuote: (deliveryQuote: DeliveryQuote | undefined) => void;
  subtotal: number;
}

export const OrderDetailsOptions = ({
  time,
  setTime,
  selectedAddress,
  setSelectedAddress,
  deliveryInstructions,
  setDeliveryInstructions,
  contactlessDelivery,
  setContactlessDelivery,
  setDeliveryQuote,
  subtotal,
}: OrderDetailsOptionsProps) => {
  const design = useDesign();
  const [isLoading, setIsLoading] = useState(false);
  const [addressTextInModal, setAddressTextInModal] = useState("");
  const [addressTextError, setAddressTextError] = useState<
    string | undefined
  >();
  const [selectedAddressInModal, setSelectedAddressInModal] = useState<
    string | undefined
  >(undefined);
  const [deliveryInstructionsInModal, setDeliveryInstructionsInModal] =
    useState("");
  const [contactlessDeliveryInModal, setContactlessDeliveryInModal] =
    useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [isOrderTypeModalOpen, setIsOrderTypeModalOpen] = useState(false);
  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false);

  const syncModalStateWithProps = useCallback(() => {
    if (selectedAddress) {
      setSelectedAddressInModal(selectedAddress);
      setAddressTextInModal(selectedAddress);
    } else {
      setSelectedAddressInModal(undefined);
      setAddressTextInModal("");
    }

    setDeliveryInstructionsInModal(deliveryInstructions);
    setContactlessDeliveryInModal(contactlessDelivery);
  }, [selectedAddress, deliveryInstructions, contactlessDelivery]);

  useEffect(() => {
    if (isAddressModalOpen) {
      syncModalStateWithProps();
    }
  }, [isAddressModalOpen]);

  useEffect(() => {
    if (selectedOrderType === ORDER_TYPE.DELIVERY && !selectedAddress) {
      setIsAddressModalOpen(true);
    }
  }, []);

  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({
    query: "(min-width: 875px)",
  });
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    getCurrentEnvironment() === "test"
      ? useMockPlacesService()
      : usePlacesService({
          apiKey: getEnvVariable("GOOGLE_MAPS_API_KEY"),
        });

  const customer = useSelector(
    (state: State) => state.customers.currentCustomer,
  );
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const selectedOrderType = useSelector(
    (state: State) => state.orderType.selectedOrderType,
  );
  const selectedLocation = useSelector(
    (state: State) => state.location.selectedLocation as LocationFragment,
  );

  const showAddressSearchResults = useMemo(() => {
    return (
      placePredictions.length > 0 &&
      !isPlacePredictionsLoading &&
      !selectedAddressInModal &&
      !addressTextError
    );
  }, [
    placePredictions,
    isPlacePredictionsLoading,
    selectedAddressInModal,
    addressTextError,
  ]);

  const orderTypeLabelMap = useMemo(
    () => ({
      [ORDER_TYPE.PICKUP]: "Pickup",
      [ORDER_TYPE.DELIVERY]: "Delivery",
    }),
    [],
  );

  const isRestaurantOpen = useMemo(
    () =>
      selectedLocation
        ? isRestaurantCurrentlyOpen(selectedLocation.hoursOfOperation)
        : false,
    [selectedLocation],
  );

  const timeText = useMemo(() => {
    if (restaurant) {
      if (time) {
        return formatISOStringToHoursAndMinutes(time.toISOString());
      } else if (selectedOrderType === ORDER_TYPE.PICKUP) {
        return `ASAP (${restaurant.restaurantSettings.minPreparationTime} min)`;
      } else {
        return "ASAP";
      }
    }
  }, [time, restaurant, selectedOrderType]);

  const timeOptions = useMemo(() => {
    if (restaurant && isRestaurantOpen && selectedLocation) {
      const nowDate = new Date();
      const nowDateInMs = nowDate.getTime();
      const minPrepTimeInMs =
        restaurant.restaurantSettings.minPreparationTime * 60 * 1000;

      const todayDayOfWeek = daysOfWeek[
        nowDate.getDay()
      ].toLowerCase() as keyof typeof selectedLocation.hoursOfOperation;

      let todayHours = selectedLocation.hoursOfOperation[todayDayOfWeek];

      if (!todayHours) {
        return [];
      }

      todayHours = todayHours as DayOfTheWeekHours[];

      const timeOptionsArray: string[] = [];

      for (const hours of todayHours) {
        const openingTime = hours.open;
        const openingDate = new Date(openingTime);
        openingDate.setFullYear(0);
        openingDate.setMonth(0);
        openingDate.setDate(0);

        const closingTime = hours.close;
        const closingDate = new Date(closingTime);
        closingDate.setFullYear(0);
        closingDate.setMonth(0);
        closingDate.setDate(0);

        const datePlusMinPrepTime = new Date(nowDateInMs + minPrepTimeInMs);
        datePlusMinPrepTime.setFullYear(0);
        datePlusMinPrepTime.setMonth(0);
        datePlusMinPrepTime.setDate(0);

        const tenMinuteTimesFromNowTillClosing = getTenMinuteTimesBetweenDates(
          datePlusMinPrepTime.toISOString(),
          closingDate.toISOString(),
        );

        const tenMinuteTimesFromOpeningTillClosing =
          getTenMinuteTimesBetweenDates(
            openingDate.toISOString(),
            closingDate.toISOString(),
          );

        if (
          tenMinuteTimesFromNowTillClosing.length <
          tenMinuteTimesFromOpeningTillClosing.length
        ) {
          timeOptionsArray.push(...tenMinuteTimesFromNowTillClosing);
        } else {
          timeOptionsArray.push(...tenMinuteTimesFromOpeningTillClosing);
        }
      }

      return timeOptionsArray;
    }

    return [];
  }, [isRestaurantOpen, restaurant, selectedLocation]);

  const validateAddressInput = useCallback(async () => {
    if (restaurant) {
      const highwayRegex =
        /\b(I-\d+|Interstate \d+|Highway \d+|Hwy \d+|Route \d+)\b/i;

      if (!selectedAddressInModal) {
        setAddressTextError("Please enter a valid address.");
        return;
      }

      if (highwayRegex.test(selectedAddressInModal)) {
        setAddressTextError("Please enter a valid address.");
        return;
      }

      setIsLoading(true);

      const deliveryQuote = await calculateDeliveryQuote(
        restaurant.id,
        selectedAddressInModal,
        subtotal,
        selectedLocation.id,
      );

      if ("error" in deliveryQuote) {
        setAddressTextError("We are unable to deliver to this address.");
        logNotAbleToDeliverToAnalytics(customer?.id, selectedAddressInModal);
      } else {
        setAddressTextError(undefined);
        setSelectedAddress(selectedAddressInModal);
        setDeliveryInstructions(deliveryInstructionsInModal);
        setContactlessDelivery(contactlessDeliveryInModal);
        setIsAddressModalOpen(false);
        setDeliveryQuote(deliveryQuote);
      }

      setIsLoading(false);
    }
  }, [
    customer,
    selectedLocation,
    restaurant,
    selectedAddressInModal,
    deliveryInstructionsInModal,
    contactlessDeliveryInModal,
    subtotal,
  ]);

  if (!restaurant) {
    captureManualSentryException(
      new Error("restaurant is undefined in OrderDetailsOptions"),
    );
    return <div />;
  }

  return (
    <div className={styles.OrderDetailsOptions}>
      <h1 className={styles.title}>Order Details</h1>
      {selectedOrderType === ORDER_TYPE.DELIVERY && (
        <div className={styles.orderDetailOptionRow}>
          <FontAwesomeIcon className={styles.icon} icon={faLocationDot} />
          <button
            data-testid="address-button"
            className={classNames(styles.orderDetailsOptionButton, {
              [styles.italicText]: !selectedAddress,
            })}
            onClick={() => setIsAddressModalOpen(true)}
          >
            {selectedAddress || "Enter Address"}
          </button>
        </div>
      )}
      <div className={styles.orderDetailOptionRow}>
        <FontAwesomeIcon className={styles.icon} icon={faBagShopping} />
        <p className={styles.orderDetailsOptionName}>Order Type: </p>
        <button
          data-testid="order-type-button"
          className={styles.orderDetailsOptionButton}
          onClick={() => setIsOrderTypeModalOpen(true)}
        >
          {orderTypeLabelMap[selectedOrderType]}
        </button>
      </div>
      <div className={styles.orderDetailOptionRow}>
        <FontAwesomeIcon className={styles.icon} icon={faClock} />
        {!isNowWithinOpenHoursOfOperation(selectedLocation.hoursOfOperation) ? (
          <p
            className={styles.restaurantClosedText}
            data-testid="restaurant-closed-text"
          >{`${restaurant.restaurantName} is currently closed`}</p>
        ) : selectedLocation.hoursOfOperation.modeSelected ===
            HoursOfOperationMode.MANUALLY_CLOSED ||
          selectedLocation.hoursOfOperation.modeSelected ===
            HoursOfOperationMode.CLOSED_UNTIL_X ? (
          <p
            className={styles.restaurantClosedText}
            data-testid="restaurant-manually-closed-text"
          >{`${restaurant.restaurantName} is currently not accepting online orders`}</p>
        ) : (
          <>
            <p className={styles.orderDetailsOptionName}>
              {selectedOrderType === ORDER_TYPE.DELIVERY
                ? "Deliver At: "
                : "Ready By: "}
            </p>
            {restaurant.restaurantSettings.allowScheduledOrders ? (
              <button
                data-testid="pickup-time-button"
                className={styles.orderDetailsOptionButton}
                onClick={() => setIsTimeModalOpen(true)}
              >
                {timeText}
              </button>
            ) : (
              <p className={styles.orderDetailsOptionText}>{timeText}</p>
            )}
          </>
        )}
      </div>
      <Modal
        visible={isAddressModalOpen}
        width={isDesktop ? "40%" : "80%"}
        height="550"
        effect="fadeInUp"
        onClickAway={() => setIsAddressModalOpen(false)}
        className={styles.addressModal}
      >
        <div
          className={styles.addressModalContainer}
          data-testid="address-modal"
        >
          <h2 className={styles.enterAddressText}>
            Enter Delivery Information
          </h2>
          <p className={styles.noticeInstructions}>
            Don't enter names of hotel, malls, or other large buildings.
            Complete street address only.
          </p>
          <div className={styles.addressAutocomplete}>
            <TextInput
              className={styles.addressInput}
              testId="address-input"
              label="Address"
              placeholder="Apt #, Street, City, State, Zip..."
              value={addressTextInModal}
              onChangeText={(newText) => {
                setAddressTextError(undefined);
                setSelectedAddressInModal(undefined);
                setAddressTextInModal(newText);
                getPlacePredictions({
                  input: newText,
                  types: ["address"],
                  componentRestrictions: { country: "us" },
                });
              }}
              inputClassName={classNames({
                [styles.noBorderRadius]:
                  isPlacePredictionsLoading || showAddressSearchResults,
              })}
              errorMessage={addressTextError}
            />
            {isPlacePredictionsLoading && (
              <div className={styles.addressLoading}>
                <ReactLoading
                  color={design.buttonColor}
                  type="spin"
                  height={25}
                  width={25}
                />
              </div>
            )}
            {showAddressSearchResults && (
              <div className={styles.addressPredictions}>
                {placePredictions.map((prediction, index) => (
                  <button
                    key={index}
                    className={styles.addressPredictionButton}
                    onClick={() => {
                      setSelectedAddressInModal(prediction.description);
                      setAddressTextInModal(prediction.description);
                    }}
                    data-testid="address-autocomplete-option"
                  >
                    <p className={styles.addressPredictionText}>
                      {prediction.description}
                    </p>
                  </button>
                ))}
              </div>
            )}
          </div>
          <TextArea
            className={styles.deliveryInstructionsInput}
            testId="delivery-instructions-input"
            label="Delivery Instructions (Optional)"
            placeholder="Enter any special instructions for the driver like gate keys or building numbers..."
            onChangeText={setDeliveryInstructionsInModal}
            value={deliveryInstructionsInModal}
          />
          <div
            className={styles.contactlessDeliveryRow}
            onClick={() =>
              setContactlessDeliveryInModal(!contactlessDeliveryInModal)
            }
            data-testid="contactless-delivery-checkbox"
          >
            <Checkbox
              checked={contactlessDeliveryInModal}
              onChange={() =>
                setContactlessDeliveryInModal(!contactlessDeliveryInModal)
              }
            />
            <h4 className={styles.contactlessDeliveryText}>
              Contactless Delivery
            </h4>
          </div>
          {isLoading ? (
            <div className={styles.loadingContainer}>
              <ReactLoading
                color={design.buttonColor}
                type="spin"
                height={25}
                width={25}
              />
            </div>
          ) : (
            <div className={styles.buttonsContainer}>
              <Button
                secondary={true}
                className={styles.cancelButton}
                onClick={() => {
                  setIsAddressModalOpen(false);
                  syncModalStateWithProps();
                }}
                testId="cancel-button"
              >
                <h3 className={styles.buttonText}>Cancel</h3>
              </Button>
              <Button
                className={styles.saveButton}
                onClick={() => {
                  validateAddressInput();
                }}
                testId="save-button"
              >
                <h3 className={styles.buttonText}>Save</h3>
              </Button>
            </div>
          )}
        </div>
      </Modal>
      <Modal
        visible={isOrderTypeModalOpen}
        width="400"
        height="200"
        effect="fadeInUp"
        onClickAway={() => setIsOrderTypeModalOpen(false)}
      >
        <div
          className={styles.orderTypeModalContainer}
          data-testid="order-type-modal"
        >
          <SelectableButton
            testId="pickup-button"
            label="Pickup"
            isSelected={selectedOrderType === ORDER_TYPE.PICKUP}
            onClick={() => {
              updateOrderTypeAction(ORDER_TYPE.PICKUP)(dispatch);
              setIsOrderTypeModalOpen(false);
              logUpdateOrderTypeToAnalytics(
                customer?.id,
                ORDER_TYPE.PICKUP,
                "CART",
              );
            }}
            className={styles.selectableButton}
          />
          {restaurant.restaurantSettings.deliverySettings.enabled && (
            <SelectableButton
              testId="delivery-button"
              label="Delivery"
              isSelected={selectedOrderType === ORDER_TYPE.DELIVERY}
              onClick={() => {
                updateOrderTypeAction(ORDER_TYPE.DELIVERY)(dispatch);
                setIsOrderTypeModalOpen(false);
                logUpdateOrderTypeToAnalytics(
                  customer?.id,
                  ORDER_TYPE.DELIVERY,
                  "CART",
                );

                if (!selectedAddress) {
                  setTimeout(() => {
                    setIsAddressModalOpen(true);
                  }, 250);
                }
              }}
              className={styles.selectableButton}
            />
          )}
        </div>
      </Modal>
      <Modal
        visible={isTimeModalOpen}
        width="400"
        height="300"
        effect="fadeInUp"
        onClickAway={() => setIsTimeModalOpen(false)}
      >
        <div
          className={styles.orderPickupTimeModalContainer}
          data-testid="pickup-time-modal"
        >
          <SelectableButton
            testId="asap-option"
            label={
              selectedOrderType === ORDER_TYPE.DELIVERY
                ? "ASAP"
                : `ASAP (${restaurant.restaurantSettings.minPreparationTime} min)`
            }
            isSelected={time === undefined}
            onClick={() => {
              setTime(undefined);
              setIsTimeModalOpen(false);
            }}
            className={styles.selectableButton}
          />
          {timeOptions.map((timeOption, index) => {
            const timeOptionAsDate = convertAMPMTimeToDateObject(timeOption);

            return (
              <SelectableButton
                key={index}
                className={styles.selectableButton}
                testId={`time-option`}
                label={timeOption}
                isSelected={
                  time
                    ? timeOptionAsDate.getHours() === time.getHours() &&
                      timeOptionAsDate.getMinutes() === time.getMinutes()
                    : false
                }
                onClick={() => {
                  setTime(timeOptionAsDate);
                  setIsTimeModalOpen(false);
                }}
              />
            );
          })}
        </div>
      </Modal>
    </div>
  );
};
