import { useSelector } from "react-redux";
import { State } from "src/state/state";

export const useDesign = () => {
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );

  const defaultDesign = {
    backgroundColor: "#fff",
    cardColor: "#fff",
    textColor: "#000",
    buttonColor: "#2266a6",
    subtextColor: "#777",
    subBackgroundColor: "#d9d9d9",
    buttonTextColor: "#fff",
    errorColor: "#a62222",
    textFont: "Montserrat",
  };

  if (restaurant) {
    return {
      backgroundColor: restaurant.restaurantDesign.backgroundColor,
      cardColor: restaurant.restaurantDesign.cardColor,
      textColor: restaurant.restaurantDesign.textColor,
      buttonColor: restaurant.restaurantDesign.buttonColor,
      subtextColor: restaurant.restaurantDesign.subTextColor,
      subBackgroundColor: restaurant.restaurantDesign.subBackgroundColor,
      buttonTextColor: restaurant.restaurantDesign.buttonTextColor,
      errorColor: restaurant.restaurantDesign.errorColor,
      textFont: restaurant.restaurantDesign.textFont,
    };
  } else {
    return defaultDesign;
  }
};
