import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, Link } from "react-router-dom";
import styles from "src/pages/Item/styles.module.scss";
import { State } from "src/state/state";
import classNames from "classnames";
import { Button, QuantityPicker, Image, OptionPicker } from "src/components";
import { useCallback, useMemo, useState } from "react";
import { getCateringPath, getMenuPath } from "src/Router/routes";
import { CartItemOptionsSelected } from "src/state/cart/types";
import { addItemToCartAction } from "src/state/cart/actions";
import { addItemToScheduledCateringCartAction } from "src/state/scheduledCateringCart/actions";
import ReactLoading from "react-loading";
import { captureManualSentryException } from "src/common/sentry";
import {
  logAddToCartToAnalytics,
  logOneItemAddedToCartToAnalytics,
} from "src/common/analytics";
import { useScrollToTop, useDesign } from "src/common/hooks";
import { selectOptionsForItem } from "src/state/option/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

type ItemUrlParams = "categoryId" | "itemId";

export const Item = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useScrollToTop();
  const design = useDesign();
  const { categoryId, itemId } = useParams<ItemUrlParams>();

  const customer = useSelector(
    (state: State) => state.customers.currentCustomer,
  );
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const restaurantSettings = restaurant?.restaurantSettings;
  const allowScheduledCateringOrders =
    restaurantSettings?.allowScheduledCateringOrders;

  const category = useSelector(
    (state: State) =>
      categoryId &&
      restaurant &&
      state.categories[restaurant.id] &&
      state.categories[restaurant.id][categoryId],
  );
  const item = useSelector(
    (state: State) =>
      itemId &&
      categoryId &&
      state.items[categoryId] &&
      state.items[categoryId][itemId],
  );
  const itemOptions = useSelector(
    (state: State) => itemId && selectOptionsForItem(state, itemId),
  );
  const cartLength = useSelector(
    (state: State) => Object.values(state.cart).length,
  );
  const scheduledCateringCartLength = useSelector(
    (state: State) => Object.values(state.scheduledCateringCart).length,
  );

  const [quantity, setQuantity] = useState(1);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [optionsSelected, setOptionsSelected] =
    useState<CartItemOptionsSelected>({});

  const itemOptionsArray = useMemo(() => {
    if (!itemOptions) {
      return [];
    }

    const itemOptionsSortedArray = Object.values(itemOptions).sort(
      (optionA, _) => (optionA.minSelection > 0 ? -1 : 1),
    );

    return itemOptionsSortedArray;
  }, [itemOptions]);

  const itemTotalPrice = useMemo(() => {
    if (item) {
      return item.price * quantity;
    }

    return 0;
  }, [item, quantity]);

  const optionsTotalPrice = useMemo(() => {
    const optionsSelectedArray = Object.values(optionsSelected);

    let totalPrice = 0;

    for (const optionValues of optionsSelectedArray) {
      for (const optionValue of optionValues) {
        totalPrice += optionValue.price;
      }
    }

    return totalPrice * quantity;
  }, [optionsSelected, quantity]);

  const totalPrice = useMemo(() => {
    return itemTotalPrice + optionsTotalPrice;
  }, [itemTotalPrice, optionsTotalPrice]);

  const areAllRequiredOptionsSelected = useMemo(() => {
    const requiredOptions = itemOptionsArray.filter(
      (option) => option.minSelection > 0,
    );

    for (const requiredOption of requiredOptions) {
      if (
        !optionsSelected[requiredOption.id] ||
        optionsSelected[requiredOption.id].length < requiredOption.minSelection
      ) {
        return false;
      }
    }

    return true;
  }, [itemOptionsArray, optionsSelected]);

  const handleAddItemToCart = useCallback(async () => {
    setIsAddingToCart(true);
    setTimeout(async () => {
      if (itemId && item) {
        if (item.isCateringItem) {
          if (scheduledCateringCartLength === 0) {
            logOneItemAddedToCartToAnalytics(customer?.id);
          }

          await addItemToScheduledCateringCartAction(
            itemId,
            quantity,
            totalPrice,
          )(dispatch);

          navigate(getCateringPath());
        } else {
          if (cartLength === 0) {
            logOneItemAddedToCartToAnalytics(customer?.id);
          }

          await addItemToCartAction(
            itemId,
            quantity,
            optionsSelected,
            itemTotalPrice,
            totalPrice,
          )(dispatch);

          logAddToCartToAnalytics(
            itemId,
            quantity,
            totalPrice,
            undefined,
            customer?.id,
          );

          navigate(getMenuPath());
        }
      }

      setIsAddingToCart(false);
    }, 1000);
  }, [
    itemId,
    item,
    quantity,
    optionsSelected,
    itemTotalPrice,
    totalPrice,
    dispatch,
    cartLength,
    scheduledCateringCartLength,
    customer?.id,
    navigate,
  ]);

  if (!restaurant || !categoryId || !itemId) {
    captureManualSentryException(
      new Error(
        "restaurant, categoryId, itemId, itemOptions or category is not defined in Item",
      ),
    );
    return <div />;
  }

  if (!item || !category || !itemOptions) {
    return (
      <div
        className={styles.ItemNotFound}
        data-testid="item-not-found-container"
      >
        <h1 className={styles.errorMessage}>
          This item is no longer available.
        </h1>
        <Button
          data-testid="back-to-menu-button"
          secondary={true}
          className={styles.backToMenuButton}
          onClick={() => navigate(getMenuPath())}
        >
          <h2 className={styles.mainMenuText}>Main Menu</h2>
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.Item} data-testid={`item-container-${itemId}`}>
      {!restaurant.isOwnerManaged && (
        <div
          className={styles.goBackRow}
          onClick={() => navigate(getMenuPath())}
        >
          <FontAwesomeIcon className={styles.backButton} icon={faArrowLeft} />
          <p className={styles.backText}>View Menu</p>
        </div>
      )}
      <div className={styles.linksContainer}>
        <Link
          to={item.isCateringItem ? getCateringPath() : getMenuPath()}
          className={styles.link}
          data-testid="menu-link"
        >
          {item.isCateringItem ? "Catering Menu" : "Menu"}
        </Link>
        <h4 className={styles.linkSpacer}>/</h4>
        <h4
          className={classNames(styles.link, styles.blackLink)}
          data-testid="category-link"
        >
          {category.name}
        </h4>
        <h4 className={styles.linkSpacer}>/</h4>
        <h4 className={classNames(styles.link, styles.blackLink)}>
          {item.name}
        </h4>
      </div>
      <div className={styles.itemContainer}>
        <div className={styles.itemImageContainer}>
          {item.imageURL && (
            <Image
              src={item.imageURL}
              alt={item.name}
              className={classNames(styles.image, {
                [styles.logoImage]: item.imageURL === undefined,
              })}
            />
          )}
        </div>
        <div
          className={classNames(styles.itemDetailsContainer, {
            [styles.cateringItemContainer]: item.isCateringItem,
          })}
        >
          <div data-testid={"item-details"}>
            <h1 className={styles.itemName} data-testid="title">
              {item.name}
            </h1>
            {item.isCateringItem && (
              <h2 className={styles.cateringText} data-testid="catering-text">
                Catering Item
              </h2>
            )}
            {(!item.isCateringItem || item.price > 0) && (
              <h3
                className={styles.itemPrice}
                data-testid="price"
              >{`$${item.price.toFixed(2)}`}</h3>
            )}
            <p className={styles.itemDescription} data-testid="description">
              {item.description}
            </p>
            {restaurant.isOwnerManaged && (
              <div className={styles.quantityPriceTextContainer}>
                <QuantityPicker
                  className={styles.quantityPicker}
                  quantity={quantity}
                  onQuantityChange={setQuantity}
                />
                <h4
                  className={styles.quantityPriceText}
                  data-testid="item-price"
                >{`$${itemTotalPrice.toFixed(2)}`}</h4>
              </div>
            )}
            {Object.keys(optionsSelected).map((optionId, index) => {
              const option = itemOptions[optionId];
              const optionValues = optionsSelected[optionId];
              if (optionValues && optionValues.length > 0) {
                const optionValuesNames = optionValues
                  .map((optionValue) => optionValue.name)
                  .join(", ");
                const optionValuesTotalPrices = optionValues.reduce(
                  (totalPrice, optionValue) => totalPrice + optionValue.price,
                  0,
                );

                return (
                  <div
                    className={styles.quantityPriceTextContainer}
                    key={`${option.id}-${index}`}
                    data-testid={`option-values-for-${option.id}`}
                  >
                    <h4
                      className={styles.quantityPriceSubtext}
                      data-testid={`option-values-name`}
                    >
                      {`${option.name}: ${optionValuesNames}`}
                    </h4>
                    <h4
                      data-testid={`option-values-total-price`}
                      className={styles.quantityPriceSubtext}
                    >{`$${(optionValuesTotalPrices * quantity).toFixed(
                      2,
                    )}`}</h4>
                  </div>
                );
              }
              return null;
            })}
          </div>
          {item.isCateringItem && (
            <div className={styles.addToCartBanner}>
              <h1 className={styles.addToCartItemName}>{item.name}</h1>
              {isAddingToCart ? (
                <div className={styles.addToCartLoading}>
                  <ReactLoading
                    type="spin"
                    color={design.buttonColor}
                    height={40}
                    width={40}
                  />
                </div>
              ) : allowScheduledCateringOrders ? (
                <Button
                  testId="add-to-cart-button"
                  className={styles.addToCartButton}
                  disabled={!areAllRequiredOptionsSelected}
                  onClick={() => {
                    handleAddItemToCart();
                  }}
                >
                  <h3
                    data-testid="total-price"
                    className={styles.addToCartButtonText}
                  >
                    {`Add to Cart • $${totalPrice.toFixed(2)}`}
                  </h3>
                </Button>
              ) : (
                <Button
                  testId="contact-us-button"
                  className={styles.contactUsButton}
                  onClick={() => {
                    window.open(
                      `tel:${restaurant.contactPhoneNumber}`,
                      "_blank",
                    );
                  }}
                >
                  <h3
                    data-testid="contact-us-text"
                    className={styles.addToCartButtonText}
                  >
                    Contact Us
                  </h3>
                </Button>
              )}
            </div>
          )}
          {itemOptionsArray.map((option) => (
            <OptionPicker
              isEditable={restaurant.isOwnerManaged}
              option={option}
              key={option.id}
              className={
                option.maxSelection &&
                optionsSelected[option.id]?.length === option.maxSelection
                  ? styles.optionPickerSelectedMax
                  : ""
              }
              onOptionValuesSelected={(optionValues) => {
                const newOptionsSelected = { ...optionsSelected };
                newOptionsSelected[option.id] = optionValues;
                setOptionsSelected(newOptionsSelected);
              }}
            />
          ))}
          {!item.isCateringItem && (
            <div
              className={styles.quantityPriceContainer}
              data-testid="item-price-details"
            />
          )}
        </div>
      </div>
      {!item.isCateringItem && restaurant.isOwnerManaged && (
        <div className={styles.addToCartBanner}>
          <h1 className={styles.addToCartItemName}>{item.name}</h1>
          {isAddingToCart ? (
            <div className={styles.addToCartLoading}>
              <ReactLoading
                type="spin"
                color={design.buttonColor}
                height={40}
                width={40}
              />
            </div>
          ) : (
            <Button
              testId="add-to-cart-button"
              className={styles.addToCartButton}
              disabled={
                restaurant.restaurantSettings.isOnlineOrderingEnabled &&
                !areAllRequiredOptionsSelected
              }
              onClick={() => {
                if (restaurant.restaurantSettings.isOnlineOrderingEnabled) {
                  handleAddItemToCart();
                } else {
                  window.open(
                    `tel://${restaurant.contactPhoneNumber};`,
                    "_blank",
                  );
                }
              }}
            >
              <h3
                data-testid="total-price"
                className={styles.addToCartButtonText}
              >
                {restaurant.restaurantSettings.isOnlineOrderingEnabled
                  ? `Add to Cart • $${totalPrice.toFixed(2)}`
                  : "Call to Order"}
              </h3>
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
