// Util selectors for deal state
import { State } from "src/state/state";
import { DEAL_ENTITY_TYPE, DealState } from "src/state/deal/types";
import { CategoryState } from "src/state/category/types";
import { ItemState } from "src/state/item/types";
import { getAllItemsObjectFromState } from "src/state/item/utils";
import _ from "lodash";

export const selectDealsWithDisplayedItems = (
  state: State,
  displayedCategories: CategoryState,
  displayedItems: ItemState,
): DealState => {
  const restaurant = state.restaurants.currentRestaurant;

  if (!restaurant) {
    return {};
  }

  const restaurantId = restaurant.id;
  const itemsObject = getAllItemsObjectFromState(displayedItems);

  const finalDeals: DealState = _.cloneDeep(state.deals);

  for (const dealId in finalDeals[restaurantId]) {
    const deal = finalDeals[restaurantId][dealId];

    for (let i = 0; i < deal.entityIds.length; i++) {
      const entityId = deal.entityIds[i];
      const entityType = deal.entityTypes[i];

      if (entityType === DEAL_ENTITY_TYPE.ITEM) {
        if (!itemsObject[entityId]) {
          delete finalDeals[restaurantId][dealId];
          break;
        }
      }

      if (entityType === DEAL_ENTITY_TYPE.CATEGORY) {
        if (!displayedCategories[restaurantId][entityId]) {
          delete finalDeals[restaurantId][dealId];
          break;
        }
      }
    }
  }

  return finalDeals;
};
