// Order reducer
import {
  ORDER_ACTION,
  GetAllOrdersForCustomerAction,
  GetActiveOrdersForCustomerAction,
  GetSpecificOrderAction,
  GetRecentlyCancelledOrderForCustomerAction,
  GetRecentlyCompletedOrderForCustomerAction,
  ClearOrdersFromStateAction,
} from "src/state/order/actions";
import { OrderFragment, OrderState } from "src/state/order/types";

type OrderAction =
  | GetAllOrdersForCustomerAction
  | GetActiveOrdersForCustomerAction
  | GetSpecificOrderAction
  | GetRecentlyCancelledOrderForCustomerAction
  | GetRecentlyCompletedOrderForCustomerAction
  | ClearOrdersFromStateAction;

export const orderReducer = (state: OrderState = {}, action: OrderAction) => {
  switch (action.type) {
    case ORDER_ACTION.GET_ALL_ORDERS_FOR_CUSTOMER_ACTION: {
      const { restaurantId, response: orders } = action;

      const next: OrderState = {
        ...state,
        [restaurantId]: {
          ...(state[restaurantId] || {}),
          ...orders.reduce(
            (acc: { [orderId: string]: OrderFragment }, order) => {
              acc[order.id] = order;
              return acc;
            },
            {},
          ),
        },
      };

      return next;
    }
    case ORDER_ACTION.GET_SPECIFIC_ORDER_ACTION: {
      const { restaurantId, response: order } = action;

      const next: OrderState = {
        ...state,
        [restaurantId]: { ...(state[restaurantId] || {}), [order.id]: order },
      };

      return next;
    }
    case ORDER_ACTION.GET_ACTIVE_ORDERS_FOR_CUSTOMER_ACTION: {
      const { customerId, response: orders } = action;

      const next: OrderState = {
        ...state,
        [customerId]: {
          ...(state[customerId] || {}),
          ...orders.reduce(
            (acc: { [orderId: string]: OrderFragment }, order) => {
              acc[order.id] = order;
              return acc;
            },
            {},
          ),
        },
      };

      return next;
    }
    case ORDER_ACTION.GET_RECENTLY_CANCELLED_ORDER_FOR_CUSTOMER_ACTION: {
      const { response } = action;

      const next = { ...state };

      if (response.order) {
        const { restaurantId } = response.order;

        next[restaurantId] = {
          ...(next[restaurantId] || {}),
          [response.order.id]: response.order,
        };
      }

      return next;
    }
    case ORDER_ACTION.GET_RECENTLY_COMPLETED_ORDER_FOR_CUSTOMER_ACTION: {
      const { response } = action;

      const next = { ...state };

      if (response.order) {
        const { restaurantId } = response.order;

        next[restaurantId] = {
          ...(next[restaurantId] || {}),
          [response.order.id]: response.order,
        };
      }

      return next;
    }
    case ORDER_ACTION.CLEAR_ORDERS_FROM_STATE_ACTION: {
      const next = {};

      return next;
    }
    default: {
      return state;
    }
  }
};
