import { useSelector } from "react-redux";
import styles from "src/components/Header/NonOwnerManagedHeader/styles.module.scss";
import { Image } from "src/components/Image/Image";
import { RestaurantFragment } from "src/state/restaurant/types";
import { State } from "src/state/state";
import StarRatings from "react-star-ratings";
import { formatPhoneNumber } from "src/common/phone";
import { useMemo } from "react";
import classNames from "classnames";
import { getHomePath, getMenuPath } from "src/Router/routes";
import { useNavigate } from "react-router-dom";

export const NonOwnerManagedHeader = () => {
  const navigate = useNavigate();

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );

  const currentPathName = useMemo(() => location.pathname, [location.pathname]);

  return (
    <div className={styles.NonOwnerManagedHeader}>
      <Image
        src={require("../../../assets/BlueLogo.png")}
        alt="logo"
        className={styles.platterLogo}
      />
      <p className={styles.lastUpdatedText}>{`Last updated Nov 2024`}</p>
      <div className={styles.informationContainer}>
        <div className={styles.leftRestaurantInfo}>
          <div className={styles.nameReviewRow}>
            <h2 className={styles.restaurantNameText}>
              {restaurant.restaurantName}
            </h2>
            <StarRatings
              numberOfStars={5}
              rating={restaurant.avgGoogleReview}
              starDimension="25px"
              starSpacing="2px"
              starRatedColor="#FCB001"
            />
          </div>
          <p className={styles.aboutUs}>
            {restaurant.restaurantSettings.aboutUs}
          </p>
        </div>
        <div className={styles.rightRestaurantInfo}>
          <p className={styles.restaurantInfoSectionTitle}>Contact</p>
          <p
            className={styles.phoneNumber}
            onClick={() => {
              window.open(`tel:${restaurant.contactPhoneNumber}`, "_blank");
            }}
          >
            {formatPhoneNumber(restaurant.contactPhoneNumber)}
          </p>
          <p className={styles.restaurantInfoSectionTitle}>
            {`${restaurant.locations[0].city}, ${restaurant.locations[0].state}`}
          </p>
          <p className={styles.locationLine1}>
            {restaurant.locations[0].line1}
          </p>
          <p className={styles.locationCityStateZip}>
            {`${restaurant.locations[0].city}, ${restaurant.locations[0].state} ${restaurant.locations[0].postalCode}`}
          </p>
        </div>
      </div>
      <div className={styles.headerLinks}>
        <h3
          onClick={() => {
            navigate(getMenuPath());
          }}
          className={classNames(styles.headerLink, {
            [styles.activeLink]: currentPathName === getMenuPath(),
          })}
        >
          Menu
        </h3>
        <h3
          onClick={() => {
            navigate(getHomePath());
          }}
          className={classNames(styles.headerLink, {
            [styles.activeLink]: currentPathName === getHomePath(),
          })}
        >
          About
        </h3>
      </div>
    </div>
  );
};
