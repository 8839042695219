import classNames from "classnames";
import styles from "src/pages/Checkout/CheckoutOrderDetails/styles.module.scss";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "src/state/state";
import { CheckoutOrderDetailsOptions } from "src/pages/CateringCheckout/CheckoutOrderDetails/CheckoutOrderDetailsOptions/CheckoutOrderDetailsOptions";
import { ItemFragment } from "src/state/item/types";
import { Button } from "src/components";
import { useNavigate } from "react-router-dom";
import { getCateringPath } from "src/Router/routes";
import ReactLoading from "react-loading";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { CheckoutPriceDetails } from "src/pages/CateringCheckout/CheckoutOrderDetails/CheckoutPriceDetails/CheckoutPriceDetails";
import { ScheduledCateringCartItemFragment } from "src/state/scheduledCateringCart/types";
import { clearScheduledCateringCartAction } from "src/state/scheduledCateringCart/actions";
import { captureManualSentryException } from "src/common/sentry";
import { OrderDetailsCartItem } from "src/pages/CateringCheckout/CheckoutOrderDetails/OrderDetailsCartItem/OrderDetailsCartItem";
import { updateGuestCustomerName } from "src/common/order";
import { completeScheduledCateringCheckoutSessionAction } from "src/state/scheduledCateringCheckoutSession/actions";
import { getCurrentEnvironment } from "src/config/getConfig";
import { animateScroll as scroll } from "react-scroll";
import { useDesign } from "src/common/hooks";
import {
  logScheduledCateringCheckoutFailureToAnalytics,
  logScheduledCateringCheckoutInitiatedToAnalytics,
} from "src/common/analytics";

interface CheckoutOrderDetailsProps {
  className?: string;
  areFieldsValid: () => boolean;
  nameForTheOrder: string;
  phoneForTheOrder: string;
  emailForTheOrder: string;
}

export const CheckoutOrderDetails = ({
  className,
  areFieldsValid,
  nameForTheOrder,
  phoneForTheOrder,
  emailForTheOrder,
}: CheckoutOrderDetailsProps) => {
  const [total, setTotal] = useState(0);
  const design = useDesign();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const elements = useElements();
  const stripe = useStripe();

  const customer = useSelector(
    (state: State) => state.customers.currentCustomer,
  );
  const cart = useSelector((state: State) => state.scheduledCateringCart);
  const items = useSelector((state: State) => state.items);
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const checkoutSession = useSelector(
    (state: State) => state.scheduledCateringCheckoutSession,
  );

  const cartArray = useMemo(() => {
    return Object.values(cart);
  }, [cart]);

  const allItemsObject = useMemo(() => {
    let allItemsObject: { [itemId: string]: ItemFragment } = {};

    for (const categoryId in items) {
      const itemsInCategory = items[categoryId];
      allItemsObject = {
        ...allItemsObject,
        ...itemsInCategory,
      };
    }

    return allItemsObject;
  }, [items]);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<undefined | string>(
    undefined,
  );

  const handlePayment = useCallback(async () => {
    if (areFieldsValid() === false) {
      return;
    }
    setIsLoading(true);
    setErrorMessage(undefined);

    if (stripe && elements && restaurant) {
      let error;

      const isGuestCustomer = !customer;

      logScheduledCateringCheckoutInitiatedToAnalytics(
        customer?.id,
        cartArray.length,
        total,
        checkoutSession.order.eventTime,
        checkoutSession.order.eventLocation,
      );

      if (isGuestCustomer) {
        await updateGuestCustomerName(
          checkoutSession.order.guestCustomerId as string,
          nameForTheOrder,
          phoneForTheOrder,
          emailForTheOrder,
        );
      }

      if (total > 0) {
        const resultFromStripe = await stripe.confirmPayment({
          elements,
          redirect: "if_required",
          confirmParams: {
            return_url:
              getCurrentEnvironment() === "test"
                ? "https://joinplatter.com"
                : window.location.origin,
          },
        });

        error = resultFromStripe.error;
      }

      if (error) {
        const { message } = error;

        setErrorMessage(message);

        logScheduledCateringCheckoutFailureToAnalytics(customer?.id, message);
      } else {
        await completeScheduledCateringCheckoutSessionAction()(dispatch);
        await clearScheduledCateringCartAction()(dispatch);

        navigate(getCateringPath());
        scroll.scrollToTop();
      }
    }

    setIsLoading(false);
  }, [
    areFieldsValid,
    nameForTheOrder,
    stripe,
    elements,
    restaurant,
    customer,
    checkoutSession,
    cart,
    total,
    setIsLoading,
    dispatch,
    navigate,
  ]);

  if (!items || !restaurant) {
    captureManualSentryException(
      new Error("items or restaurant is undefined in CheckoutOrderDetails"),
    );
    return <div />;
  }

  return (
    <div
      className={classNames(styles.CheckoutOrderDetails, className)}
      data-testid="order-details"
    >
      <CheckoutOrderDetailsOptions />
      <div className={styles.cartItemsContainer}>
        {cartArray.map((cartItem) => {
          const scheduledCateringCartItem =
            cartItem as ScheduledCateringCartItemFragment;

          return (
            <OrderDetailsCartItem
              key={scheduledCateringCartItem.itemId}
              name={allItemsObject[scheduledCateringCartItem.itemId].name}
              totalPrice={scheduledCateringCartItem.totalPrice}
              testId={`cart-item-summary-${scheduledCateringCartItem.id}`}
            />
          );
        })}
      </div>
      {checkoutSession.order.additionalNotes && (
        <div className={styles.additionalNotesContainer}>
          <h3 className={styles.additionalNotesLabel}>Additional Notes</h3>
          <p
            className={styles.additionalNotesText}
            data-testid="additional-notes"
          >
            {checkoutSession.order.additionalNotes}
          </p>
        </div>
      )}
      <CheckoutPriceDetails
        onTotalCalculated={(calculatedTotal: number) => {
          setTotal(calculatedTotal);
        }}
      />
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <ReactLoading
            type="spin"
            color={design.buttonColor}
            height={40}
            width={40}
          />
        </div>
      ) : (
        <Button
          testId="pay-button"
          className={styles.payButton}
          onClick={handlePayment}
        >
          <h3 className={styles.payText}>Pay</h3>
        </Button>
      )}
      {errorMessage && (
        <p data-testid="error-message" className={styles.errorMessage}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};
