// Actions associated with RestaurantWideDiscount
import { Dispatch } from "redux";
import { RestaurantWideDiscountFragment } from "src/state/restaurantWideDiscount/types";

export enum RESTAURANT_WIDE_DISCOUNT_ACTION {
  SET_RESTAURANT_WIDE_DISCOUNT_FOR_RESTAURANT_ACTION = "SET_RESTAURANT_WIDE_DISCOUNT_FOR_RESTAURANT_ACTION",
}

export interface SetRestaurantWideDiscountForRestaurantAction {
  type: RESTAURANT_WIDE_DISCOUNT_ACTION.SET_RESTAURANT_WIDE_DISCOUNT_FOR_RESTAURANT_ACTION;
  restaurantId: string;
  response: {
    restaurantWideDiscount: RestaurantWideDiscountFragment | null;
  };
}

export const setRestaurantWideDiscountForRestaurantAction = (
  restaurantId: string,
  restaurantWideDiscount: RestaurantWideDiscountFragment | null,
) => {
  return async (
    dispatch: Dispatch<SetRestaurantWideDiscountForRestaurantAction>,
  ) => {
    const action = {
      type: RESTAURANT_WIDE_DISCOUNT_ACTION.SET_RESTAURANT_WIDE_DISCOUNT_FOR_RESTAURANT_ACTION,
      restaurantId,
      response: { restaurantWideDiscount },
    } as SetRestaurantWideDiscountForRestaurantAction;

    dispatch(action);

    return action;
  };
};
