import { post } from "src/api/server";

export const createOptOutForPhoneNumber = async (
  phoneNumber: string,
  restaurantId?: string,
): Promise<void> => {
  await post({
    service: "thanos",
    endpoint: "/opt_out_list/create_opt_out",
    params: {
      phoneNumber,
      restaurantId,
    },
  });
};
