import ReactDOM from "react-dom/client";
import "src/index.css";
import { App } from "src/App";
import reportWebVitals from "src/reportWebVitals";
import { getCurrentEnvironment } from "src/config/getConfig";
import * as Sentry from "@sentry/react";

if (getCurrentEnvironment() === "prod") {
  Sentry.init({
    dsn: "https://2720dd9f48a941e2663f016e438bb299@o4504812880789504.ingest.sentry.io/4506301452713984",
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
