// Utilities for interacting with AWS S3
import { getEnvVariable } from "src/config/getConfig";

export const getItemImageURLFromItemId = (itemId: string): string => {
  return `https://${getEnvVariable(
    "AWS_S3_BUCKET_NAME",
  )}.s3.amazonaws.com/item-images/${itemId}?${Date.now()}`;
};

export const getCategoryImageURLFromCategoryId = (
  categoryId: string,
): string => {
  return `https://${getEnvVariable(
    "AWS_S3_BUCKET_NAME",
  )}.s3.amazonaws.com/category-images/${categoryId}?${Date.now()}`;
};

export const getDownloadFromTheAppStoreImageURL = (): string => {
  return `https://${getEnvVariable(
    "AWS_S3_BUCKET_NAME",
  )}.s3.amazonaws.com/static-customer-web-images/DownloadOnTheAppStore.png`;
};

export const getGetItOnGooglePlayImageURL = (): string => {
  return `https://${getEnvVariable(
    "AWS_S3_BUCKET_NAME",
  )}.s3.amazonaws.com/static-customer-web-images/GetItOnGooglePlay.png`;
};
