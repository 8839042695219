import { useMemo } from "react";
import { getMenuPath } from "src/Router/routes";
import { Button, PageTitle } from "src/components";
import styles from "src/pages/Cart/styles.module.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { State } from "src/state/state";
import { CartItem } from "src/pages/Cart/CartItem/CartItem";
import { OrderDetails } from "src/pages/Cart/OrderDetails/OrderDetails";
import { CartDealFragment, CartItemFragment } from "src/state/cart/types";
import { isCartItemADeal } from "src/state/order/utils";
import { CartDeal } from "src/pages/Cart/CartDeal/CartDeal";
import { useScrollToTop } from "src/common/hooks";
import { CartUpsell } from "src/pages/Cart/CartUpsell/CartUpsell";

export const Cart = () => {
  useScrollToTop();

  const cart = useSelector((state: State) => state.cart);

  const cartArray = useMemo(() => {
    return Object.values(cart);
  }, [cart]);

  if (cartArray.length === 0) {
    return (
      <div className={styles.Cart} data-testid="cart-container">
        <PageTitle title="Cart" className={styles.title} />
        <div
          className={styles.emptyTitleContainer}
          data-testid="empty-cart-container"
        >
          <h2 className={styles.emptyMessage}>Your Cart is Empty</h2>
          <Link to={getMenuPath()} className={styles.orderNowLink}>
            <Button
              secondary={true}
              className={styles.orderNowButton}
              testId="empty-order-now-button"
            >
              <h2 className={styles.orderNowButtonText}>Order Now</h2>
            </Button>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.Cart} data-testid="cart-container">
      <div className={styles.cartContainer}>
        <div className={styles.cartItemsContainer}>
          <div className={styles.cartTitleRow}>
            <PageTitle title="Cart" className={styles.title} />
            <Link className={styles.addMoreItemsButtonLink} to={getMenuPath()}>
              <Button
                className={styles.addMoreItemsButton}
                testId="add-more-items-button"
              >
                <h3 className={styles.addMoreItemsText}>Add More Items</h3>
              </Button>
            </Link>
          </div>
          {cartArray.map((cartItemOrDeal) => {
            if (isCartItemADeal(cartItemOrDeal)) {
              return (
                <CartDeal
                  key={cartItemOrDeal.id}
                  cartDeal={cartItemOrDeal as CartDealFragment}
                  className={styles.cartItem}
                />
              );
            } else {
              return (
                <CartItem
                  key={cartItemOrDeal.id}
                  cartItem={cartItemOrDeal as CartItemFragment}
                  className={styles.cartItem}
                />
              );
            }
          })}
          <CartUpsell />
        </div>
        <OrderDetails className={styles.orderDetails} />
      </div>
    </div>
  );
};
