import styles from "src/pages/Menu/CategoriesSlider/styles.module.scss";
import {
  faChevronRight,
  faChevronLeft,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DealFragment } from "src/state/deal/types";
import { CategoryFragment } from "src/state/category/types";
import { useState } from "react";

interface ComponentProps {
  deals: DealFragment[];
  categoriesArray: CategoryFragment[];
  onSearchValueChange: (value: string) => void;
}

export const CategoriesSlider = ({
  deals,
  categoriesArray,
  onSearchValueChange,
}: ComponentProps) => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  return (
    <div className={styles.CategoriesSlider}>
      {isSearchVisible ? (
        <>
          <div
            className={styles.searchIcon}
            data-testid="search-icon"
            onClick={() => {
              setIsSearchVisible(false);
              setSearchValue("");
              onSearchValueChange("");
            }}
          >
            <FontAwesomeIcon
              size="1x"
              className={styles.icon}
              icon={faSearch}
            />
          </div>
          <div className={styles.searchContainer}>
            <input
              data-testid="search-input"
              type="text"
              placeholder="Search..."
              className={styles.searchInput}
              value={searchValue}
              autoFocus={true}
              onChange={(e) => {
                setSearchValue(e.target.value);
                onSearchValueChange(e.target.value);
              }}
            />
          </div>
        </>
      ) : (
        <>
          <div
            className={styles.arrowLeft}
            onClick={() => {
              const slider = document.getElementById("categories-slider");
              if (slider) {
                slider.scrollBy({
                  left: -400,
                  behavior: "smooth",
                });
              }
            }}
          >
            <FontAwesomeIcon
              size="xs"
              className={styles.icon}
              icon={faChevronLeft}
            />
          </div>
          <div
            data-testid="search-icon"
            className={styles.searchIcon}
            onClick={() => {
              setIsSearchVisible(true);
            }}
          >
            <FontAwesomeIcon
              size="1x"
              className={styles.icon}
              icon={faSearch}
            />
          </div>
          <div className={styles.sliderContainer} id="categories-slider">
            {deals.length > 0 && (
              <div
                className={styles.eachSlider}
                onClick={() =>
                  document
                    .getElementById("deals-container")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                <h5 className={styles.categoryName}>Deals</h5>
              </div>
            )}
            {categoriesArray.map((category) => {
              return (
                <div
                  className={styles.eachSlider}
                  key={category.id}
                  onClick={() => {
                    const categoryContainer = document.getElementById(
                      `${category.id}`,
                    );

                    if (categoryContainer) {
                      categoryContainer.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                >
                  <h5 className={styles.categoryName}>{category.name}</h5>
                </div>
              );
            })}
          </div>
          <div
            className={styles.arrowRight}
            onClick={() => {
              const slider = document.getElementById("categories-slider");
              if (slider) {
                slider.scrollBy({
                  left: 400,
                  behavior: "smooth",
                });
              }
            }}
          >
            <FontAwesomeIcon
              size="xs"
              className={styles.icon}
              icon={faChevronRight}
            />
          </div>
        </>
      )}
    </div>
  );
};
