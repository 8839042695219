// Utilities with phone number
import { PhoneNumberUtil } from "google-libphonenumber";

export const isValidPhoneNumber = (phoneNumber: string) => {
  try {
    if (/[a-zA-Z]/g.test(phoneNumber.trim())) {
      return false;
    }

    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneUtil.parse(phoneNumber.trim(), "US");

    return phoneUtil.isValidNumber(parsedNumber);
  } catch {
    return false;
  }
};

export const isValidEmailAddress = (emailAddress: string) => {
  if (/^\S+@\S+\.\S+$/.test(emailAddress.trim()) === false) {
    return false;
  }

  return true;
};

export const isValidPositiveNumber = (number: string) => {
  if (
    number.trim() === "" ||
    isNaN(parseFloat(number)) ||
    parseFloat(number) <= 0
  ) {
    return false;
  }

  return true;
};
