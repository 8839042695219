import classNames from "classnames";
import styles from "src/components/SelectableButton/styles.module.scss";

interface SelectableButtonProps {
  testId?: string;
  label: string;
  isSelected: boolean;
  onClick: () => void;
  className?: string;
}

export const SelectableButton = ({
  testId,
  label,
  isSelected,
  onClick,
  className,
}: SelectableButtonProps) => {
  return (
    <button
      data-testid={testId}
      onClick={onClick}
      className={classNames(
        styles.SelectableButton,
        isSelected && styles.selected,
        className,
      )}
    >
      {label}
    </button>
  );
};
