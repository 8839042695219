// Restaurant reducer
import {
  OPTION_ACTION,
  SetOptionsForRestaurantAction,
} from "src/state/option/actions";
import { OptionState } from "src/state/option/types";

type OptionAction = SetOptionsForRestaurantAction;

export const optionReducer = (
  state: OptionState = {},
  action: OptionAction,
) => {
  switch (action.type) {
    case OPTION_ACTION.SET_OPTIONS_FOR_RESTAURANT_ACTION: {
      const next: OptionState = { ...state };

      const { restaurantId, options } = action;

      next[restaurantId] = {
        ...(next[restaurantId] || {}),
      };

      options.forEach((option) => {
        if (option.isPublished) {
          next[restaurantId][option.id] = {
            ...option,
            optionValues: option.optionValues.filter(
              (optionValue) => optionValue.isPublished,
            ),
          };
        }
      });

      return next;
    }
    default: {
      return state;
    }
  }
};
