// Types associated with the Promo Code entity

export enum PROMO_CODE_TYPE {
  AMOUNT_OFF = "AMOUNT_OFF",
}

export enum PROMO_CODE_VALUE_TYPE {
  PERCENT = "PERCENT",
  DOLLAR = "DOLLAR",
}

export interface PromoCode {
  id: string;
  restaurantId: string;
  code: string;
  type: PROMO_CODE_TYPE;
  valueType: PROMO_CODE_VALUE_TYPE;
  value: number;
  minSize: number;
  isPlatterSponsored: boolean;
}
