// Component for the status bar
import classNames from "classnames";
import styles from "src/components/StatusBar/styles.module.scss";

interface ComponentProps {
  className?: string;
  // A number between 0-10,000
  indicatorPosition: number;
}

export const StatusBar = ({ className, indicatorPosition }: ComponentProps) => {
  return (
    <div className={classNames(styles.StatusBar, className)}>
      <div className={styles.linearGradient} />
      <div
        className={styles.primaryIndicator}
        style={{
          left: `calc(${
            indicatorPosition > 10000 ? 10000 : indicatorPosition / 100
          }% - 7.5px)`,
        }}
      />
      <div className={styles.labelsConatiner}>
        <p className={classNames(styles.label, styles.leftAlign)}>{`0`}</p>
        <p className={classNames(styles.label, styles.leftAlign)}>{`2500`}</p>
        <p className={styles.label}>{`5000`}</p>
        <p className={classNames(styles.label, styles.rightAlign)}>{`7500`}</p>
        <p className={classNames(styles.label, styles.rightAlign)}>{`10000`}</p>
      </div>
    </div>
  );
};
