// Utility function for dealing with the order state

import {
  CartDealFragment,
  CartItemFragment,
  CartItemOptionsSelected,
  CartState,
} from "src/state/cart/types";
import {
  CreateOrderItemAPIParams,
  CreateOrderItemOptionAPIParams,
} from "src/state/order/types";

export const isCartItemADeal = (
  cartItem: CartItemFragment | CartDealFragment,
): boolean => {
  return (cartItem as CartDealFragment).dealId !== undefined;
};

const createOrderItemOptionsFromCartItemOptions = (
  optionsSelected: CartItemOptionsSelected,
): CreateOrderItemOptionAPIParams[] => {
  return Object.keys(optionsSelected).map((optionId) => ({
    optionId,
    optionValueIds: optionsSelected[optionId].map(
      (optionValue) => optionValue.id,
    ),
  }));
};

const createOrderItemFromCartItem = (
  cartItem: CartItemFragment,
): CreateOrderItemAPIParams => {
  return {
    itemId: cartItem.itemId,
    quantity: cartItem.quantity,
    orderItemOptions: createOrderItemOptionsFromCartItemOptions(
      cartItem.optionsValuesSelectedForEachOption,
    ),
  };
};

const createOrderItemFromDeal = (
  cartDeal: CartDealFragment,
): CreateOrderItemAPIParams[] => {
  return cartDeal.itemIdsSelected.map((itemId, index) => ({
    itemId,
    quantity: 1,
    dealAppliedId: cartDeal.dealId,
    orderItemOptions: createOrderItemOptionsFromCartItemOptions(
      cartDeal.optionValuesSelected[index],
    ),
  }));
};

export const createOrderItemsFromCart = (
  cart: CartState,
): CreateOrderItemAPIParams[] => {
  const orderItems: CreateOrderItemAPIParams[] = [];

  const cartAsArray = Object.values(cart);

  for (const eachCartItemOrDeal of cartAsArray) {
    if (!isCartItemADeal(eachCartItemOrDeal)) {
      const cartItem = eachCartItemOrDeal as CartItemFragment;
      const orderItem = createOrderItemFromCartItem(cartItem);

      orderItems.push(orderItem);
    } else {
      const cartDeal = eachCartItemOrDeal as CartDealFragment;
      const orderItem = createOrderItemFromDeal(cartDeal);

      orderItems.push(...orderItem);
    }
  }

  return orderItems;
};
