// Deal reducer
import {
  DEAL_ACTION,
  SetDealsForRestaurantAction,
} from "src/state/deal/actions";
import { DealState } from "src/state/deal/types";

type DealAction = SetDealsForRestaurantAction;

export const dealReducer = (state: DealState = {}, action: DealAction) => {
  switch (action.type) {
    case DEAL_ACTION.SET_DEALS_FOR_RESTAURANT_ACTION: {
      const { response: deals, restaurantId } = action;

      const next: DealState = {
        ...state,
        [restaurantId]: { ...(state[restaurantId] || {}) },
      };

      deals.forEach((deal) => {
        next[restaurantId][deal.id] = deal;
      });

      return next;
    }

    default: {
      return state;
    }
  }
};
