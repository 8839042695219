import { useSelector } from "react-redux";
import { logUpdatedPromotionsConsentAtCheckoutToAnalytics } from "src/common/analytics";
import { Checkbox } from "src/components";
import styles from "src/pages/Checkout/PromotionCheckbox/styles.module.scss";
import { State } from "src/state/state";

export const PromotionCheckbox = ({
  isConsentingToPromotions,
  setIsConsentingToPromotions,
  uiVariant,
}) => {
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );

  return (
    <div
      className={styles.promotionCheckboxContainer}
      data-testid="promotion-checkbox-container"
    >
      <Checkbox
        testId="promotion-checkbox"
        className={styles.checkbox}
        checked={isConsentingToPromotions}
        onChange={() => {
          logUpdatedPromotionsConsentAtCheckoutToAnalytics(
            !isConsentingToPromotions,
            uiVariant,
          );
          setIsConsentingToPromotions(!isConsentingToPromotions);
        }}
        size="small"
      />
      <p
        className={styles.promotionCheckboxText}
        data-testId="promotion-checkbox-text"
      >
        {`Yes! Send me delicious deals from ${restaurant?.restaurantName} via text or email.`}
      </p>
    </div>
  );
};
