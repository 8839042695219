// Types related to the loyalty entity

// Loyalty Mode
export enum LOYALTY_TYPE {
  POINTS_SYSTEM = "POINTS_SYSTEM",
  NONE = "NONE",
}

export interface LoyaltyFragment {
  id: string;
  type: LOYALTY_TYPE;
  pointsSystemAmountSpend?: number | null;
  pointsSystemAmountEarn?: number | null;
}
