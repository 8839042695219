// Set of functions to interact and log to BigQuery
import { captureManualSentryException } from "src/common/sentry";
import { ORDER_TYPE } from "src/state/order/types";
import { isInDemoEnvironment } from "src/common/host";
import { post } from "src/api/server";
import { DEAL_TYPE } from "src/state/deal/types";
import { getClientConfig } from "src/state/clientConfig/utils";
import { CateringFormFieldsErrors } from "src/pages";
import { store } from "src/state/store";
import { UIVariants } from "src/state/globalUIVariant/types";
import { BotUserAgents } from "src/common/types/BotUserAgents";

export enum AnalyticsEvent {
  WEBSITE_VISIT = "WEBSITE_VISIT",
  ADD_TO_CART = "ADD_TO_CART",
  CHECKOUT_FAILURE = "CHECKOUT_FAILURE",
  CHECKOUT_INITIATED = "CHECKOUT_INITIATED",
  CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS",
  CREATE_ACCOUNT_FAILURE = "CREATE_ACCOUNT_FAILURE",
  CREATE_ACCOUNT_INITIATED = "CREATE_ACCOUNT_INITIATED",
  CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS",
  DELETE_ACCOUNT = "DELETE_ACCOUNT",
  FORGOT_PASSWORD_INITIATED = "FORGOT_PASSWORD_INITIATED",
  FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS",
  LOG_IN_FAILURE = "LOG_IN_FAILURE",
  LOG_IN_INITIATED = "LOG_IN_INITIATED",
  LOG_IN_SUCCESS = "LOG_IN_SUCCESS",
  POINTS_SELECTED_IN_CART = "POINTS_SELECTED_IN_CART",
  POINTS_TOOLTIP_CLICKED = "POINTS_TOOLTIP_CLICKED",
  CUSTOMIZE_ITEM = "CUSTOMIZE_ITEM",
  SIGN_OUT = "SIGN_OUT",
  UPDATE_PERSONAL_INFO = "UPDATE_PERSONAL_INFO",
  CATERING_CONTACT_CLICKED = "CATERING_CONTACT_CLICKED",
  CATERING_FORM_SUBMITTED = "CATERING_FORM_SUBMITTED",
  CATERING_FORM_SUBMIT_ERROR = "CATERING_FORM_SUBMIT_ERROR",
  FOOTER_APP_LINK_CLICKED = "FOOTER_APP_LINK_CLICKED",
  FOOTER_CONTACT_LINK_CLICKED = "FOOTER_CONTACT_LINK_CLICKED",
  FOOTER_PLATTER_LINK_CLICKED = "FOOTER_PLATTER_LINK_CLICKED",
  TAXES_AND_FEES_TOOLTIP_CLICKED = "TAXES_AND_FEES_TOOLTIP_CLICKED",
  VIEW_CART_CLICKED = "VIEW_CART_CLICKED",
  CHECKOUT_FROM_CART_CLICKED = "CHECKOUT_FROM_CART_CLICKED",
  CHECKOUT_FROM_CART_ADDRESS_FAILURE = "CHECKOUT_FROM_CART_ADDRESS_FAILURE",
  FOOTER_SOCIAL_MEDIA_LINK_CLICKED = "FOOTER_SOCIAL_MEDIA_LINK_CLICKED",
  ONE_ITEM_ADDED_TO_CART = "ONE_ITEM_ADDED_TO_CART",
  VIEW_RECEIPT_CLICKED = "VIEW_RECEIPT_CLICKED",
  VIEW_ITEM_CLICKED = "VIEW_ITEM_CLICKED",
  NOT_ABLE_TO_DELIVER = "NOT_ABLE_TO_DELIVER",
  UPDATE_ORDER_TYPE = "UPDATE_ORDER_TYPE",
  DELIVERY_TRACKING_CLICKED = "DELIVERY_TRACKING_CLICKED",
  MENU_ORDER_NOW_CLICKED = "MENU_ORDER_NOW_CLICKED",
  SELECT_LOCATION = "SELECT_LOCATION",
  CATERING_CHECKOUT_INITIATED = "CATERING_CHECKOUT_INITIATED",
  CATERING_CHECKOUT_SUCCESS = "CATERING_CHECKOUT_SUCCESS",
  CATERING_CHECKOUT_FAILURE = "CATERING_CHECKOUT_FAILURE",
  SCHEDULED_CATERING_CHECKOUT_INITIATED = "SCHEDULED_CATERING_CHECKOUT_INITIATED",
  SCHEDULED_CATERING_CHECKOUT_SUCCESS = "SCHEDULED_CATERING_CHECKOUT_SUCCESS",
  SCHEDULED_CATERING_CHECKOUT_FAILURE = "SCHEDULED_CATERING_CHECKOUT_FAILURE",
  CLICK_MENU_DISCLAIMER = "CLICK_MENU_DISCLAIMER",
  CLICK_HOME_DISCLAIMER = "CLICK_HOME_DISCLAIMER",
  CLICK_FOOTER_DISCLAIMER = "CLICK_FOOTER_DISCLAIMER",
  CLICK_CHECKOUT_DISCLAIMER = "CLICK_CHECKOUT_DISCLAIMER",
  INVALID_ORDER_INTENT = "INVALID_ORDER_INTENT",
  ADD_TO_CART_FROM_UPSELL = "ADD_TO_CART_FROM_UPSELL",
  ORDER_NOW_CLICKED_FROM_HOME = "ORDER_NOW_CLICKED_FROM_HOME",
  GET_DIRECTIONS_CLICKED = "GET_DIRECTIONS_CLICKED",
  LOCATION_CLICKED_FROM_HOME = "LOCATION_CLICKED_FROM_HOME",
  PHONE_CLICKED_FROM_HOME = "PHONE_CLICKED_FROM_HOME",
  SOCIAL_MEDIA_CLICKED_FROM_HOME = "SOCIAL_MEDIA_CLICKED_FROM_HOME",
  ITEM_CLICKED_FROM_HOME = "ITEM_CLICKED_FROM_HOME",
  UPDATED_PROMOTIONS_CONSENT_AT_CHECKOUT = "UPDATED_PROMOTIONS_CONSENT_AT_CHECKOUT",
  UPDATED_PROMOTIONS_CONSENT_AT_CHECKOUT_SHOWN = "UPDATED_PROMOTIONS_CONSENT_AT_CHECKOUT_SHOWN",
  PROMO_CODE_APPLED = "PROMO_CODE_APPLED",
  PROMO_CODE_FAILED = "PROMO_CODE_FAILED",
  FOOTER_GET_DIRECTIONS_CLICKED = "FOOTER_GET_DIRECTIONS_CLICKED",
  FOOTER_ORDER_ONLINE_CLICKED = "FOOTER_ORDER_ONLINE_CLICKED",
}

export const createAnalyticsEventInBigQuery = async (
  event: AnalyticsEvent,
  params: Record<string, unknown>,
  userId?: string,
) => {
  try {
    // Skip if in demo environment
    if (isInDemoEnvironment()) {
      return;
    }
    const userAgent = navigator.userAgent;
    const isBot = BotUserAgents.some((bot) => userAgent.includes(bot));
    if (isBot) {
      return;
    }

    const restaurantId = await getClientConfig("restaurantId");
    const restaurantName = await getClientConfig("name");

    const reduxState = store.getState();

    const sessionId = reduxState.session.sessionId as string;
    const isOwnerManaged = reduxState.restaurants.currentRestaurant
      ?.isOwnerManaged as boolean;

    const urlParams = new URLSearchParams(window.location.search);
    const promoId = urlParams.get("promoId") || undefined;
    const promoSource = urlParams.get("promoSource") || undefined;

    const paramsToSend = {
      ...params,
      promoId,
      promoSource,
      restaurantId,
      restaurantName,
      isOwnerManaged,
    };

    await post({
      service: "eugene",
      endpoint: "/analytics/create",
      params: {
        analyticsEvent: {
          event,
          params: paramsToSend,
          user_id: userId,
          product: "customer_website",
          sessionId,
        },
      },
    });
  } catch (e) {
    captureManualSentryException(e as Error);
  }
};

export const logWebsiteVisitToAnalytics = (isMobile: boolean) => {
  createAnalyticsEventInBigQuery(AnalyticsEvent.WEBSITE_VISIT, {
    isMobile,
  });
};

export const logAddToCartToAnalytics = (
  itemId: string | undefined,
  quantity: number,
  totalPrice: number,
  dealType?: DEAL_TYPE,
  userId?: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.ADD_TO_CART,
    {
      itemId,
      quantity,
      totalPrice,
      dealType,
    },
    userId,
  );
};

export const logScheduledCateringCheckoutFailureToAnalytics = (
  userId: string | undefined,
  failureReason: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.SCHEDULED_CATERING_CHECKOUT_FAILURE,
    {
      failureReason,
    },
    userId,
  );
};

export const logCheckoutFailureToAnalytics = (
  userId: string | undefined,
  failureReason: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.CHECKOUT_FAILURE,
    {
      failureReason,
    },
    userId,
  );
};

export const logCheckoutInitiatedToAnalytics = (
  userId: string | undefined,
  orderType: ORDER_TYPE,
  time: "ASAP" | "LATER",
  numCartItems: number,
  totalPrice: number,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.CHECKOUT_INITIATED,
    {
      numCartItems,
      totalPrice,
      time,
      orderType,
      isGuestCheckout: userId === undefined,
    },
    userId,
  );
};

export const logScheduledCateringCheckoutInitiatedToAnalytics = (
  userId: string | undefined,
  numCartItems: number,
  totalPrice: number,
  eventTime: string,
  eventLocation: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.SCHEDULED_CATERING_CHECKOUT_INITIATED,
    { numCartItems, totalPrice, eventTime, eventLocation },
    userId,
  );
};

export const logCheckoutSuccessToAnalytics = (
  userId: string | undefined,
  orderType: ORDER_TYPE,
  time: "ASAP" | "LATER",
  numCartItems: number,
  totalPrice: number,
  additionalNotesAdded: boolean,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.CHECKOUT_SUCCESS,
    {
      numCartItems,
      totalPrice,
      time,
      orderType,
      additionalNotesAdded,
      isGuestCheckout: userId === undefined,
    },
    userId,
  );
};

export const logScheduledCateringCheckoutSuccessToAnalytics = (
  userId: string | undefined,
  numCartItems: number,
  totalPrice: number,
  eventTime: string,
  eventLocation: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.SCHEDULED_CATERING_CHECKOUT_SUCCESS,
    { numCartItems, totalPrice, eventTime, eventLocation },
    userId,
  );
};

export type CreateAccountFailureReason =
  | "FIELDS_MISSING"
  | "NON_MATCHING_PASSWORDS"
  | "NOT_ACCEPTED_TERMS"
  | "INVALID_PHONE_NUMBER"
  | "INVALID_EMAIL"
  | "SHORT_PASSWORD"
  | "EMAIL_ALREADY_EXISTS"
  | "UNKNOWN_ERROR";

export const logCreateAccountFailureToAnalytics = (
  failureReason: CreateAccountFailureReason,
  otherReason?: string,
) => {
  createAnalyticsEventInBigQuery(AnalyticsEvent.CREATE_ACCOUNT_FAILURE, {
    failureReason,
    otherReason,
  });
};

export const logCreateAccountInitiatedToAnalytics = (
  isFromCheckout: boolean,
) => {
  createAnalyticsEventInBigQuery(AnalyticsEvent.CREATE_ACCOUNT_INITIATED, {
    isFromCheckout,
  });
};

export const logCreateAccountSuccessToAnalytics = (
  userId: string,
  isFromCheckout: boolean,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.CREATE_ACCOUNT_SUCCESS,
    { isFromCheckout },
    userId,
  );
};

export const logDeleteAccountToAnalytics = (userId: string) => {
  createAnalyticsEventInBigQuery(AnalyticsEvent.DELETE_ACCOUNT, {}, userId);
};

export type LogInFailureReason =
  | "FIELDS_MISSING"
  | "INVALID_EMAIL"
  | "INCORRECT_EMAIL_OR_PASSWORD"
  | "UNKNOWN_ERROR";

export const logLogInFailureToAnalytics = (
  failureReason: LogInFailureReason,
  otherError?: string,
) => {
  createAnalyticsEventInBigQuery(AnalyticsEvent.LOG_IN_FAILURE, {
    failureReason,
    otherError,
  });
};

export const logLogInInitiatedToAnalytics = () => {
  createAnalyticsEventInBigQuery(AnalyticsEvent.LOG_IN_INITIATED, {});
};

export const logLogInSuccessToAnalytics = (userId: string) => {
  createAnalyticsEventInBigQuery(AnalyticsEvent.LOG_IN_SUCCESS, {}, userId);
};

export const logForgotPasswordInitiated = () => {
  createAnalyticsEventInBigQuery(AnalyticsEvent.FORGOT_PASSWORD_INITIATED, {});
};

export const logForgotPasswordSuccess = () => {
  createAnalyticsEventInBigQuery(AnalyticsEvent.FORGOT_PASSWORD_SUCCESS, {});
};

export const logPointsSelectedInCartToAnalytics = (
  userId: string,
  points: number,
  totalPriceBeforePoints: number,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.POINTS_SELECTED_IN_CART,
    {
      points,
      totalPriceBeforePoints,
    },
    userId,
  );
};

export const logPointsTooltipClickedToAnalytics = (userId: string) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.POINTS_TOOLTIP_CLICKED,
    {},
    userId,
  );
};

export const logCustomizeItemToAnalytics = (
  itemId: string,
  userId?: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.CUSTOMIZE_ITEM,
    {
      itemId,
    },
    userId,
  );
};

export const logSignOutToAnalytics = (userId: string) => {
  createAnalyticsEventInBigQuery(AnalyticsEvent.SIGN_OUT, {}, userId);
};

export const logUpdatePersonalInfoToAnalytics = (
  userId: string,
  firstNameUpdated: boolean,
  lastNameUpdated: boolean,
  emailUpdated: boolean,
  phoneUpdated: boolean,
) => {
  // No  here on purpose as analytics should not block any user interaction
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.UPDATE_PERSONAL_INFO,
    {
      firstNameUpdated,
      lastNameUpdated,
      emailUpdated,
      phoneUpdated,
    },
    userId,
  );
};

export const logCateringContactClickedToAnalytics = (
  cateringItemId: string,
  userId?: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.CATERING_CONTACT_CLICKED,
    {
      cateringItemId,
    },
    userId,
  );
};

export const logCateringFormSubmittedToAnalytics = (
  name: string,
  email: string,
  phone: string,
  estimatedGuestCount: string,
  estimatedBudget: string,
  eventDate: string,
  eventLocation: string,
  zipCode: string,
  notes: string,
  companyName: string,
  userId?: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.CATERING_FORM_SUBMITTED,
    {
      name,
      email,
      phone,
      estimatedGuestCount,
      estimatedBudget,
      eventDate,
      eventLocation,
      zipCode,
      notes,
      companyName,
    },
    userId,
  );
};

export const logCateringFormSubmitErrorToAnalytics = (
  errorMessages: CateringFormFieldsErrors,
  userId?: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.CATERING_FORM_SUBMIT_ERROR,
    {
      errorMessages,
    },
    userId,
  );
};

export const logFooterAppLinkClickedToAnalytics = (
  link: "app_store" | "google_play",
  userId?: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.FOOTER_APP_LINK_CLICKED,
    { link },
    userId,
  );
};

export const logFooterContactLinkClickedToAnalytics = (
  contactMethod: "phone" | "email",
  userId?: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.FOOTER_CONTACT_LINK_CLICKED,
    {
      contactMethod,
    },
    userId,
  );
};

export const logFooterPlatterLinkClickedToAnalytics = (userId?: string) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.FOOTER_PLATTER_LINK_CLICKED,
    {},
    userId,
  );
};

export const logFooterSocialMediaLinkClickedToAnalytics = (
  userId: string | undefined,
  socialMedia: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.FOOTER_SOCIAL_MEDIA_LINK_CLICKED,
    {
      socialMedia,
    },
    userId,
  );
};

export const logTaxesAndFeesToolTipClickedToAnalytics = (
  userId: string | undefined,
  orderType: ORDER_TYPE,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.TAXES_AND_FEES_TOOLTIP_CLICKED,
    {
      orderType,
    },
    userId,
  );
};

export const logTaxesAndFeesToolTipClickedToAnalyticsForCatering = (
  userId: string | undefined,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.TAXES_AND_FEES_TOOLTIP_CLICKED,
    {
      orderType: "CATERING",
    },
    userId,
  );
};

export const logViewCartClickedToAnalytics = (
  userId: string | undefined,
  numCartItems: number,
  cartTotal: number,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.VIEW_CART_CLICKED,
    {
      numCartItems,
      cartTotal,
    },
    userId,
  );
};

export const logCheckoutFromCartClickedToAnalytics = (
  userId: string | undefined,
  numCartItems: number,
  cartTotal: number,
  orderType: ORDER_TYPE,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.CHECKOUT_FROM_CART_CLICKED,
    {
      numCartItems,
      cartTotal,
      orderType,
    },
    userId,
  );
};

export const logCheckoutFromCartAddressFailureToAnalytics = (
  userId: string | undefined,
  failureReason: string,
  additionalParams: Record<string, unknown>,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.CHECKOUT_FROM_CART_ADDRESS_FAILURE,
    {
      failureReason,
      additionalParams: JSON.stringify(additionalParams),
    },
    userId,
  );
};

export const logOneItemAddedToCartToAnalytics = (
  userId: string | undefined,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.ONE_ITEM_ADDED_TO_CART,
    {},
    userId,
  );
};

export const logViewReceiptClickedToAnalytics = (
  userId: string | undefined,
  orderId: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.VIEW_RECEIPT_CLICKED,
    {
      orderId,
    },
    userId,
  );
};

export const logViewItemClickedToAnalytics = (
  userId: string | undefined,
  itemId: string,
  itemName: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.VIEW_ITEM_CLICKED,
    { itemId, itemName },
    userId,
  );
};

export const logNotAbleToDeliverToAnalytics = (
  userId: string | undefined,
  address: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.NOT_ABLE_TO_DELIVER,
    { address },
    userId,
  );
};

export const logUpdateOrderTypeToAnalytics = (
  userId: string | undefined,
  orderType: ORDER_TYPE,
  source: "MENU" | "CART",
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.UPDATE_ORDER_TYPE,
    { orderType, source },
    userId,
  );
};

export const logDeliveryTrackingClickedToAnalytics = (
  userId: string | undefined,
  orderId: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.DELIVERY_TRACKING_CLICKED,
    { orderId },
    userId,
  );
};

export const logMenuOrderNowClickedToAnalytics = (
  userId: string | undefined,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.MENU_ORDER_NOW_CLICKED,
    {},
    userId,
  );
};

export const logSelectLocationToAnalytics = (
  userId: string | undefined,
  locationId: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.SELECT_LOCATION,
    { locationId },
    userId,
  );
};

export const logCateringCheckoutInitiatedToAnalytics = (
  userId: string | undefined,
  cateringRequestId: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.CATERING_CHECKOUT_INITIATED,
    { cateringRequestId },
    userId,
  );
};

export const logCateringCheckoutSuccessToAnalytics = (
  userId: string | undefined,
  cateringRequestId: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.CATERING_CHECKOUT_SUCCESS,
    { cateringRequestId },
    userId,
  );
};

export const logCateringCheckoutFailureToAnalytics = (
  userId: string | undefined,
  cateringRequestId: string,
  failureReason: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.CATERING_CHECKOUT_FAILURE,
    { cateringRequestId, failureReason },
    userId,
  );
};

export const logClickFooterDisclaimerToAnalytics = (
  userId: string | undefined,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.CLICK_FOOTER_DISCLAIMER,
    {},
    userId,
  );
};

export const logClickCheckoutDisclaimerToAnalytics = (
  userId: string | undefined,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.CLICK_CHECKOUT_DISCLAIMER,
    {},
    userId,
  );
};

export const logInvalidOrderIntentToAnalytics = (
  userId: string | undefined,
  invalidOrderIntentReason: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.INVALID_ORDER_INTENT,
    { invalidOrderIntentReason },
    userId,
  );
};

export const logAddToCartFromUpsellToAnalytics = (
  itemId: string | undefined,
  itemPrice: number,
  userId?: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.ADD_TO_CART_FROM_UPSELL,
    {
      itemId,
      itemPrice,
    },
    userId,
  );
};

export type OrderNowButtonLocation =
  | "HOME_COVER_PHOTO"
  | "HOME_ABOUT_US"
  | "HOME_POPULAR_ITEMS"
  | "HOME_REVIEW_SECTION";

export const logOrderNowClickedFromHomeToAnalytics = (
  userId: string | undefined,
  buttonLocation: OrderNowButtonLocation,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.ORDER_NOW_CLICKED_FROM_HOME,
    { buttonLocation },
    userId,
  );
};

export const logGetDirectionsClickedToAnalytics = (
  userId: string | undefined,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.GET_DIRECTIONS_CLICKED,
    {},
    userId,
  );
};

export const logLocationClickedFromHomeToAnalytics = (
  userId: string | undefined,
  locationId: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.LOCATION_CLICKED_FROM_HOME,
    {
      locationId,
    },
    userId,
  );
};

export const logPhoneClickedFromHomeToAnalytics = (
  userId: string | undefined,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.PHONE_CLICKED_FROM_HOME,
    {},
    userId,
  );
};

export const logSocialMediaClickedFromHomeToAnalytics = (
  userId: string | undefined,
  socialMedia: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.SOCIAL_MEDIA_CLICKED_FROM_HOME,
    {
      socialMedia,
    },
    userId,
  );
};

export const logItemClickedFromHomeToAnalytics = (
  userId: string | undefined,
  itemId: string,
  itemName: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.ITEM_CLICKED_FROM_HOME,
    {
      itemId,
      itemName,
    },
    userId,
  );
};

export const logUpdatedPromotionsConsentAtCheckoutToAnalytics = (
  isConsentingToPromotions: boolean,
  uiVariant?: UIVariants,
  userId?: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.UPDATED_PROMOTIONS_CONSENT_AT_CHECKOUT,
    {
      isConsentingToPromotions,
      uiVariant,
    },
    userId,
  );
};

export const logUpdatedPromotionsConsentAtCheckoutShownToAnalytics = (
  uiVariant: UIVariants,
  userId?: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.UPDATED_PROMOTIONS_CONSENT_AT_CHECKOUT_SHOWN,
    {
      uiVariant,
    },
    userId,
  );
};

export const logPromoCodeAppliedToAnalytics = (
  userId: string | undefined,
  promoCodeId: string,
  code: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.PROMO_CODE_APPLED,
    {
      promoCodeId,
      code,
    },
    userId,
  );
};

export const logPromoCodeFailedToAnalytics = (
  userId: string | undefined,
  code: string,
  failureReason: "INVALID_CODE" | "CART_TOO_SMALL" | "DELIVERY_NOT_ALLOWED",
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.PROMO_CODE_FAILED,
    {
      code,
      failureReason,
    },
    userId,
  );
};

export const logFooterGetDirectionsClicked = () => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.FOOTER_GET_DIRECTIONS_CLICKED,
    {},
  );
};

export const logFooterOrderOnlineClicked = (link: string) => {
  createAnalyticsEventInBigQuery(AnalyticsEvent.FOOTER_ORDER_ONLINE_CLICKED, {
    link,
  });
};
