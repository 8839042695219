import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TextInput, Button, Image } from "src/components";
import styles from "src/pages/ResetPassword/styles.module.scss";
import { State } from "src/state/state";
import { getSignInPath } from "src/Router/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import ReactLoading from "react-loading";
import { captureManualSentryException } from "src/common/sentry";
import { confirmForgotPasswordInAWSCognito } from "src/common/auth";
import { logForgotPasswordSuccess } from "src/common/analytics";
import { useScrollToTop, useDesign } from "src/common/hooks";
interface ResetPasswordFieldsErrors {
  verificationCode: string | undefined;
  newPassword: string | undefined;
  confirmNewPassword: string | undefined;
}

export const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const design = useDesign();
  useScrollToTop();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const redirectUrl = searchParams.get("redirect");

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );

  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorBox, setErrorBox] = useState<string | undefined>(undefined);

  const [errorMessages, setErrorMessages] = useState<ResetPasswordFieldsErrors>(
    {
      verificationCode: undefined,
      newPassword: undefined,
      confirmNewPassword: undefined,
    },
  );

  const areFieldsValid = useCallback(() => {
    const newErrorMessages = {
      ...errorMessages,
    };

    if (verificationCode.trim() === "") {
      newErrorMessages.verificationCode = "Please enter a verification code.";
    }

    if (newPassword.trim() === "") {
      newErrorMessages.newPassword = "Please enter a new password.";
    }

    if (confirmNewPassword.trim() === "") {
      newErrorMessages.confirmNewPassword = "Please confirm your new password.";
    }

    if (
      confirmNewPassword.trim() !== "" &&
      newPassword !== confirmNewPassword
    ) {
      newErrorMessages.confirmNewPassword = "Passwords do not match.";
    }

    if (newPassword.trim() !== "" && newPassword.trim().length < 8) {
      newErrorMessages.newPassword = "Password must be at least 8 characters.";
    }

    for (const key in newErrorMessages) {
      if (newErrorMessages[key] !== undefined) {
        setErrorMessages(newErrorMessages);
        return false;
      }
    }

    return true;
  }, [verificationCode, newPassword, confirmNewPassword, errorMessages]);

  const handleResetPassword = useCallback(async () => {
    if (restaurant && email) {
      try {
        setIsLoading(true);

        await confirmForgotPasswordInAWSCognito(
          email,
          verificationCode,
          newPassword,
        );
        logForgotPasswordSuccess();

        setIsLoading(false);

        if (redirectUrl) {
          navigate(getSignInPath(redirectUrl));
        } else {
          navigate(getSignInPath());
        }
      } catch (e) {
        setIsLoading(false);
        const errorMessage = typeof e === "string" ? e : (e as Error).message;

        if (
          errorMessage ===
          "Invalid verification code provided, please try again."
        ) {
          setErrorBox(errorMessage);
          return;
        }

        captureManualSentryException(e as Error);
        setErrorBox("Something went wrong. Please try again.");
      }
    }
  }, [
    email,
    restaurant,
    verificationCode,
    newPassword,
    confirmNewPassword,
    redirectUrl,
    dispatch,
    navigate,
  ]);

  if (!restaurant || !email) {
    captureManualSentryException(
      new Error("restaurant or email is not defined in ResetPassword"),
    );

    return <div />;
  }

  return (
    <div
      className={styles.ResetPassword}
      data-testid="reset-password-container"
    >
      {restaurant.isOwnerManaged ? (
        <Image src={restaurant.logoUrl} alt="logo" className={styles.logo} />
      ) : (
        <div className={styles.logo} />
      )}
      <h1 className={styles.headingText}>{`Reset Password`}</h1>
      <p className={styles.resetPasswordMessage}>
        If an account with this email exists, we've sent you a verification
        code. Please enter it below.
      </p>
      <TextInput
        className={styles.input}
        testId="verification-code-input"
        label="Verification Code"
        type="text"
        value={verificationCode}
        onChangeText={(newText) => {
          setVerificationCode(newText);
          setErrorMessages({
            ...errorMessages,
            verificationCode: undefined,
          });
        }}
        placeholder="Enter the verification code..."
        errorMessage={errorMessages.verificationCode}
      />
      <TextInput
        className={styles.input}
        testId="new-password-input"
        label="New Password"
        type="password"
        autoComplete="new-password"
        value={newPassword}
        onChangeText={(newText) => {
          setNewPassword(newText);
          setErrorMessages({
            ...errorMessages,
            confirmNewPassword: undefined,
            newPassword: undefined,
          });
        }}
        placeholder="Create a new password..."
        errorMessage={errorMessages.newPassword}
      />
      <TextInput
        className={styles.input}
        testId="confirm-new-password-input"
        label="Confirm New Password"
        type="password"
        autoComplete="new-password"
        value={confirmNewPassword}
        onChangeText={(newText) => {
          setConfirmNewPassword(newText);
          setErrorMessages({
            ...errorMessages,
            newPassword: undefined,
            confirmNewPassword: undefined,
          });
        }}
        placeholder="Confirm your new password..."
        errorMessage={errorMessages.confirmNewPassword}
      />
      {errorBox && (
        <div className={styles.errorBox}>
          <FontAwesomeIcon
            className={styles.errorIcon}
            icon={faTriangleExclamation}
          />
          <p data-testid="error-box-text" className={styles.errorBoxText}>
            {errorBox}
          </p>
        </div>
      )}
      {isLoading ? (
        <ReactLoading
          type="spin"
          color={design.buttonColor}
          height={40}
          width={40}
        />
      ) : (
        <Button
          className={styles.resetPasswordButton}
          testId="reset-password-button"
          onClick={async () => {
            setErrorBox(undefined);

            if (areFieldsValid()) {
              await handleResetPassword();
            }
          }}
        >
          <h3 className={styles.buttonText}>Reset Password</h3>
        </Button>
      )}
    </div>
  );
};
