import styles from "src/pages/Checkout/CheckoutOrderDetails/OrderDetailsCartItem/styles.module.scss";

interface ComponentProps {
  name: string;
  totalPrice: number;
  testId?: string;
}

export const OrderDetailsCartItem = ({
  testId,
  name,
  totalPrice,
}: ComponentProps) => {
  return (
    <div className={styles.OrderDetailsCartItem} data-testid={testId}>
      <h4 className={styles.cartItemName} data-testid="item-name">
        {name}
      </h4>
      <h4 className={styles.cartItemPrice} data-testid="item-price">
        ${totalPrice.toFixed(2)}
      </h4>
    </div>
  );
};
