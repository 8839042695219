// Root reducer for the web
import { combineReducers } from "redux";
import { authReducer } from "src/state/auth/reducer";
import { restaurantReducer } from "src/state/restaurant/reducer";
import { customerReducer } from "src/state/customer/reducer";
import { dealReducer } from "src/state/deal/reducer";
import { categoryReducer } from "src/state/category/reducer";
import { itemReducer } from "src/state/item/reducer";
import { optionReducer } from "src/state/option/reducer";
import { cartReducer } from "src/state/cart/reducer";
import { orderReducer } from "src/state/order/reducer";
import { restaurantWideDiscountReducer } from "src/state/restaurantWideDiscount/reducer";
import { checkoutReducer } from "src/state/checkoutSession/reducer";
import { menuReducer } from "src/state/menu/reducer";
import { orderTypeReducer } from "src/state/orderType/reducer";
import { clientConfigReducer } from "src/state/clientConfig/reducer";
import { orderBannerReducer } from "src/state/orderBanner/reducer";
import { locationReducer } from "src/state/location/reducer";
import { cateringInvoiceReducer } from "src/state/cateringInvoice/reducer";
import { scheduledCateringOrderReducer } from "src/state/scheduledCateringOrder/reducer";
import { scheduledCateringCheckoutSessionReducer } from "src/state/scheduledCateringCheckoutSession/reducer";
import { scheduledCateringCartReducer } from "src/state/scheduledCateringCart/reducer";
import { sessionReducer } from "src/state/session/reducer";
import { globalUIVariantReducer } from "src/state/globalUIVariant/reducer";

const appReducer = combineReducers({
  auth: authReducer,
  restaurants: restaurantReducer,
  customers: customerReducer,
  deals: dealReducer,
  categories: categoryReducer,
  items: itemReducer,
  options: optionReducer,
  cart: cartReducer,
  orders: orderReducer,
  restaurantWideDiscounts: restaurantWideDiscountReducer,
  checkoutSession: checkoutReducer,
  menus: menuReducer,
  orderType: orderTypeReducer,
  clientConfig: clientConfigReducer,
  orderBanner: orderBannerReducer,
  location: locationReducer,
  cateringInvoices: cateringInvoiceReducer,
  scheduledCateringCheckoutSession: scheduledCateringCheckoutSessionReducer,
  scheduledCateringCart: scheduledCateringCartReducer,
  scheduledCateringOrder: scheduledCateringOrderReducer,
  session: sessionReducer,
  globalUIVariant: globalUIVariantReducer,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const rootReducer = (state: any, action: any) => {
  if (action.type === "CLEAR_REDUX_STATE") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
