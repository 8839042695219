// Checkout session reducer
import { putItemIntoLocalStorage } from "src/common/localStorage";
import {
  CHECKOUT_SESSION,
  CompleteCheckoutSessionAction,
  CreateCheckoutSessionAction,
} from "src/state/checkoutSession/actions";
import { CheckoutSessionState } from "src/state/checkoutSession/types";

type CheckoutSessionAction =
  | CreateCheckoutSessionAction
  | CompleteCheckoutSessionAction;

export const checkoutReducer = (
  state: CheckoutSessionState | object = {},
  action: CheckoutSessionAction,
) => {
  switch (action.type) {
    case CHECKOUT_SESSION.CREATE_CHECKOUT_SESSION: {
      const checkoutSessionState = action.response;

      const cacheKey = `checkoutSession`;
      putItemIntoLocalStorage(cacheKey, JSON.stringify(checkoutSessionState));

      return checkoutSessionState;
    }
    case CHECKOUT_SESSION.COMPLETE_CHECKOUT_SESSION_ACTION: {
      const existingState = state as CheckoutSessionState;

      const next = {
        ...existingState,
        completed: true,
      };

      const cacheKey = `checkoutSession`;
      putItemIntoLocalStorage(cacheKey, JSON.stringify(next));

      return next;
    }
    default: {
      return state;
    }
  }
};
